import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
// Material Ui
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
// ## //
import * as PaymentAction from 'action/PaymentAction';
import { FormErrorType, LicenseeType } from 'types';
import useStyles from './styles/StripeFormStyles';
import { StripeCardElementOptions } from '@stripe/stripe-js';

// import AddressSection from './AddressSection';
// import CardSection from './CardSection';
const labels = defineMessages({
  name: {
    id: 'stripe.form.name',
    description: 'Cardholder Name',
    defaultMessage: 'Cardholder Name',
  },
});

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

interface Props {
  licensee: LicenseeType;
  closeModals: () => void;
  email: string;
}

const CheckoutForm: React.FC<Props> = ({ licensee, closeModals, email }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl();
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>): Promise<void> => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const { error, source } = await stripe.createSource(cardElement, {
      type: 'card',
      currency: 'eur',
      metadata: {
        licenseeId: licensee._id,
      },
      owner: { name, email },
      usage: 'reusable',
    });
    if (error) {
      // eslint-disable-next-line no-console
      console.log('[error]', error); // Temp left for debug
    } else if (source) {
      try {
        await dispatch(PaymentAction.saveCardAction({ sourceId: source.id, licenseeId: licensee._id }));
        if (closeModals) {
          closeModals();
        }
        // TODO:: Do something
      } catch (err: any) {
        if (err && err.error) {
          // eslint-disable-next-line no-console
          console.log(err.error); // FIXME: Debug
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.card}>
        <FormGroup>
          <TextField
            label={intl.formatMessage(labels.name)}
            name="firstname"
            variant="outlined"
            required
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <br />
        <br />
        <FormGroup>
          <CardElement options={CARD_OPTIONS} />
        </FormGroup>
      </Paper>
      <div id="card-errors" role="alert" />
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  );
};

export const validate = (values: FormErrorType): FormErrorType => {
  const errors: FormErrorType = {};
  if (!values.name) {
    errors.name = <FormattedMessage defaultMessage="Required" id="stripe.form.error.name" />;
  }
  return errors;
};

export default CheckoutForm;
