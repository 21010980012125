import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { DisplayData, MUIDataTableOptions } from 'mui-datatables';
// Material Ui
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// Icons
import BlockIcon from '@mui/icons-material/Block';
import DoneAllIcon from '@mui/icons-material/DoneAll';
// ## //
import { UserType } from 'types';
import TextLabels from '../datatables/datatables-messages';
import { boolFormatter } from '../datatables/Formatters';
import CustomToolbarSelect from '../datatables/CustomToolbarSelect';
import { findSelectedIds } from '../datatables/actionHelpers';
import { CustomMUIDataTableColumn, SelectedRows } from '../datatables/ListTypes';

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
    id: 'user.email',
  },
  firstName: {
    defaultMessage: 'Firstname',
    id: 'user.firstname',
  },
  lastName: {
    defaultMessage: 'Lastname',
    id: 'user.lastname',
  },
  status: {
    defaultMessage: 'Is active?',
    id: 'user.status',
  },
  emailConfirmed: {
    defaultMessage: 'Is email confirmed ?',
    id: 'user.emailConfirmed',
  },
  actionDisable: {
    defaultMessage: 'Disable',
    id: 'action.disable',
  },
  staff: {
    defaultMessage: 'Staff',
    id: 'user.staff',
  },
  actionEnable: {
    defaultMessage: 'Enable',
    id: 'action.enable',
  },
});

export interface UserListProps {
  users: UserType[];
  handleRowSelect: (row: string) => void;
  disableUsers: (ids: string[]) => void;
  enableUsers: (ids: string[]) => void;
}

const UserList: React.FC<UserListProps> = ({ users, handleRowSelect, disableUsers, enableUsers }) => {
  const intl = useIntl();
  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'primaryEmail',
      label: intl.formatMessage(messages.email),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'firstName',
      label: intl.formatMessage(messages.firstName),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'lastName',
      label: intl.formatMessage(messages.lastName),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'active',
      label: intl.formatMessage(messages.status),
      options: {
        filterType: 'checkbox',
        customBodyRender: boolFormatter,
      },
    },
    {
      name: 'staff',
      label: intl.formatMessage(messages.staff),
      options: {
        filterType: 'checkbox',
        customBodyRender: boolFormatter,
      },
    },
    {
      name: 'emailConfirmed',
      label: intl.formatMessage(messages.emailConfirmed),
      options: {
        filterType: 'checkbox',
        customBodyRender: boolFormatter,
      },
    },
  ];

  const actionEnable = (selectedRows: SelectedRows, displayData: DisplayData): void => {
    const ids = findSelectedIds(selectedRows, displayData);
    enableUsers(ids);
  };
  const actionDisable = (selectedRows: SelectedRows, displayData: DisplayData): void => {
    const ids = findSelectedIds(selectedRows, displayData);
    disableUsers(ids);
  };

  const options: MUIDataTableOptions = {
    tableId: 'UsersList',
    textLabels: TextLabels(intl),
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    // selectableRows: 'none',
    onRowClick: (rowData) => {
      handleRowSelect(rowData[0]); // 0 is ID
    },
    // FIXME: Had to disable eslint here, i don't know why
    // eslint-disable-next-line react/display-name
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}>
        <Tooltip title={intl.formatMessage(messages.actionDisable)}>
          <IconButton onClick={() => actionDisable(selectedRows, displayData)}>
            <BlockIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage(messages.actionEnable)}>
          <IconButton onClick={() => actionEnable(selectedRows, displayData)}>
            <DoneAllIcon />
          </IconButton>
        </Tooltip>
      </CustomToolbarSelect>
    ),
  };
  return <MUIDataTable title="" columns={columns} data={users} options={options} />;
};

export default UserList;
