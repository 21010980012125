import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { useRouteMatch } from 'react-router-dom';
// Material Ui
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
// Theme
import Header from 'theme/Header/Header';
import logo from 'assets/img/logo.png';
import theme from '../../theme';
// ## //
import RightLinks from './rightLinks';
import LeftLinks from './leftLinks';
import AdminMenu from './AdminMenu';
import LicenseeMenu from './LicenseeMenu';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  appRouter: React.ReactNode;
}

const LayoutHandler: React.FC<Props> = ({ appRouter }) => {
  const licenseeMenu = useRouteMatch<{ id: string }>('/licensee/:id');
  const adminMenu = useRouteMatch('/admin/*');
  const hasSubmenu = !!licenseeMenu || !!adminMenu;

  // TODO: Have button + state to play with collapsible
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          color="primary"
          brand={<img src={logo} alt="Incarna Logo" className="header-logo" />}
          leftLinks={<LeftLinks />}
          rightLinks={<RightLinks />}
          changeColorOnScroll={{
            height: 300,
            color: 'white',
          }}
        />
        <Box>
          <Grid container spacing={1}>
            {hasSubmenu && (
              <Grid item xs={12} md={3}>
                <Paper>
                  {licenseeMenu && <LicenseeMenu id={licenseeMenu.params.id} />}
                  {adminMenu && <AdminMenu />}
                </Paper>
              </Grid>
            )}
            <Grid item md={hasSubmenu ? 9 : 12}>
              {appRouter}
            </Grid>
          </Grid>
        </Box>
        <ReduxToastr />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LayoutHandler;
