import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
// Material Ui
import Alert from '@mui/material/Alert';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
// ## //
import * as PaymentAction from 'action/PaymentAction';
import { RootState } from 'reducer/configureStore';
import RatesList from '../../lists/RatesList';

const messages = defineMessages({
  title: {
    defaultMessage: 'Rates',
    id: 'Rates.list.title',
  },
  info1: {
    defaultMessage: 'Rates are currently only editable in stripe. Changes will be reflected here',
    id: 'rates.info1',
  },
});

const RatesListPage: React.FC = () => {
  const rates = useSelector((state: RootState) => state.stripeReducer.rates);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(PaymentAction.getVTARatesAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  if (rates) {
    return (
      <Card>
        <CardHeader color="primary">
          <h1>{intl.formatMessage(messages.title)}</h1>
        </CardHeader>
        <CardBody>
          <Alert severity="info">{intl.formatMessage(messages.info1)}</Alert>
          <RatesList Rates={rates} />
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default RatesListPage;
