import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
// MaterialUi
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
// ## //
import { deleteSourceAction, getLicenseeCustomerAction, makeSourceDefaultAction } from 'action/PaymentAction';
import { SourceType } from 'types';
import { RootState } from 'reducer/configureStore';
import StripeForm from './StripeForm';
import SourcesList from './SourcesList';
import DialogTitle from '../common/DialogTitle';

const messages = defineMessages({
  title: {
    defaultMessage: 'Billing Info',
    id: 'billing.title',
  },
  addBilling: {
    defaultMessage: 'Add Billing Info',
    id: 'billing.addBilling',
  },
  modalTitle: {
    defaultMessage: 'Please provide card information',
    id: 'billing.modal.title',
  },
  noPayment: {
    defaultMessage: 'No Payment method registered',
    id: 'payment.method.current',
  },
});

const LicenseeBilling: React.FC = () => {
  const [showBillingModal, openBillingModal] = useState(false);
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const customer = useSelector((state: RootState) => state.selectedLicenseeReducer.customer);
  const user = useSelector((state: RootState) => state.authenticationReducer.user);
  const intl = useIntl();
  const dispatch = useDispatch();

  const deleteCard = useCallback(
    async (source: SourceType) => {
      if (customer && licensee) {
        await dispatch(deleteSourceAction({ customerId: customer.id, sourceId: source.id }));
        setTimeout(dispatch(getLicenseeCustomerAction(licensee._id)), 1000);
        // Wait a sec before Refreshing to let Stripe Update its data
      }
    },
    [customer, dispatch, licensee]
  );

  const makeDefault = useCallback(
    async (source: SourceType) => {
      if (customer && licensee) {
        await dispatch(makeSourceDefaultAction({ customerId: customer.id, sourceId: source.id }));
        setTimeout(
          dispatch(getLicenseeCustomerAction(licensee._id)),
          1000 // Wait a sec before Refreshing to let Stripe Update its data
        );
      }
    },
    [customer, dispatch, licensee]
  );

  if (user && customer && licensee) {
    const { sources } = customer;
    return (
      <>
        <Card>
          <CardHeader
            title={intl.formatMessage(messages.title)}
            action={
              <Button variant="contained" onClick={() => openBillingModal(true)}>
                {intl.formatMessage(messages.addBilling)}
              </Button>
            }
          />
          <CardContent>
            <SourcesList customer={customer} deleteCard={deleteCard} makeDefault={makeDefault} />
            {!sources && <Typography>{intl.formatMessage(messages.noPayment)}</Typography>}
          </CardContent>
        </Card>
        <Dialog fullWidth onClose={() => openBillingModal(false)} open={showBillingModal}>
          <DialogTitle onClose={() => openBillingModal(false)}>{intl.formatMessage(messages.modalTitle)}</DialogTitle>
          <DialogContent>
            <StripeForm closeModals={() => openBillingModal(false)} licensee={licensee} email={user.primaryEmail} />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return null;
};

export default LicenseeBilling;
