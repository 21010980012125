import React from 'react';
import { FormattedMessage } from 'react-intl';
// Material Ui
import ButtonGroup from '@mui/material/ButtonGroup';
// Icons
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TranslateIcon from '@mui/icons-material/Translate';
import EuroIcon from '@mui/icons-material/Euro';
import ErrorIcon from '@mui/icons-material/Error';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CommentIcon from '@mui/icons-material/Comment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// Theme
import Button from 'theme/CustomButtons/Button';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import CardFooter from 'theme/Card/CardFooter';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
import InfoArea from 'theme/InfoArea/InfoArea';
import { CustomerType } from 'types';
// ## //

interface StripeCustomerButtonsProps {
  editAction: () => void;
  customer: CustomerType;
}

const StripeCustomerButtons: React.FC<StripeCustomerButtonsProps> = ({ editAction, customer }) => (
  <ButtonGroup>
    <Button customColor="danger" size="small" onClick={editAction}>
      <FormattedMessage defaultMessage="Edit" description="Edit button" id="button.edit" />
    </Button>
    <Button
      customColor="primary"
      size="small"
      target="_blank"
      href={`https://dashboard.stripe.com/customers/${customer.id}`}
    >
      See in Stripe
    </Button>
  </ButtonGroup>
);

interface Props {
  customer: CustomerType;
  editAction: () => void;
}

const StripeCustomerInfo: React.FC<Props> = ({ customer, editAction }) => (
  <Card>
    <CardHeader color="primary">Stripe Data</CardHeader>
    <CardBody>
      <GridContainer>
        <GridItem md={4}>
          <InfoArea
            title="Name"
            description={customer.name || 'not defined'}
            icon={EmojiPeopleIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Address"
            description={
              customer.address ? (
                <>
                  {`${'' || customer.address.line1} ${'' || customer.address.line2} ${
                    customer.address.postal_code || ''
                  } ${customer.address.city || ''} ${customer.address.state || ''} ${customer.address.country || ''}`}
                </>
              ) : (
                'not defined'
              )
            }
            icon={ApartmentIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Description"
            description={customer.description || 'not defined'}
            icon={CommentIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Contact Email"
            description={customer.email || 'not defined'}
            icon={ContactMailIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea title="Tax Exemption" description={customer.tax_exempt} icon={NoteAddIcon} iconColor="white" />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Delinquent Status"
            description={customer.delinquent ? 'YES' : 'NO'}
            icon={ErrorIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Currency"
            description={customer.currency || 'not defined'}
            icon={EuroIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea title="Balance" description={customer.balance || '0'} icon={EqualizerIcon} iconColor="white" />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Language"
            description={customer.preferred_locales[0] || 'default'}
            icon={TranslateIcon}
            iconColor="white"
          />
        </GridItem>
        <GridItem md={4}>
          <InfoArea
            title="Invoice Footer"
            description={customer.invoice_settings ? customer.invoice_settings.footer : 'nothing'}
            icon={TranslateIcon}
            iconColor="white"
          />
        </GridItem>
      </GridContainer>
    </CardBody>
    <CardFooter>
      <StripeCustomerButtons editAction={editAction} customer={customer} />
    </CardFooter>
  </Card>
);

export default StripeCustomerInfo;
