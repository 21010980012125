import _ from 'lodash';
import { SET_LICENSEE_PERMISSION } from 'reducer/authenticationReducer';
import { GET_LICENSEES_RESPONSE } from 'reducer/licenseesReducer';
import {
  GET_LICENSEE_RESPONSE,
  GET_LICENSEE_KEYS_RESPONSE,
  GET_LICENSEE_INVITATIONS_RESPONSE,
  GET_LICENSEE_INVOICES_RESPONSE,
  GET_LICENSEE_BUCKETS_RESPONSE,
  GET_LICENSEE_BUCKET_LINK,
} from 'reducer/selectedLicenseeReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import LicenseeApi from 'api/LicenseeApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LicenseeType, UserType } from 'types';

/**
 * Get Licensees (list)
 */

export const getLicenseesAction = createAsyncThunk('licensee/getLicenseesAction', (_param, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenseeApi.getAllLicensees().then((licensees) => {
    dispatch(GET_LICENSEES_RESPONSE(licensees));
    dispatch(API_CALL_END());
  });
});

/**
 * Add or Update Licensee
 */

export const saveLicenseeAction = createAsyncThunk(
  'licensee/save',
  (licenseeBeingAddedOrEdited: Partial<LicenseeType>, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.saveLicensee(licenseeBeingAddedOrEdited)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getLicenseesAction()); // FIXME: STOOPID
      });
  }
);

/**
 * Get Licensee (unique)
 */
export const getLicenseeAction = createAsyncThunk('licensee/getLicenseeAction', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenseeApi.getLicensee(licenseeId).then((licensee) => {
    dispatch(SET_LICENSEE_PERMISSION(licensee));
    dispatch(GET_LICENSEE_RESPONSE(licensee));
    dispatch(API_CALL_END());
  });
});

/**
 * Get Licensee Keys (unique)
 */
export const getLicenseeKeysAction = createAsyncThunk(
  'licensee/getLicenseeKeysAction',
  (licenseeId: string, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.getLicenseeKeys(licenseeId).then((keys) => {
      dispatch(GET_LICENSEE_KEYS_RESPONSE(keys));
      dispatch(API_CALL_END());
    });
  }
);

/**
 * Delete Licensee (unique)
 */
export const deleteLicenseeAction = createAsyncThunk(
  'licensee/deleteLicenseeAction',
  (licenseeId: string, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.deleteLicensee(licenseeId)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getLicenseesAction());
      });
  }
);

/**
 * Create licence Key for Licensee (unique)
 */
export const createLicenceKeyAction = createAsyncThunk(
  'licensee/createLicenceKeyAction',
  (licensee: Partial<LicenseeType> & { _id: string }, { dispatch }) => {
    const licenseeId = licensee._id;
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.createLicenceKey(licenseeId)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getLicenseeKeysAction(licenseeId));
      });
  }
);

/**
 * Assign Users to licensee (unique)
 */

// Helper function
function assignUsers(dispatch: any, finalUsers: any, licenseeId: any): Promise<void> {
  dispatch(API_CALL_BEGIN());
  return LicenseeApi.assignUsers(finalUsers, licenseeId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getLicenseeAction(licenseeId));
    });
}

interface AssignUserParams {
  users: Partial<UserType> & { _id: string }[];
  licensee: Partial<LicenseeType> & { _id: string };
}

export const assignUsersAction = createAsyncThunk(
  'licensee/assignUsers',
  ({ users, licensee }: AssignUserParams, { dispatch }) => {
    const licenseeId = licensee._id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const finalUsers = _.unionWith(licensee.users, users, (x, y) => x.user._id === y.user._id);

    return assignUsers(dispatch, finalUsers, licenseeId);
  }
);

/**
 * Assign Users to licensee (unique)
 */

export const unAssignUsersAction = createAsyncThunk(
  'licensee/unassignUsers',
  ({ users, licensee }: AssignUserParams, { dispatch }) => {
    const licenseeId = licensee._id;
    const finalUsers = _.differenceWith(licensee.users, users, (x, y) => x.user._id === y._id);
    return assignUsers(dispatch, finalUsers, licenseeId);
  }
);

/**
 * Get Invitations for given licensee
 */
export const getInvitationsAction = createAsyncThunk(
  'licensee/getInvitationsAction',
  (licenseeId: string, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.getInvitations(licenseeId).then((invitations) => {
      dispatch(GET_LICENSEE_INVITATIONS_RESPONSE(invitations));
      dispatch(API_CALL_END());
    });
  }
);

interface AddInvitationParams {
  licenseeId: string;
  emails: string[];
}

export const addInvitationsAction = createAsyncThunk(
  'licensee/addInvitation',
  ({ licenseeId, emails }: AddInvitationParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.addInvitations(licenseeId, emails)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getInvitationsAction(licenseeId));
        dispatch(getLicenseeAction(licenseeId));
      });
  }
);

interface EditInvitationsActionParams {
  licenseeId: string;
  invitation: any;
}

export const editInvitationsAction = createAsyncThunk(
  'licensee/editInvitationsAction',
  ({ licenseeId, invitation }: EditInvitationsActionParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.editInvitation(invitation)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getInvitationsAction(licenseeId));
        dispatch(getLicenseeAction(licenseeId));
      });
  }
);

interface DeleteInvitationsActionParams {
  licenseeId: string;
  invitation: any;
}

export const deleteInvitationsAction = createAsyncThunk(
  'licensee/deleteInvitationsAction',
  ({ licenseeId, invitation }: DeleteInvitationsActionParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.deleteInvitations(licenseeId, invitation)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getInvitationsAction(licenseeId));
      });
  }
);

/**
 * Get invoices for given licensee
 */
export const getLicenseeInvoicesAction = createAsyncThunk(
  'licensee/getLicenseeInvoicesAction',
  (licenseeId: string, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenseeApi.getInvoices(licenseeId).then((data) => {
      dispatch(GET_LICENSEE_INVOICES_RESPONSE(data));
      dispatch(API_CALL_END());
    });
  }
);

/**
 * Get Downloadables items
 */
export const getBucketsAction = createAsyncThunk('licensee/getBucketsAction', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenseeApi.getBuckets(licenseeId).then((data) => {
    dispatch(GET_LICENSEE_BUCKETS_RESPONSE(data));
    dispatch(API_CALL_END());
  });
});

export const getBucketLink = createAsyncThunk('licensee/getBucketLink', (base64Name: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenseeApi.getBucketLink(base64Name).then((data) => {
    dispatch(GET_LICENSEE_BUCKET_LINK(data));
    dispatch(API_CALL_END());
  });
});
