import React, { useCallback, useMemo } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @mui/material components
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
// import Grow from '@mui/material/Grow';
import Icon from '@mui/material/Icon';
import Popper from '@mui/material/Popper';
// core components
import Button, { CustomButtonsProps } from 'theme/CustomButtons/Button';

import useStyles from './styles/customDropdownStyle';

interface Props {
  hoverColor?: 'black' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';
  buttonText: React.ReactNode;
  buttonIcon: string | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  dropdownList: React.ReactElement[];
  buttonProps: CustomButtonsProps;
  dropup?: boolean;
  dropdownHeader?: React.ReactNode;
  rtlActive?: boolean;
  caret?: boolean;
  left?: boolean;
  noLiPadding: boolean;
  // function that returns the selected item
  onClick?: (unknown: unknown) => void;
}

const Dropdown: React.FC<Props> = ({
  buttonText,
  buttonIcon,
  dropdownList,
  buttonProps,
  dropup,
  dropdownHeader,
  caret,
  hoverColor,
  left,
  rtlActive,
  noLiPadding,
  onClick,
}: Props) => {
  // Very Painfull to type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  // Actions
  const handleClick = (event: React.ChangeEvent<unknown>): void => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
    if (onClick) {
      onClick(dropdownHeader);
    }
  }, [onClick, dropdownHeader]);

  const handleCloseAway = (event: MouseEvent | TouchEvent): void => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  // Styles
  const classes = useStyles();
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: Boolean(anchorEl),
    [classes.caretRTL]: rtlActive,
  });

  const hoverClass:
    | 'blackHover'
    | 'primaryHover'
    | 'infoHover'
    | 'successHover'
    | 'warningHover'
    | 'dangerHover'
    | 'roseHover' = hoverColor ? `${hoverColor}Hover` : 'primaryHover';

  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverClass]]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });

  const icon = useMemo(() => {
    switch (typeof buttonIcon) {
      case 'object':
        return React.createElement(buttonIcon, { className: classes.buttonIcon });
      case 'string':
        return <Icon className={classes.buttonIcon}>{buttonIcon}</Icon>;
      default:
        return null;
    }
  }, [buttonIcon, classes.buttonIcon]);

  const dropUp = useMemo((): 'top' | 'top-start' | 'bottom' | 'bottom-start' => {
    const direction = dropup ? 'top' : 'bottom';
    return left ? `${direction}-start` : direction;
  }, [dropup, left]);

  return (
    <div>
      <div>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? 'menu-list' : undefined}
          aria-haspopup="true"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          onClick={handleClick}
        >
          {icon}
          {buttonText}
          {caret && <b className={caretClasses} />}
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        // transition
        disablePortal
        placement={dropUp}
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        <Paper className={classes.dropdown}>
          <ClickAwayListener onClickAway={handleCloseAway}>
            <MenuList role="menu" className={classes.menuList}>
              {dropdownHeader && (
                <MenuItem onClick={handleClose} className={classes.dropdownHeader}>
                  {dropdownHeader}
                </MenuItem>
              )}
              {dropdownList.map((prop: React.ReactElement) => (
                <MenuItem key={prop?.key} onClick={handleClose} className={dropdownItem}>
                  {prop}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

Dropdown.defaultProps = {
  caret: true,
  hoverColor: 'primary',
  dropup: false,
  rtlActive: false,
  left: false,
  dropdownHeader: undefined,
  onClick: undefined,
};

export default Dropdown;
