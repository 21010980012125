import React from 'react';
// Prismic
import { RichText } from 'prismic-reactjs';
// Material Ui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

interface Props {
  prismicDoc: any;
}

const ReleaseNote: React.FC<Props> = ({ prismicDoc }) => {
  const title = prismicDoc.data.title.length !== 0 ? RichText.asText(prismicDoc.data.title) : undefined;
  const content = prismicDoc.data.content.length !== 0 ? prismicDoc.data.content : undefined;
  const date = prismicDoc.data.date.length !== 0 ? prismicDoc.data.date : undefined;

  if (title && content) {
    return (
      <Card>
        <CardHeader title={title} subheader={date} />
        <CardContent>
          <RichText render={content} />
        </CardContent>
      </Card>
    );
  }
  return null;
};

export default ReleaseNote;
