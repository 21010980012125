// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { toastr } from 'react-redux-toastr';
import { Dispatch, Action } from 'redux';
import { FormattedMessage } from 'react-intl';
// ## //
import { API_CALL_ERROR } from 'reducer/apiReducer';
import ErrorMessages from './ErrorsMessages';

export const customError = { error: { message: 'CustomErrorMessage', code: 'CustomCode' } };
export const NoBackendError = { error: { message: 'No Backend', code: 'NoBackendError' } };

interface AppErrorMessage {
  code: keyof typeof ErrorMessages | 'unspecified';
  message: string;
}

const ApplicationError = ({ code, message }: AppErrorMessage): string => {
  if (code && code !== 'unspecified') {
    // Sorry violate here, as getting access to intl Shape is hard
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <FormattedMessage {...ErrorMessages[code]} />;
  }
  // If possible, send to sentry
  return message;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchError =
  (e: any) =>
  (dispatch: Dispatch<Action>): void => {
    console.log('FETCHED ERROR::', e);
    dispatch(API_CALL_ERROR());
    toastr.confirm(ApplicationError(e), {});
  };

export default fetchError;
