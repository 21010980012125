import React from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// thheme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
// ## //
import { FormErrorType, ProductType } from 'types';
import { ButtonCancel, ButtonSubmit, ButtonClear } from '../common/Buttons';

const labels = defineMessages({
  name: {
    id: 'product.form.name',
    defaultMessage: 'Name',
  },
  key: {
    id: 'product.form.key',
    defaultMessage: 'Product Key',
  },
});

interface Props {
  initialValues?: Partial<ProductType>;
  handleSave: (values: ProductType) => void;
  handleCancel: () => void;
}

const ProductForm: React.FC<Props> = ({ handleSave, handleCancel, initialValues }) => {
  const intl = useIntl();
  const methods = useForm<ProductType>({
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const { handleSubmit, formState, reset } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup>
              <TextFieldInput
                label={intl.formatMessage(labels.name)}
                name="name"
                placeholder={intl.formatMessage(labels.name)}
                type="text"
              />
              <TextFieldInput
                label={intl.formatMessage(labels.key)}
                name="key"
                placeholder={intl.formatMessage(labels.key)}
                type="text"
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonClear disabled={!isDirty} onClick={() => reset(initialValues)} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

export const validate = (values: FormErrorType): FormErrorType => {
  const errors: FormErrorType = {};

  if (!values.name) {
    errors.name = (
      <FormattedMessage
        defaultMessage="Name is required"
        description="Error missing name"
        id="product.form.error.name"
      />
    );
  }
  return errors;
};

// TODO: Add async validator for uniq fields (like name)

ProductForm.defaultProps = {
  initialValues: {},
};

export default ProductForm;
