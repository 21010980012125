import React from 'react';
import { useDispatch } from 'react-redux';
// Material Ui
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// ## //
import changeLocale from 'action/updateLocale';
import { LocaleType } from 'types';
import FlagIcon from '../common/FlagIcon';

const LocaleButton: React.FC = () => {
  const dispatch = useDispatch();
  const switchLocale = (locale: LocaleType): void => {
    dispatch(changeLocale(locale));
  };

  return (
    <ButtonGroup orientation="vertical" size="small" variant="text">
      <Button variant="text" className="ml-1 mr-1" onClick={() => switchLocale('en')}>
        <FlagIcon className="locale-button" code="us" />
      </Button>
      <Button variant="text" className="ml-1 mr-1" onClick={() => switchLocale('fr')}>
        <FlagIcon className="locale-button" code="fr" />
      </Button>
    </ButtonGroup>
  );
};

export default LocaleButton;
