import React from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import _ from 'lodash';
// MaterialUi
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from 'theme/CustomButtons/Button';
import { RootState } from 'reducer/configureStore';
import useStyles from './styles/headerLinksStyle';

const messages = defineMessages({
  home: {
    defaultMessage: 'Home',
    id: 'menu.home',
  },
  licensees: {
    defaultMessage: 'My Licensees',
    id: 'menu.mylicensees',
  },
  faq: {
    defaultMessage: 'FAQ',
    id: 'menu.faq',
  },
  login: {
    defaultMessage: 'Login',
    id: 'menu.login',
  },
  register: {
    defaultMessage: 'Register',
    id: 'menu.register',
  },
  admin: {
    defaultMessage: 'Admin',
    id: 'menu.admin',
  },
});

interface RouteDef {
  path: string;
  title: keyof typeof messages;
  match: {
    path: string;
    exact?: boolean;
  };
}

const loggedInRoutes: RouteDef[] = [
  {
    path: '/',
    title: 'home',
    match: {
      path: '/',
      exact: true,
    },
  },
  {
    path: '/licensees',
    title: 'licensees',
    match: {
      path: '/licensee*',
      exact: false,
    },
  },
  {
    path: '/faq',
    title: 'faq',
    match: {
      path: '/faq',
      exact: true,
    },
  },
];

const loggedOutRoutes: RouteDef[] = [
  {
    path: '/',
    title: 'home',
    match: {
      path: '/',
      exact: true,
    },
  },
  {
    path: '/login',
    title: 'login',
    match: {
      path: '/login',
    },
  },
  {
    path: '/register',
    title: 'register',
    match: {
      path: '/register',
    },
  },
];

const adminRoutes: RouteDef[] = [
  {
    path: '/admin/users',
    title: 'admin',
    match: { path: '/admin/*' },
  },
];

const LeftLinks: React.FC = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const classes = useStyles();

  const loggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);

  // eslint-disable-next-line react/display-name
  const routes = loggedIn ? _.concat(loggedInRoutes, staff ? adminRoutes : []) : loggedOutRoutes;

  return (
    <List className={classes.list}>
      {routes.map((item) => (
        <ListItem className={classes.listItem} key={item.path}>
          <Button
            customColor={matchPath(pathname, item.match) ? 'info' : 'transparent'}
            to={item.path}
            component={RouterLink}
            size="small"
          >
            {intl.formatMessage(messages[item.title])}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

export default LeftLinks;
