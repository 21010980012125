import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// core components
import useStyles from './styles/cardFooterStyle';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const CardFooter: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [className || '']: className !== undefined,
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};

CardFooter.defaultProps = {
  className: undefined,
};

export default CardFooter;
