import React, { ElementType, forwardRef } from 'react';
import classNames from 'classnames';
// @mui/material components
import Button, { ButtonProps } from '@mui/material/Button';
// core components
import useStyles from './styles/buttonStyle';

export interface CustomButtonsProps extends Partial<ButtonProps> {
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string;
  target?: string;
  to?: string;
  component?: ElementType;
  customColor?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'rose'
    | 'white'
    | 'facebook'
    | 'twitter'
    | 'google'
    | 'github'
    | 'transparent';
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: React.ChangeEvent<unknown>) => void;
  type?: 'button' | 'reset' | 'submit';
}

type Ref = HTMLButtonElement | null;

const RegularButton = forwardRef<Ref, CustomButtonsProps>((props: CustomButtonsProps, ref) => {
  const { customColor, round, children, fullWidth, disabled, simple, size, block, link, justIcon, className, ...rest } =
    props;

  const classes = useStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size || 'small']]: size,
    [classes[customColor || 'primary']]: customColor,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className || '']: className,
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...rest} className={btnClasses} ref={ref}>
      {children}
    </Button>
  );
});

RegularButton.defaultProps = {
  className: '',
  color: 'primary',
  simple: false,
  round: false,
  fullWidth: false,
  disabled: false,
  block: false,
  link: false,
  justIcon: false,
  size: 'small',
  onClick: undefined,
  type: undefined,
  target: undefined,
};

export default RegularButton;
