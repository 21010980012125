import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import _ from 'lodash';
// Material Ui
import Alert from '@mui/material/Alert';
// Theme
import Button from 'theme/CustomButtons/Button';

// ## //
import { GameType } from 'types';
import { RootState } from 'reducer/configureStore';
import { dateTimeFormatter, boolFormatter, escapedStringFormatter } from '../datatables/Formatters';
import TextLabels from '../datatables/datatables-messages';
import GameEventsModal from '../Dialog/GameEventsDialog';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  actions: {
    defaultMessage: 'Action',
    id: 'licensee.games.actions',
  },
  actionCancelled: {
    defaultMessage: 'Game Cancelled',
    id: 'action.cancelgame.message',
  },
  actionUnCancelled: {
    defaultMessage: 'Game UnCancelled',
    id: 'action.uncancelgame.message',
  },
  players: {
    defaultMessage: 'Number of players',
    id: 'licensee.games.nbPlayers',
  },
  ip: {
    defaultMessage: 'Server public Ip',
    id: 'licensee.games.serverip',
  },
  startTime: {
    defaultMessage: 'Date',
    id: 'licensee.games.date',
  },
  sentToStripe: {
    defaultMessage: 'Sent to Stripe',
    id: 'licensee.games.sentToStripe',
  },
  dateSentToStripe: {
    defaultMessage: 'date Synced with Stripe',
    id: 'licensee.games.dateSentToStripe',
  },
  viewEvents: {
    defaultMessage: 'View',
    id: 'licensee.game.view',
  },
  contestAction: {
    defaultMessage: 'Contest Game',
    id: 'game.contest',
  },
  cancelContestAction: {
    defaultMessage: 'Cancel',
    id: 'game.uncancel',
  },
  contestStatus: {
    defaultMessage: 'Contest Status',
    id: 'game.contestStatus',
  },
  contestReason: {
    defaultMessage: 'Reason of Contest',
    id: 'game.contestReason',
  },
  licensee: {
    defaultMessage: 'Licensee',
    id: 'licensee.games.licensee',
  },
});

export interface GamesListProps {
  games: GameType[];
  showLicensee?: boolean;
  licenseeId?: string;
}

const GamesList: React.FC<GamesListProps> = ({ games, showLicensee, licenseeId }) => {
  const intl = useIntl();
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const [openedGame, setOpenedGame] = useState<GameType | undefined>(undefined);

  const openGame = (gameId: string): void => {
    const game = _.find(games, (g) => g._id === gameId);
    setOpenedGame(game);
  };

  const selectGameFormatter = (_void: unknown, tableMeta: MUIDataTableMeta): React.ReactNode => {
    const gameId = tableMeta.rowData[0];
    return (
      <Button round customColor="primary" onClick={() => openGame(gameId)}>
        {intl.formatMessage(messages.viewEvents)}
      </Button>
    );
  };

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: 'startTime',
      options: {
        customBodyRender: dateTimeFormatter,
        filter: true,
        sort: true,
      },
      label: intl.formatMessage(messages.startTime),
    },
    {
      name: 'players',
      label: intl.formatMessage(messages.players),
      options: {
        filterType: 'checkbox',
      },
    },
    {
      name: 'ip',
      label: intl.formatMessage(messages.ip),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'contestStatus',
      label: intl.formatMessage(messages.contestStatus),
      options: {
        filterType: 'checkbox',
      },
    },
    {
      name: 'contestReason',
      label: intl.formatMessage(messages.contestReason),
      options: {
        customBodyRender: escapedStringFormatter,
        filterType: 'textField',
      },
    },
  ];
  if (staff) {
    columns.push({
      name: 'sentToStripe',
      label: intl.formatMessage(messages.sentToStripe),
      options: {
        customBodyRender: boolFormatter,
      },
    });
    columns.push({
      name: 'dateSentToStripe',
      options: {
        customBodyRender: dateTimeFormatter,
        filter: false,
      },
      label: intl.formatMessage(messages.dateSentToStripe),
    });
  }
  if (showLicensee) {
    columns.unshift({
      name: 'licenceKey.licensee.name',
      label: intl.formatMessage(messages.licensee),
      options: {
        filterType: 'textField',
      },
    });
  }
  // Make sure _id is first (so last unshift)
  columns.unshift({
    name: 'actions',
    label: intl.formatMessage(messages.actions),
    options: {
      empty: true,
      filter: false,
      customBodyRender: selectGameFormatter,
    },
  });
  columns.unshift({
    name: '_id',
    options: {
      display: 'excluded',
      filter: false,
    },
  });

  const options: MUIDataTableOptions = {
    tableId: 'GamesList',
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    sortOrder: { name: 'startTime', direction: 'desc' },
    enableNestedDataAccess: '.',
  };

  return (
    <>
      <Alert severity="info">
        {' '}
        <FormattedMessage
          defaultMessage="Displaying last {count, number} {count, plural,
                        one {game}
                        other {games}
                      }"
          id="games.list.subtitle"
          values={{
            count: games.length,
          }}
        />
      </Alert>
      <MUIDataTable title="" data={games} options={options} columns={columns} />
      {openedGame && (
        <GameEventsModal
          openedGame={openedGame}
          onClose={() => setOpenedGame(undefined)}
          licenseeId={!showLicensee ? licenseeId : undefined}
        />
      )}
    </>
  );
};

GamesList.defaultProps = {
  showLicensee: false,
  licenseeId: undefined,
};

export default GamesList;
