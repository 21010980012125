import { OptionForDropDown } from 'types';

interface ObjectParamForDropdown {
  _id: string;
  name: string;
}

export const FormatObjectListForDropdown = (objects?: ObjectParamForDropdown[]): OptionForDropDown[] | null => {
  if (!objects) {
    return null;
  }
  return objects.map((x) => ({
    value: x._id,
    text: x.name,
  }));
};

interface FrequencyOption {
  value: string;
  label: string;
}

export const frequencysForPlansDropdown = (): FrequencyOption[] => [
  { value: '1 week', label: '1 week' },
  { value: '2 week', label: '2 weeks' },
  { value: '1 month', label: '1 month' },
  { value: '3 month', label: '3 months' },
];
