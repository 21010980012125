import { createSlice } from '@reduxjs/toolkit';
import { FaqType } from 'types';

export interface PrismicReducerDataType {
  faq: FaqType[] | undefined;
  releaseNotes: any; // FIXME: Type
}

const initialState: PrismicReducerDataType = {
  faq: undefined,
  releaseNotes: undefined,
};

const prismicSlice = createSlice({
  name: 'prismicReducer',
  initialState,
  reducers: {
    GET_PRISMIC_FAQ(state, action) {
      return {
        ...state,
        faq: action.payload,
      };
    },
    GET_PRISMIC_RELEASES(state, action) {
      return {
        ...state,
        releaseNotes: action.payload,
      };
    },
  },
});

export const { GET_PRISMIC_FAQ, GET_PRISMIC_RELEASES } = prismicSlice.actions;

export default prismicSlice.reducer;
