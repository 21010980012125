import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
// Theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
import Select from 'theme/CustomInput/CustomSelect';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
// ## //
import { AddressType, LicenseeType, OptionForDropDown, PlanType, RateType } from 'types';
import countries from './countries';
import CountrySelect from './CountrySelect';
import { ButtonCancel, ButtonClear, ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  name: {
    id: 'licensee.form.name',
    defaultMessage: 'Name',
  },
  address: {
    id: 'licensee.form.address',
    defaultMessage: 'Exploitation Address',
  },
  vat: {
    id: 'licensee.form.vat',
    defaultMessage: 'European VAT Identifier',
  },
  plan: {
    id: 'licensee.form.plan',
    defaultMessage: 'Plan',
  },
  tax: {
    id: 'licensee.form.tax',
    defaultMessage: 'Tax Rate',
  },
  line1: {
    id: 'licensee.form.line1',
    defaultMessage: 'street, PO Box,...',
  },
  line2: {
    id: 'licensee.form.line2',
    defaultMessage: 'apartment, suite, unit, or building',
  },
  postal_code: {
    id: 'licensee.form.postal_code',
    defaultMessage: 'ZIP or postal code',
  },
  city: {
    id: 'licensee.form.city',
    defaultMessage: 'City, district, suburb, town, or village.',
  },
  state: {
    id: 'licensee.form.state',
    defaultMessage: 'State, county, province, or region',
  },
  country: {
    id: 'licensee.form.country',
    defaultMessage: 'Country (ISO FORMAT, 2 LETTERS)',
  },
  status: {
    id: 'licensee.form.status',
    defaultMessage: 'Activé, en test, annulé, ...',
  },
});

const possibleStatus = [
  { value: 'En test', label: 'En test' },
  { value: 'Activé', label: 'Activé' },
  { value: 'Fermé', label: 'Fermé' },
  { value: 'Refus Client', label: 'Refus Client' },
  { value: 'Pas de status', label: 'Pas de status' },
];

export interface LicenseeFormValues {
  name: string;
  status: string;
  plan: PlanType;
  vat: string;
  stripe: { rateId: string };
  country: { code: string } | null;
  exploitationAddress: string;
  address: AddressType;
}
interface Props {
  handleSave: (values: LicenseeFormValues) => void;
  handleCancel: () => void;
  plans: OptionForDropDown[];
  staff: boolean;
  rates: RateType[];
  initialValues?: LicenseeType | Record<string, never>;
}

const LicenseeForm: React.FC<Props> = ({ plans, rates, handleSave, handleCancel, initialValues = {}, staff }) => {
  const methods = useForm<LicenseeFormValues>({
    defaultValues: {
      name: initialValues.name,
      address: initialValues.address,
      status: initialValues.status || 'En test',
      plan: { _id: (initialValues.plan && initialValues.plan._id) || '' },
      stripe: { rateId: (initialValues.stripe && initialValues.stripe.rateId) || '' },
      country: initialValues.address?.country ? countries.find((x) => x.code === initialValues.address?.country) : null,
    },
    mode: 'onBlur',
  });
  const intl = useIntl();
  const { handleSubmit, formState, reset } = methods;
  const { isDirty, isSubmitting } = formState;
  // TODO: Add Main Email Contact (set user as Main Contact ?*)

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup>
              <TextFieldInput label={intl.formatMessage(labels.name)} name="name" placeholder="name" type="text" />
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item md={6}>
                  <TextFieldInput optional label={intl.formatMessage(labels.line1)} name="address.line1" type="text" />
                  <TextFieldInput optional label={intl.formatMessage(labels.line2)} name="address.line2" type="text" />

                  <TextFieldInput
                    optional
                    label={intl.formatMessage(labels.postal_code)}
                    name="address.postal_code"
                    placeholder=""
                    type="text"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextFieldInput
                    optional
                    label={intl.formatMessage(labels.city)}
                    name="address.city"
                    placeholder=""
                    type="text"
                  />
                  <TextFieldInput
                    optional
                    label={intl.formatMessage(labels.state)}
                    name="address.state"
                    placeholder=""
                    type="text"
                  />
                  <CountrySelect label={intl.formatMessage(labels.country)} name="country" />
                </Grid>
              </Grid>
              <br />
              <TextFieldInput optional label={intl.formatMessage(labels.vat)} name="vat" type="text" />
              {staff && (
                <>
                  <Select label={intl.formatMessage(labels.plan)} name="plan._id" options={plans} />
                </>
              )}
              <br />
              {staff && (
                <>
                  <Select
                    optional
                    label={intl.formatMessage(labels.tax)}
                    name="stripe.rateId"
                    options={rates.map((rate) => ({
                      value: rate.id,
                      label: `${rate.display_name} ${rate.description}`,
                    }))}
                  />
                </>
              )}
              <br />
              {staff && (
                <>
                  <Select
                    optional
                    options={possibleStatus.map((status) => ({
                      value: status.value,
                      label: status.label,
                    }))}
                    label={intl.formatMessage(labels.status)}
                    name="status"
                  />
                </>
              )}
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonClear disabled={!isDirty} onClick={() => reset(initialValues)} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

LicenseeForm.defaultProps = {
  initialValues: {},
};

export default LicenseeForm;
