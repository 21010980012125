import makeStyles from '@mui/styles/makeStyles';

const gridItemStyles = makeStyles({
  grid: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    flexBasis: 'auto',
  },
});

export default gridItemStyles;
