import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import { ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  email: {
    id: 'forgotpassword.form.Email',
    description: 'forgot password email',
    defaultMessage: 'Email',
  },
});

interface ForgotPasswordFormType {
  email: string;
}
interface Props {
  onSubmit: (values: ForgotPasswordFormType) => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const methods = useForm({ mode: 'onBlur' });
  const intl = useIntl();
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldInput
          label={intl.formatMessage(labels.email)}
          name="email"
          placeholder={intl.formatMessage(labels.email)}
          type="email"
        />
        <ButtonSubmit disabled={isSubmitting || !isDirty} />
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
