import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
// Material Ui
import Button from '@mui/material/Button';
// theme
import Card from 'theme/Card/Card';
import CardHeader from 'theme/Card/CardHeader';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// Icons
import SendIcon from '@mui/icons-material/Send';
import { LoginParam } from 'types';
// ## //

const labels = defineMessages({
  password: {
    id: 'login.form.password',
    defaultMessage: 'Password',
  },
  email: {
    id: 'login.form.username',
    defaultMessage: 'Email',
  },
});

interface Props {
  handleSave: (values: LoginParam) => void;
  handleResetPassword: () => void;
}

const LoginForm: React.FC<Props> = ({ handleSave, handleResetPassword }) => {
  const methods = useForm({ mode: 'onBlur' });
  const intl = useIntl();
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardHeader color="primary">
            <h1>
              <FormattedMessage
                defaultMessage="Please Login"
                description="login form heading"
                id="login.form.heading"
              />
            </h1>
          </CardHeader>
          <CardBody>
            <TextFieldInput
              label={intl.formatMessage(labels.email)}
              name="username"
              placeholder="username (login)"
              type="text"
            />

            <TextFieldInput
              label={intl.formatMessage(labels.password)}
              name="password"
              placeholder={intl.formatMessage(labels.password)}
              type="password"
            />
          </CardBody>
          <CardFooter>
            <Button color="primary" variant="contained" disabled={isSubmitting || !isDirty} type="submit">
              <SendIcon />
              <FormattedMessage defaultMessage="Login" id="login.form.login" />
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleResetPassword} type="button">
              <FormattedMessage defaultMessage="Reset Password" id="login.form.resetpassword" />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
