import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { getLicenceKeyAction, saveLicenceKeyAction, getLicenceKeysAction } from 'action/LicenceKeyAction';
import { getProductsAction } from 'action/ProductAction';
import { getLicenseeKeysAction } from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';
import LicenceKeyForm from '../forms/LicenceKeyForm';
import { FormatObjectListForDropdown } from '../../selectors/selectors';

interface Props {
  licenceKeyId?: string;
  doneAction: () => void;
}

const AddOrEditLicenceKeyContainer: React.FC<Props> = ({ licenceKeyId, doneAction }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // Global States
  const products = useSelector((state: RootState) => FormatObjectListForDropdown(state.productReducer.products));
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const licenceKey = useSelector((state: RootState) => state.selectedLicenceKeyReducer.licenceKey);

  // Local States
  const [loadingLicencekey, setLoadingLicenceKey] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const fetchProducts = useCallback(async () => {
    await dispatch(getProductsAction());
    setLoadingProducts(false);
  }, [dispatch]);

  const fetchLicenceKey = useCallback(
    async (id) => {
      await dispatch(getLicenceKeyAction(id));
      setLoadingLicenceKey(false);
    },
    [dispatch]
  );

  useEffect(() => {
    fetchProducts();
    // Dismount
    // eslint-disable-next-line no-console
    return () => console.log('dismount');
  }, [dispatch, fetchProducts]);

  useEffect(() => {
    fetchLicenceKey(licenceKeyId);
    // Dismount
    // eslint-disable-next-line no-console
    return () => console.log('dismount');
  }, [dispatch, fetchLicenceKey, licenceKeyId]);

  const handleSave = useCallback(
    async (values) => {
      const licenceKeyToSave = {
        _id: licenceKey ? licenceKey._id : undefined,
        product: values.product,
        active: values.active,
        comment: values.comment,
        licensee: licenceKey ? licenceKey.licensee._id : licensee?._id || '', // FIXME: Typescript is stoopid
      };

      await dispatch(saveLicenceKeyAction({ licenceKey: licenceKeyToSave, amount: values.amount }));
      if (licensee && licensee._id) {
        await dispatch(getLicenseeKeysAction(licensee._id));
      } else {
        await dispatch(getLicenceKeysAction());
      }
      toastr.success('', intl.formatMessage({ defaultMessage: 'Licence Key Saved', id: 'licenceKey.save.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, licenceKey, licensee]
  );
  if (loadingLicencekey || loadingProducts) {
    return <div> loading </div>;
  }
  return (
    <LicenceKeyForm
      handleCancel={doneAction}
      handleSave={handleSave}
      initialValues={licenceKey}
      staff={staff}
      products={products || []}
    />
  );
};

AddOrEditLicenceKeyContainer.defaultProps = {
  licenceKeyId: undefined,
};

export default AddOrEditLicenceKeyContainer;
