import React from 'react';
// Material Ui
import Grid from '@mui/material/Grid';
// ## //
import Games from './Games';
import GamesGraph from './GamesGraph';
import Licensees from './Licensees';
import Keys from './Keys';
import TotalProfit from './TotalProfit';
import LicenseesByPlans from './LicenseesByPlans';

// See https://github.com/devias-io/react-material-dashboard
const StaffDashboard: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Games />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Licensees />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Keys />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <TotalProfit />
      </Grid>
      <Grid item lg={8} md={12} xl={9} xs={12}>
        <GamesGraph />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <LicenseesByPlans />
      </Grid>
    </Grid>
  );
};

export default StaffDashboard;
