import { GET_PRODUCTS_RESPONSE, GET_PRODUCT_RESPONSE, RESET_PRODUCT_RESPONSE } from 'reducer/productReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import ProductApi from 'api/ProductApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Get Licensee Stripe Customer (unique)
 */

export const getProductsAction = createAsyncThunk('product/getAll', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return ProductApi.getProducts().then((products) => {
    dispatch(GET_PRODUCTS_RESPONSE(products));
    dispatch(API_CALL_END());
  });
});

export const getProductAction = createAsyncThunk('product/getOne', (productId: string | undefined, { dispatch }) => {
  if (!productId) {
    dispatch(RESET_PRODUCT_RESPONSE());
    return Promise.resolve();
  }
  dispatch(API_CALL_BEGIN());

  return ProductApi.getProduct(productId).then((product) => {
    dispatch(GET_PRODUCT_RESPONSE(product));
    dispatch(API_CALL_END());
  });
});

export const saveProductAction = createAsyncThunk('product/save', (productBeingAddedOrEdited: any, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return ProductApi.saveProduct(productBeingAddedOrEdited)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getProductsAction());
    });
});
