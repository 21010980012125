import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
// ## //
import { getLicenceesByPlansAction } from 'action/StatisticsAction';
import { RootState } from 'reducer/configureStore';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
  },
}));

interface Props {
  className?: string;
}

const LicenseesByPlans: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataG = useSelector((state: RootState) => state.statisticsReducer.licenseesGraph);

  useEffect(() => {
    dispatch(getLicenceesByPlansAction());
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    legend: {
      position: 'bottom',
    },
    layout: { padding: 0 },
    animation: false,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
    },
  };

  if (dataG) {
    const { countByplans } = dataG;
    const data = {
      datasets: [
        {
          data: countByplans.map((x: any) => x.count),
          backgroundColor: [
            '#0aa6d1',
            '#53b0d4',
            '#77bad7',
            '#95c4da',
            '#b0cedd',
            '#cad8e0',
            '#e2e2e2',
            '#d0c1d4',
            '#bda0c6',
            '#aa80b7',
            '#9660a9',
            '#823f9b',
            '#6c198c',
          ],
          borderWidth: 8,
          borderColor: '#fff',
          hoverBorderColor: '#fff',
        },
      ],
      labels: countByplans.map((x: any) => `${x.text}(${x.count})`),
    };

    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Licensees by Plans" />
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut data={data} options={options} />
          </div>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Licensees by Plans" />
      <CardContent>
        <div className={classes.chartContainer}>
          <CircularProgress />
        </div>
      </CardContent>
    </Card>
  );
};

LicenseesByPlans.defaultProps = {
  className: 'DasboardLicenseesByPlans',
};

export default LicenseesByPlans;
