import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// ## //
import useStyles from './styles/cardHeaderStyle';

type Props = {
  className?: string;
  color?: 'warning' | 'success' | 'danger' | 'info' | 'primary';
  plain?: boolean;
  children: React.ReactNode;
};

const CardHeader: React.FC<Props> = ({ className, children, color, plain, ...rest }: Props) => {
  const classes = useStyles();
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes.cardHeaderPlain]: plain,
    // Color Switches
    [classes.warningCardHeader]: color === 'warning',
    [classes.successCardHeader]: color === 'success',
    [classes.dangerCardHeader]: color === 'danger',
    [classes.infoCardHeader]: color === 'info',
    [classes.primaryCardHeader]: color === 'primary',
    [className || '']: className !== undefined,
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

CardHeader.defaultProps = {
  plain: false,
  className: undefined,
  color: 'primary',
};

export default CardHeader;
