import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { defineMessages, useIntl } from 'react-intl';
// Material UI
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// Theme
import Button from 'theme/CustomButtons/Button';
// Icons
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SportsEsports from '@mui/icons-material/SportsEsports';
import SyncIcon from '@mui/icons-material/Sync';
// ## //
import { getGamesStatsAction, syncGamesAction } from 'action/StatisticsAction';
import CardFooter from 'theme/Card/CardFooter';
import { RootState } from 'reducer/configureStore';
import useStyles from './styles/ElementsGameStyles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Games this week',
    id: 'dashboard.games.title',
  },
  contested: {
    defaultMessage: 'Pending Contest',
    id: 'dashboard.games.contested',
  },
  unsynced: {
    defaultMessage: 'Unsynced Games',
    id: 'dashboard.games.unsynced',
  },
  sync: {
    defaultMessage: 'Upload All to Stripe',
    id: 'dashboard.action.sync',
  },
});

interface Props {
  className?: string;
}

const GamesWidget: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const gamesStats = useSelector((state: RootState) => state.statisticsReducer.games);

  useEffect(() => {
    dispatch(getGamesStatsAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  const handleSync = useCallback(async () => {
    await dispatch(syncGamesAction());
    toastr.success('', intl.formatMessage({ defaultMessage: 'Games Synced', id: 'action.syncall.games' }));
  }, [dispatch, intl]);

  if (gamesStats) {
    const { countActive, countContested, countUnsynced } = gamesStats;
    return (
      <Card className={clsx(classes.root, className)}>
        <CardActionArea component={RouterLink} to="/admin/games">
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
                  {intl.formatMessage(messages.title)}
                </Typography>
                <Typography variant="h3">{countActive}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <SportsEsports className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <div className={classes.difference}>
              {countContested > 0 ? (
                <>
                  <ErrorIcon className={classes.koIcon} />
                  <Typography className={classes.koValue} variant="body2">
                    {countContested}
                  </Typography>
                </>
              ) : (
                <>
                  <CheckCircleIcon className={classes.okIcon} />
                  <Typography className={classes.okValue} variant="body2">
                    {countContested}
                  </Typography>
                </>
              )}
              <Typography variant="caption">{intl.formatMessage(messages.contested)}</Typography>
            </div>
            <div className={classes.difference}>
              {countUnsynced > 0 ? (
                <>
                  <ErrorIcon className={classes.koIcon} />
                  <Typography className={classes.koValue} variant="body2">
                    {countUnsynced}
                  </Typography>
                </>
              ) : (
                <>
                  <CheckCircleIcon className={classes.okIcon} />
                  <Typography className={classes.okValue} variant="body2">
                    {countUnsynced}
                  </Typography>
                </>
              )}
              <Typography variant="caption">{intl.formatMessage(messages.unsynced)}</Typography>
            </div>
          </CardContent>
        </CardActionArea>
        <CardFooter>
          <Button customColor="rose" round onClick={handleSync}>
            <SyncIcon />
            {intl.formatMessage(messages.sync)}
          </Button>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <CircularProgress />
      </CardContent>
    </Card>
  );
};

GamesWidget.defaultProps = {
  className: 'DasboardGames',
};

export default GamesWidget;
