import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import * as userAction from 'action/UserAction';
import { checkSessionAction } from 'action/AuthAction';
import { RootState } from 'reducer/configureStore';
import UserForm from '../forms/UserForm';

const ProfilePage: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.authenticationReducer.user);

  useEffect(() => {
    if (id) {
      dispatch(userAction.getUserAction(id));
    }
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch, id]);

  const handleSave = useCallback(
    async (values) => {
      const userToSave = {
        _id: values._id,
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        primaryEmail: values.primaryEmail,
        staff: values.staff,
      };

      await dispatch(userAction.saveUserAction(userToSave));
      await dispatch(checkSessionAction()); // Update User in Session
      toastr.success('', intl.formatMessage({ defaultMessage: 'Saved', id: 'profile.save.message' }));
      history.push('/profile');
    },
    [dispatch, history, intl]
  );

  const handleCancel = () => history.goBack();

  if (!user || !user._id) {
    return null;
  }
  return <UserForm handleCancel={handleCancel} handleSave={handleSave} initialValues={user} staff={false} />;
};

export default ProfilePage;
