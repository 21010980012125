import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl, defineMessages, IntlShape } from 'react-intl';
// Material Ui
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import CardHeader from 'theme/Card/CardHeader';
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import { ButtonCancel, ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  label: {
    id: 'licensee.users.invite.label',
    defaultMessage: 'emails',
  },
  placeholder: {
    id: 'licensee.users.invite.placeholder',
    defaultMessage: 'Please input one email per line. You can copy paste a list too.',
  },
  emailsValid: {
    defaultMessage: 'Line {line} is not a valid email address.',
    id: 'user.form.error.emailsvalid',
  },
  requiredError: {
    defaultMessage: 'Required',
    id: 'invitation.form.error.emails',
  },
});

type SetValueType = (name: string, value: any) => void;

export const validate = (value: string, setValue: SetValueType, intl: IntlShape): string | true => {
  const validationErrors: string[] = [];
  // eslint-disable-next-line no-param-reassign
  const newValue = value.replace(/[ ,;]/gi, '\n');
  if (value !== newValue) {
    setValue('emails', newValue);
  }
  const emails = newValue.split('\n');
  emails.forEach((val, key) => {
    if (val !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
      validationErrors.push(
        intl.formatMessage(labels.emailsValid, {
          line: key + 1,
        })
      );
    }
  });
  return validationErrors.length > 0 ? validationErrors[0] : true;
};

interface Props {
  handleSave: (data: any) => void;
  handleCancel: () => void;
}

const InvitationForm: React.FC<Props> = ({ handleSave, handleCancel }) => {
  const intl = useIntl();
  const methods = useForm<any>({
    mode: 'onBlur',
  });
  const { handleSubmit, formState, setValue } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardHeader color="primary">
            <h1>
              <FormattedMessage
                defaultMessage="Invite users"
                description="Licensee users invite title"
                id="licensee.users.invite.title"
              />
            </h1>
          </CardHeader>
          <CardBody>
            <TextFieldInput
              rows={4}
              label={intl.formatMessage(labels.label)}
              name="emails"
              placeholder={intl.formatMessage(labels.placeholder)}
              type="textarea"
              fullWidth
              validators={{
                required: intl.formatMessage(labels.requiredError),
                validate: (value) => validate(value, setValue, intl),
              }}
            />
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

export default InvitationForm;
