import React from 'react';
// Prismic
import { RichText } from 'prismic-reactjs';
// Material Ui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FaqType } from 'types';

interface Props {
  prismicDoc: FaqType;
}

const FaqArticle: React.FC<Props> = ({ prismicDoc }) => {
  const title = prismicDoc.data.title.length !== 0 ? RichText.asText(prismicDoc.data.title) : undefined;
  const answer = prismicDoc.data.answer.length !== 0 ? prismicDoc.data.answer : undefined;

  if (title && answer) {
    // console.log(prismicDoc);
    return (
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={0}>
            <RichText render={answer} />
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  }
  return null;
};

export default FaqArticle;
