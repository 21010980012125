import { GET_USERS_RESPONSE } from 'reducer/usersReducer';
import { RESET_USER_RESPONSE, GET_USER_RESPONSE } from 'reducer/selectedUserReducer';
import { API_CALL_BEGIN, API_CALL_ERROR, API_CALL_END } from 'reducer/apiReducer';
import UserApi from 'api/UserApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocaleType, UserType } from 'types';

export const getUsersAction = createAsyncThunk('user/getAll', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.getAllUsers().then((users) => {
    dispatch(GET_USERS_RESPONSE(users));
    dispatch(API_CALL_END());
  });
});

export const saveUserAction = createAsyncThunk(
  'user/save',
  (userBeingAddedOrEdited: Partial<UserType>, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return UserApi.saveUser(userBeingAddedOrEdited)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getUsersAction());
      });
  }
);

export const ResetPasswordAction = createAsyncThunk('user/resetPasswd', (email: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.sendResetLink(email).then(() => {
    dispatch(API_CALL_END());
  });
});

export const getUserAction = createAsyncThunk('user/getOne', (userId: string | undefined, { dispatch }) => {
  if (!userId) {
    dispatch(RESET_USER_RESPONSE());
    return Promise.resolve();
  }
  dispatch(API_CALL_BEGIN());

  return UserApi.getUser(userId).then((user) => {
    dispatch(GET_USER_RESPONSE(user));
    dispatch(API_CALL_END());
  });
});

export const deleteUserAction = createAsyncThunk('user/deleteOne', (userId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.deleteUser(userId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getUsersAction());
    })
    .catch((error) => {
      dispatch(API_CALL_ERROR());
      throw error;
    });
});

export const changeUserPassword = createAsyncThunk('user/changePasswd', (values: any, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.changepasswd(values)
    .then((data) => {
      dispatch(API_CALL_END());
      return data;
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        return Promise.reject(err.response.data.error);
      }
      return Promise.reject({ code: 'GENERIC_ERR', message: 'Generic Error' });
    });
});

export const changeUserPasswordWithToken = createAsyncThunk(
  'user/changePasswdWithToken',
  (values: any, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return UserApi.resetpasswd(values)
      .then((data) => {
        dispatch(API_CALL_END());
        return data;
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          return Promise.reject(err.response.data.error);
        }
        return Promise.reject({ code: 'GENERIC_ERR', message: 'Generic Error' });
      });
  }
);

export const enableUsers = createAsyncThunk('user/enable', (ids: string[], { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.enableUsers(ids).then(() => {
    dispatch(API_CALL_END());
  });
});

export const disableUsers = createAsyncThunk('user/disable', (ids: string[], { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.disableUsers(ids).then(() => {
    dispatch(API_CALL_END());
  });
});

export const setLocaleAction = createAsyncThunk('user/setLocale', (locale: LocaleType, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.setLocale(locale).then(() => {
    dispatch(API_CALL_END());
  });
});
