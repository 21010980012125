import React from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
// Material Ui
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';

const labels = defineMessages({
  required: {
    id: 'form.error.required',
    defaultMessage: 'Required',
  },
});

interface Option {
  value: string;
  label?: string;
  text?: string;
}

interface Props {
  label: string;
  name: string;
  options: Option[];
  readOnly?: boolean;
  optional?: boolean;
}

const SelectInput: React.FC<Props> = ({ label, name, options, readOnly, optional }) => {
  const { errors } = useFormContext();
  const intl = useIntl();
  const error = _.get(errors, name);
  return (
    <FormControl error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Controller
        rules={{ required: !optional }}
        as={
          <Select>
            {options.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label || opt.text}
              </MenuItem>
            ))}
          </Select>
        }
        name={name}
        disabled={readOnly}
      />
      {!!error && <FormHelperText>{intl.formatMessage(labels.required)}</FormHelperText>}
    </FormControl>
  );
};

SelectInput.defaultProps = {
  readOnly: false,
  optional: false,
};

export default SelectInput;
