import { useState } from 'react';

interface State {
  data: string | undefined;
  openModal: boolean;
}

const useObjectModal = (): [State, (key: string) => void, () => void, () => void] => {
  const [editionState, OpenCloseModal] = useState<State>({
    data: undefined,
    openModal: false,
  });
  const handleRowSelect = (licenceKeyId: string): void => {
    OpenCloseModal({ data: licenceKeyId, openModal: true });
  };
  const resetModal = (): void => {
    OpenCloseModal({ data: undefined, openModal: false });
  };
  const openModal = (): void => {
    OpenCloseModal({ data: undefined, openModal: true });
  };

  return [editionState, handleRowSelect, resetModal, openModal];
};

export default useObjectModal;
