import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormattedDate, FormattedNumber } from 'react-intl';

export const dateFormatter = (cell: Date | number): React.ReactNode => {
  let value;
  if (typeof cell === 'number') {
    value = new Date(cell * 1000);
  } else {
    value = new Date(cell);
  }
  return <FormattedDate day="numeric" month="long" value={value} year="numeric" />;
};

export const dateTimeFormatter = (cell: Date | number): React.ReactNode => {
  if (cell === undefined) {
    return ' - ';
  }
  let value;
  if (typeof cell === 'number') {
    value = new Date(cell * 1000);
  } else {
    value = new Date(cell);
  }
  return <FormattedDate day="numeric" hour="numeric" minute="numeric" month="long" value={value} year="numeric" />;
};

export const boolFormatter = (cell: unknown): React.ReactNode => (
  <Checkbox disabled checked={!!cell} color="secondary" size="medium" />
);

export const amountFormatter = (amount: number, currency: string): React.ReactNode => (
  <FormattedNumber
    currency={currency}
    // eslint-disable-next-line react/style-prop-object
    style="currency"
    value={amount}
  />
);

export const rateFormatter = (amount: string): string => `${amount}%`;

export const escapedStringFormatter = (input: string): React.ReactNode => {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};
