import { GET_GAMES_RESPONSE } from 'reducer/gamesReducer';
import { GET_LICENSEE_GAMES_RESPONSE } from 'reducer/selectedLicenseeReducer';
import { GET_GAME_EVENTS_RESPONSE } from 'reducer/selectedGameReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import GameApi from 'api/GameApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Get all games for given licensee
 */

// TODO: Pagination
export const getGamesAction = createAsyncThunk('games/get', async (filter: string | undefined, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return GameApi.getGames(filter).then((games) => {
    dispatch(GET_GAMES_RESPONSE(games));
    dispatch(API_CALL_END());
  });
});

/**
 * Get games for given licensee
 */

export const getLicenseeGamesAction = createAsyncThunk('games/getLicenseeGame', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return GameApi.getLicenseeGames(licenseeId).then((games) => {
    dispatch(GET_LICENSEE_GAMES_RESPONSE(games));
    dispatch(API_CALL_END());
  });
});

/**
 *  Sync Games (Send Counter to Stripe)
 *  It is mainly used to force sync if sync hasn't been made
 */

export const syncGamesAction = createAsyncThunk('games/sync', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return GameApi.syncGames(licenseeId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getLicenseeGamesAction(licenseeId));
    });
});

/**
 * Get all events for a given game
 */

export const getGameEventsAction = createAsyncThunk('games/getEvents', (gameId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return GameApi.getGameEvents(gameId).then((events) => {
    dispatch(GET_GAME_EVENTS_RESPONSE(events));
    dispatch(API_CALL_END());
  });
});

/**
 *  Contest a Game
 */

interface ContestGameActionParams {
  gameId: string;
  reason?: string;
  licenseeId?: string;
  cancellation?: boolean;
}

export const contestGameAction = createAsyncThunk(
  'games/contest',
  ({ gameId, reason, licenseeId, cancellation }: ContestGameActionParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return GameApi.contestGame(gameId, reason, cancellation)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        if (licenseeId) {
          dispatch(getLicenseeGamesAction(licenseeId));
        } else {
          // FIXME: Assume we are on a panel : Break SOC :'(
          dispatch(getGamesAction('contest'));
        }
      });
  }
);

interface ValidateContestGameActionParams {
  gameId: string;
  doValidate?: boolean;
  licenseeId?: string;
}

export const validateContestGameAction = createAsyncThunk(
  'games/validateContest',
  ({ gameId, doValidate, licenseeId }: ValidateContestGameActionParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return GameApi.validateContestGame(gameId, doValidate)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        if (licenseeId) {
          dispatch(getLicenseeGamesAction(licenseeId));
        } else {
          // FIXME: Assume we are on a panel : Break SOC :'(
          dispatch(getGamesAction('contest'));
        }
      });
  }
);
