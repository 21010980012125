import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
// Material Ui
import Typography from '@mui/material/Typography';
// Theme
import Button from 'theme/CustomButtons/Button';
// ## //
import { LicenseeType } from 'types';
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  name: {
    defaultMessage: 'Name',
    id: 'licensees.list.name',
  },
  exploitationAddress: {
    defaultMessage: 'Address',
    id: 'licensees.list.Address',
  },
  plan: {
    defaultMessage: 'Plan',
    id: 'licensees.list.Plan',
  },
  actions: {
    defaultMessage: 'Action',
    id: 'table.action',
  },
  viewAction: {
    defaultMessage: 'View',
    id: 'table.viewAction',
  },
  vat: {
    defaultMessage: 'VTA Number',
    id: 'licensees.list.vta',
  },
  status: {
    defaultMessage: 'Status Room',
    id: 'licensees.list.status',
  },
});

export interface LicenseeListProps {
  licensees: LicenseeType[];
  handleEdit: (id: string) => void;
  staff?: boolean;
}

const planLink = (planName: string, planId: string): React.ReactNode => <Link to={`/plan/${planId}`}>{planName}</Link>;

const LicenseeList: React.FC<LicenseeListProps> = ({ staff, licensees, handleEdit }) => {
  const intl = useIntl();

  const actionsFormatter = (_void: unknown, tableMeta: MUIDataTableMeta): React.ReactNode => (
    <Button round customColor="primary" onClick={() => handleEdit(tableMeta.rowData[0])}>
      {intl.formatMessage(messages.viewAction)}
    </Button>
  );

  // ## //
  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      // This is for the plan formatted to be able to access the Id
      // Column 1
      name: 'plan._id',
      options: {
        empty: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'viewAction',
      label: intl.formatMessage(messages.actions),
      options: {
        filter: false,
        customBodyRender: actionsFormatter,
      },
    },
    {
      name: 'name',
      label: intl.formatMessage(messages.name),
      options: {
        filterType: 'textField',
        // eslint-disable-next-line react/display-name
        customBodyRender: (cell) => <Typography variant="h6">{cell}</Typography>,
      },
    },
    {
      name: 'exploitationAddress',
      label: intl.formatMessage(messages.exploitationAddress),
      options: {
        filterType: 'textField',
      },
    },
  ];
  if (staff) {
    columns.push({
      name: 'vat',
      options: {
        filterType: 'textField',
      },
      label: intl.formatMessage(messages.vat),
    });
    columns.push({
      name: 'status',
      options: {
        filterType: 'multiselect',
        customBodyRender: (cell) => cell || 'Pas de status',
      },
      label: intl.formatMessage(messages.status),
    });
    columns.push({
      name: 'plan.name',
      label: intl.formatMessage(messages.plan),
      options: {
        filterType: 'multiselect',
        customBodyRender: (cell, tableMeta) => (cell ? planLink(cell, tableMeta.rowData[1]) : 'no plan'),
      },
    });
  }

  const options: MUIDataTableOptions = {
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    enableNestedDataAccess: '.',
    tableId: 'LicenseeList',
  };

  // ## //

  return <MUIDataTable title="" data={licensees} options={options} columns={columns} />;
};

LicenseeList.defaultProps = {
  staff: false,
};

export default LicenseeList;
