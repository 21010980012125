import { createSlice } from '@reduxjs/toolkit';
import { LicenseeType } from 'types';

export interface LicenseesReducerDataType {
  licensees: LicenseeType[];
}

const initialState: LicenseesReducerDataType = {
  licensees: [],
};

const licenseesSlice = createSlice({
  name: 'licenseesReducer',
  initialState,
  reducers: {
    GET_LICENSEES_RESPONSE(state, action) {
      return {
        ...state,
        licensees: action.payload,
      };
    },
  },
});

export const { GET_LICENSEES_RESPONSE } = licenseesSlice.actions;

export default licenseesSlice.reducer;
