import { createSlice } from '@reduxjs/toolkit';
import { GameEventType } from 'types';

export interface SelectedGameReducerDataType {
  events: GameEventType[]; // FIXME: type game event
}

const initialState: SelectedGameReducerDataType = {
  events: [],
};

const selectedGameSlice = createSlice({
  name: 'selectedGameReducer',
  initialState,
  reducers: {
    GET_GAME_EVENTS_RESPONSE(state, action) {
      return {
        ...state,
        events: action.payload,
      };
    },
    RESET_GAME_EVENTS() {
      return initialState;
    },
  },
});

export const { GET_GAME_EVENTS_RESPONSE, RESET_GAME_EVENTS } = selectedGameSlice.actions;

export default selectedGameSlice.reducer;
