import React from 'react';
import clsx from 'clsx';
import { defineMessages, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// ## //
const messages = defineMessages({
  title: {
    defaultMessage: 'Cumulated Amounts',
    id: 'dashboard.profit.title',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    content: {
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      fontWeight: 700,
    },
    avatar: {
      backgroundColor: '#ffffff',
      color: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    icon: {
      height: 32,
      width: 32,
    },
  })
);

interface Props {
  className?: string;
}

const TotalProfit: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.title} color="inherit" gutterBottom variant="body2">
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography color="inherit" variant="h3">
              $23,200
            </Typography>
            <Typography color="inherit" variant="h3">
              €23,200
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.defaultProps = {
  className: undefined,
};

export default TotalProfit;
