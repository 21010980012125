import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
// MaterialUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
// Icons
import Group from '@mui/icons-material/Group';
import AttachMoney from '@mui/icons-material/AttachMoney';
import VpnKey from '@mui/icons-material/VpnKey';
import SportsEsports from '@mui/icons-material/SportsEsports';
import ExposureIcon from '@mui/icons-material/Exposure';
import ExtensionIcon from '@mui/icons-material/Extension';
import { RootState } from 'reducer/configureStore';

// ## //

const messages = defineMessages({
  users: {
    defaultMessage: 'Users',
    id: 'menu.users',
  },
  plans: {
    defaultMessage: 'Plans',
    id: 'menu.plans',
  },
  licenceKeys: {
    defaultMessage: 'Licence Keys',
    id: 'menu.licenceKeys',
  },
  games: {
    defaultMessage: 'Games',
    id: 'menu.games',
  },
  rates: {
    defaultMessage: 'Tax Rates',
    id: 'menu.rates',
  },
  product: {
    defaultMessage: 'Products',
    id: 'menu.products',
  },
});

interface AdminRoute {
  path: string;
  title: keyof typeof messages;
  icon: JSX.Element;
}

// FIXME redondant code with MenuProfile.js
export const adminRoutes: AdminRoute[] = [
  {
    path: '/admin/users',
    title: 'users',
    icon: <Group fontSize="small" />,
  },
  {
    path: '/admin/plans',
    title: 'plans',
    icon: <AttachMoney fontSize="small" />,
  },
  {
    path: '/admin/licencekeys',
    title: 'licenceKeys',
    icon: <VpnKey fontSize="small" />,
  },
  {
    path: '/admin/games',
    title: 'games',
    icon: <SportsEsports fontSize="small" />,
  },
  {
    path: '/admin/rates',
    title: 'rates',
    icon: <ExposureIcon fontSize="small" />,
  },
  {
    path: '/admin/products',
    title: 'product',
    icon: <ExtensionIcon fontSize="small" />,
  },
];

const AdminMenu: React.FC = () => {
  const { pathname } = useLocation();
  const intl = useIntl();

  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);

  const match = (route: string): boolean => route === pathname;

  if (staff) {
    return (
      <List>
        {adminRoutes.map((route) => (
          <ListItem key={route.title} selected={match(route.path)} component={Link} button to={route.path}>
            <ListItemIcon>
              <Icon>{route.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage(messages[route.title])} />
          </ListItem>
        ))}
      </List>
    );
  }
  return null;
};

export default AdminMenu;
