import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
// Material Ui
import Alert from '@mui/material/Alert';
// ## //
import { dateFormatter, boolFormatter, amountFormatter } from '../datatables/Formatters';
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';
import { Invoice, ProvisionalInvoiceType } from 'types';

// This is an external Link, however
// TODO: See if there is a MaterialUi replacement
const refFormatter = (cell: string, hostedInvoiceUrl: string): React.ReactNode => (
  <a href={hostedInvoiceUrl} target="_blank" rel="noopener noreferrer">
    {cell}
  </a>
);

const messages = defineMessages({
  reference: {
    defaultMessage: 'Reference',
    id: 'licenseeinvoices.list.number',
  },
  amount: {
    defaultMessage: 'Amount',
    id: 'licenseeinvoices.list.amount',
  },
  paid: {
    defaultMessage: 'Paid',
    id: 'licenseeinvoices.list.paid',
  },
  date: {
    defaultMessage: 'Date',
    id: 'licenseeinvoices.list.date',
  },
});

interface Props {
  invoices: Invoice[];
  provisionalInvoice: ProvisionalInvoiceType | undefined;
}

const InvoicesList: React.FC<Props> = ({ invoices, provisionalInvoice }) => {
  const intl = useIntl();

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id', // #0
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'hosted_invoice_url', // #1
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'currency', // #2
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'number',
      label: intl.formatMessage(messages.reference),
      options: {
        customBodyRender: (cell, tableData) =>
          refFormatter(
            cell,
            tableData.rowData[1] // #1 is hosted_invoice_url
          ),
      },
    },
    {
      name: 'total',
      label: intl.formatMessage(messages.amount),
      options: {
        customBodyRender: (cell, tableMeta) =>
          amountFormatter(
            cell / 100,
            tableMeta.rowData[2] // #2 is Currency
          ),
      },
    },
    {
      name: 'paid',
      label: intl.formatMessage(messages.paid),
      options: {
        customBodyRender: boolFormatter,
      },
    },
    {
      name: 'date',
      label: intl.formatMessage(messages.date),
      options: {
        customBodyRender: dateFormatter,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    tableId: 'InvoicesList',
    textLabels: TextLabels(intl),
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    selectableRows: 'none',
  };

  return (
    <>
      {provisionalInvoice?.number && (
        <Alert severity="info">
          <FormattedMessage
            defaultMessage="Next invoice will be billed on {date}
                                                for an amout of {amount}"
            id="licenseeinvoices.nextinvoice"
            values={{
              date: dateFormatter(new Date(provisionalInvoice.date * 1000)),
              amount: amountFormatter(provisionalInvoice.total / 100, provisionalInvoice.currency),
            }}
          />
        </Alert>
      )}
      <MUIDataTable title="" data={invoices} columns={columns} options={options} />
    </>
  );
};

export default InvoicesList;
