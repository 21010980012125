import axios from './ApiConfig';

interface SaveProductParam {
  product: any;
}

class ProductApi {
  static getProducts(): Promise<any> {
    return axios.get('/api/products').then((response) => response.data);
  }

  static getProduct(productId: string): Promise<any> {
    return axios.get(`/api/product/${productId}`).then((response) => response.data);
  }

  static saveProduct(param: SaveProductParam): Promise<any> {
    const product = { ...param }; // to avoid manipulating object passed in.
    return axios.post('/api/product', { product }).then((response) => response.data);
  }
}

export default ProductApi;
