import makeStyles from '@mui/styles/makeStyles';

const gridContainerStyles = makeStyles({
  grid: {
    marginRight: '-15px',
    marginLeft: '-15px',
    width: 'auto',
  },
});

export default gridContainerStyles;
