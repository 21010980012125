import {
  GET_GAMES_STATS,
  GET_LICENSEES_STATS,
  GET_KEYS_STATS,
  GET_CASH_STATS,
  GET_LICENSEES_GRAPHBYPLAN,
  GET_GAMES_GRAPH,
} from 'reducer/statisticsReducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import StatsApi from 'api/StatsApi';
import GameApi from 'api/GameApi';

export const getGamesStatsAction = createAsyncThunk('stats/game', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getGames().then((stats) => {
    dispatch(GET_GAMES_STATS(stats));
    dispatch(API_CALL_END());
  });
});

export const syncGamesAction = createAsyncThunk('stats/sync', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return GameApi.syncAllGames()
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getGamesStatsAction());
    });
});

export const getLicenseesStatsAction = createAsyncThunk('stats/licensees', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getLicensees().then((stats) => {
    dispatch(GET_LICENSEES_STATS(stats));
    dispatch(API_CALL_END());
  });
});

export const getKeysStatsAction = createAsyncThunk('stats/keys', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getKeys().then((stats) => {
    dispatch(GET_KEYS_STATS(stats));
    dispatch(API_CALL_END());
  });
});

export const getCashStatsAction = createAsyncThunk('stats/cash', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getCash().then((stats) => {
    dispatch(GET_CASH_STATS(stats));
    dispatch(API_CALL_END());
  });
});

export const getLicenceesByPlansAction = createAsyncThunk('stats/byPlans', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getLicenseesByPlans().then((stats) => {
    dispatch(GET_LICENSEES_GRAPHBYPLAN(stats));
    dispatch(API_CALL_END());
  });
});

export const getGamesGraphAction = createAsyncThunk('stats/gamesGraph', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return StatsApi.getGamesGraph().then((stats) => {
    dispatch(GET_GAMES_GRAPH(stats));
    dispatch(API_CALL_END());
  });
});
