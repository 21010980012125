/**
 * Password validation RegEx for JavaScript
 *
 * Passwords must be
 * - At least 8 characters long, max length anything
 * - Include at least 1 lowercase letter
 * - 1 capital letter
 * - 1 number
 * - 1 special character => !@#$%^&*
 *
 *
 */

// FIXME: Seems buggy
// /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@"'$-.#,?()$%^&*])[\w!@"'$-.#,?()$%^&*]{8,}$/
//     .test(password)
// FIXME: Less nice but actually works and allow for any special character
export default (password: string): boolean =>
  !!(
    password.length >= 8 &&
    password.match(/.*[A-Z].*/) &&
    password.match(/.*[^A-Za-z0-9].*/) &&
    password.match(/.*[0-9].*/)
  );
