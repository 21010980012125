import axios from './ApiConfig';

class StatsApi {
  static getGames(): Promise<any> {
    return axios.get('/api/statistics/games').then((response) => response.data);
  }

  static getLicensees(): Promise<any> {
    return axios.get('/api/statistics/licensees').then((response) => response.data);
  }

  static getKeys(): Promise<any> {
    return axios.get('/api/statistics/keys').then((response) => response.data);
  }

  static getCash(): Promise<any> {
    return axios.get('/api/statistics/cash').then((response) => response.data);
  }

  static getLicenseesByPlans(): Promise<any> {
    return axios.get('/api/statistics/licenseesbyplan').then((response) => response.data);
  }

  static getGamesGraph(): Promise<any> {
    return axios.get('/api/statistics/gamesgraph').then((response) => response.data);
  }
}

export default StatsApi;
