import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { ResetPasswordAction } from 'action/UserAction';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';

interface Props {
  closeModal: () => void;
}

const ForgotPasswordContainer: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const submitResetPasswordRequest = useCallback(
    async (values) => {
      const { email } = values;
      await dispatch(ResetPasswordAction(email));
      toastr.success(
        '',
        intl.formatMessage(
          {
            defaultMessage: 'An email was sent to your email address {email}.',
            id: 'action.forgotpassword.sentmail',
          },
          {
            email,
          }
        )
      );
      closeModal();
    },
    [closeModal, dispatch, intl]
  );

  return <ForgotPasswordForm onSubmit={submitResetPasswordRequest} />;
};

export default ForgotPasswordContainer;
