import { createSlice } from '@reduxjs/toolkit';

export interface ApiReducerDataType {
  apiCallsInProgress: number;
}

const initialState: ApiReducerDataType = {
  apiCallsInProgress: 0,
};

const apiSlice = createSlice({
  name: 'apiReducer',
  initialState,
  reducers: {
    API_CALL_BEGIN(state) {
      return { ...state, apiCallsInProgress: state.apiCallsInProgress + 1 };
    },
    API_CALL_ERROR(state) {
      return { ...state, apiCallsInProgress: state.apiCallsInProgress - 1 };
    },
    API_CALL_END(state) {
      return { ...state, apiCallsInProgress: state.apiCallsInProgress - 1 };
    },
  },
});

export const { API_CALL_BEGIN, API_CALL_ERROR, API_CALL_END } = apiSlice.actions;

export default apiSlice.reducer;
