import React, { useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// ## //
import { setRedirectUrl } from 'action/AuthAction';
import { RootState } from 'reducer/configureStore';

interface Props extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: React.FC<RouteComponentProps<{}>> | React.ComponentClass<unknown> | React.FC<unknown>;
  childProps?: Record<string, unknown>;
}

const AdminRoute: React.FC<Props> = ({ component, childProps, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const Component = component;

  useEffect(() => {
    if (!staff) {
      // set the current url/path for future redirection (we use a Redux action)
      // then redirect (we use a React Router method)
      if (!isLoggedIn) {
        const currentURL = history.location.pathname;
        if (currentURL !== '/login') {
          dispatch(setRedirectUrl(currentURL));
          history.push('/login');
        }
      } else {
        history.push('/');
      }
    }
  }, [dispatch, history, isLoggedIn, staff]);

  if (staff) {
    return <Route {...rest} render={(routeProps) => <Component {...routeProps} {...childProps} />} />;
  }
  return null;
};

AdminRoute.defaultProps = {
  childProps: {},
};

export default AdminRoute;
