import { createSlice } from '@reduxjs/toolkit';
import { UserType } from 'types';

export interface SelectedUserReducerDataType {
  user: UserType | undefined;
}

const initialState: SelectedUserReducerDataType = {
  user: undefined,
};

const selectedUserSlice = createSlice({
  name: 'selectedUserReducer',
  initialState,
  reducers: {
    GET_USER_RESPONSE(state, action) {
      return {
        ...state,
        user: action.payload,
      };
    },
    RESET_USER_RESPONSE() {
      return initialState;
    },
  },
});

export const { GET_USER_RESPONSE, RESET_USER_RESPONSE } = selectedUserSlice.actions;

export default selectedUserSlice.reducer;
