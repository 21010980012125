import { createSlice } from '@reduxjs/toolkit';
import { PlanType } from 'types';

export interface SelectedPlanReducerDataType {
  plan: PlanType | undefined;
}

const initialState: SelectedPlanReducerDataType = {
  plan: undefined,
};

const selectedPlanSlice = createSlice({
  name: 'selectedPlanReducer',
  initialState,
  reducers: {
    GET_PLAN_RESPONSE(state, action) {
      return {
        ...state,
        plan: action.payload,
      };
    },
    RESET_PLAN_RESPONSE() {
      return initialState;
    },
  },
});

export const { GET_PLAN_RESPONSE, RESET_PLAN_RESPONSE } = selectedPlanSlice.actions;

export default selectedPlanSlice.reducer;
