import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import SwitchInput from 'theme/CustomInput/CustomSwitch';
import TextFieldInput from 'theme/CustomInput/CustomTextField';
import Select from 'theme/CustomInput/CustomSelect';
// ## //
import Alert from '@mui/material/Alert';
import { LicenceKeyType, OptionForDropDown } from 'types';
import { ButtonCancel, ButtonDelete, ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  comment: {
    id: 'licencekey.form.comment',
    defaultMessage: 'Please provide comment',
  },
  key: {
    id: 'licencekey.form.key',
    defaultMessage: 'Licence Key',
  },
  active: {
    id: 'licencekey.form.active',
    defaultMessage: 'Is active',
  },
  linkedUuid: {
    id: 'licencekey.form.isLinked',
    defaultMessage: 'Is Linked',
  },
  product: {
    id: 'licencekey.form.product',
    defaultMessage: 'Product',
  },
  productReadOnly: {
    id: 'licencekey.form.productReadOnly',
    defaultMessage: 'Defined products cannot be changed',
  },
  amount: {
    id: 'licencekey.form.amount',
    defaultMessage: 'Amount of keys to be created',
  },
});

interface LicenceKeyFormType extends LicenceKeyType {
  amount: number;
}

interface Props {
  handleSave: (values: LicenceKeyFormType) => void;
  handleCancel: () => void;
  handleDelete?: () => void;
  initialValues?: Partial<LicenceKeyFormType>;
  staff?: boolean;
  products: OptionForDropDown[];
}

const LicenceKeyForm: React.FC<Props> = ({
  handleSave,
  handleDelete,
  handleCancel,
  initialValues = { active: true, product: '', amount: 1 },
  staff,
  products,
}) => {
  const methods = useForm<LicenceKeyFormType>({
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const intl = useIntl();
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup>
              <TextFieldInput
                label={intl.formatMessage(labels.comment)}
                name="comment"
                placeholder="5"
                type="text"
                optional
              />
              {staff && (
                <>
                  <SwitchInput label={intl.formatMessage(labels.active)} name="active" />
                  {!!initialValues.product && (
                    <Alert severity="warning">{intl.formatMessage(labels.productReadOnly)}</Alert>
                  )}
                  <Select
                    label={intl.formatMessage(labels.product)}
                    name="product"
                    readOnly={!!initialValues.product}
                    options={products}
                  />
                </>
              )}
              {!initialValues._id && (
                <TextFieldInput label={intl.formatMessage(labels.amount)} name="amount" placeholder="1" type="text" />
              )}
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonCancel onClick={handleCancel} />
            {handleDelete && <ButtonDelete onClick={handleDelete} />}
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

LicenceKeyForm.defaultProps = {
  handleDelete: undefined,
  initialValues: { active: true, product: '', amount: 1 },
  staff: false,
};

export default LicenceKeyForm;
