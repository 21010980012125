import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { RoleType, UserType } from 'types';

export interface AuthenticationReducerType {
  checking: boolean;
  loggedIn: boolean;
  user: UserType | Record<string, never> | undefined;
  redirectUrl: string | undefined;
  licenseePermission: RoleType | undefined; // FIXME Permission Type
}

const initialState: AuthenticationReducerType = {
  user: {},
  loggedIn: false,
  checking: false,
  redirectUrl: undefined,
  licenseePermission: undefined,
};

const authenticationSlice = createSlice({
  name: 'authenticationReducer',
  initialState,
  reducers: {
    API_CALL_SESSION_START(state) {
      return { ...state, checking: true };
    },
    API_CALL_SESSION_END(state) {
      return { ...state, checking: false };
    },
    LOGIN_USER_RESPONSE(state, action) {
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    },
    CHECK_SESSION_RESPONSE(state, action) {
      return {
        ...state,
        loggedIn: action.payload && action.payload.loggedIn ? action.payload.loggedIn : initialState.loggedIn,
        user: action.payload && action.payload.user ? action.payload.user : initialState.user,
      };
    },
    SET_LICENSEE_PERMISSION(state, action) {
      if (!action.payload || !action.payload.users) {
        return state;
      }
      const present = _.find(action.payload.users, (u) => u.user._id === state.user?._id);
      if (present) {
        return {
          ...state,
          licenseePermission: present.role,
        };
      }
      return state;
    },
    LOGOUT_USER_RESPONSE() {
      return initialState;
    },
    SET_REDIRECT_URL_RESPONSE(state, action) {
      return {
        ...state,
        redirectUrl: action.payload,
      };
    },
  },
});

export const {
  API_CALL_SESSION_START,
  API_CALL_SESSION_END,
  LOGIN_USER_RESPONSE,
  CHECK_SESSION_RESPONSE,
  SET_LICENSEE_PERMISSION,
  LOGOUT_USER_RESPONSE,
  SET_REDIRECT_URL_RESPONSE,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
