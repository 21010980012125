import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// Material Ui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// ## //
import countries from './countries';

function countryToFlag(isoCode: string): string {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

interface Props {
  name: string;
  label: string;
}

const CountrySelect: React.FC<Props> = ({ name, label }) => {
  const { control } = useFormContext();
  return (
    <Controller
      render={(props) => (
        <Autocomplete
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          options={countries}
          getOptionLabel={(option) => option.label}
          renderOption={(_props, option) => (
            <span>
              {countryToFlag(option.code)}
              {option.label}
            </span>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Choose a country"
              variant="outlined"
            />
          )}
          onChange={(_, data) => props.onChange(data)}
        />
      )}
      name={name}
      control={control}
      label={label}
    />
  );
};

export default CountrySelect;
