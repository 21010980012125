import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// material-ui components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
// core components
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
// ## //
import useStyles from './styles/customTabsStyle';

interface CustomTab {
  tabValue: string;
  tabName: string | React.ReactNode;
  tabIcon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> | string;
  tabContent?: React.ReactNode;
}

interface Props {
  headerColor: 'warning' | 'success' | 'danger' | 'info' | 'primary';
  title?: string;
  tabs: CustomTab[];
  rtlActive?: boolean;
  plainTabs?: boolean;
  handleChange: (event: React.ChangeEvent<unknown>, value: string) => void;
  value: string;
  globalContent?: string | React.ReactNode;
}

const CustomTabs: React.FC<Props> = ({
  headerColor,
  plainTabs,
  tabs,
  title,
  rtlActive,
  handleChange,
  value,
  globalContent,
}) => {
  const classes = useStyles();
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
          }}
        >
          {tabs.map((prop) => {
            if (prop.tabIcon) {
              const TabIcon = prop.tabIcon;
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    // label: classes.tabLabel,
                    selected: classes.tabSelected,
                  }}
                  key={prop.tabValue}
                  value={prop.tabValue}
                  label={prop.tabName}
                  icon={typeof prop.tabIcon === 'string' ? <Icon>{prop.tabIcon}</Icon> : <TabIcon />}
                />
              );
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  // label: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
                key={prop.tabValue}
                value={prop.tabValue}
                label={prop.tabName}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {globalContent ||
          tabs.map((prop) => {
            if (prop.tabValue === value) {
              return <div key={prop.tabValue}>{prop.tabContent}</div>;
            }
            return null;
          })}
      </CardBody>
    </Card>
  );
};

CustomTabs.defaultProps = {
  title: undefined,
  globalContent: undefined,
  rtlActive: false,
  plainTabs: false,
};

export default CustomTabs;
