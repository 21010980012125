import makeStyles from '@mui/styles/makeStyles';

const customToolbarSelectStyles = makeStyles({
  iconButton: {},
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
  icon: {},
});

export default customToolbarSelectStyles;
