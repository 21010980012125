import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
// Icons
import GetAppIcon from '@mui/icons-material/GetApp';
// Theme
import Button from 'theme/CustomButtons/Button';
// ## //
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

interface DownloadLinkProps {
  downloadKey: string;
  downloadAction: (key: string) => void;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ downloadKey, downloadAction }) => (
  <Button justIcon round customColor="primary" onClick={() => downloadAction(downloadKey)}>
    <GetAppIcon />
  </Button>
);

const messages = defineMessages({
  key: {
    defaultMessage: 'File Name',
    id: 'download.file',
  },
  size: {
    defaultMessage: 'File Size',
    id: 'download.size',
  },
});

interface Props {
  documents: any;
  downloadAction: (key: string) => void;
}

const DownloadList: React.FC<Props> = ({ documents, downloadAction }) => {
  const intl = useIntl();
  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'Action',
      options: {
        customBodyRender: (cell, tableMeta) => (
          <DownloadLink downloadKey={tableMeta.rowData[2]} downloadAction={downloadAction} />
        ),
      },
    },
    {
      name: 'Key',
      label: intl.formatMessage(messages.key),
    },
    {
      name: 'Size',
      label: intl.formatMessage(messages.size),
      options: {
        customBodyRender: bytesToSize,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    tableId: 'DownloadList',
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    viewColumns: false,
    sortFilterList: false,
    search: false,
    print: false,
    pagination: false,
    filter: false,
    download: false,
  };

  return <MUIDataTable title="" columns={columns} data={documents} options={options} />;
};

export default DownloadList;
