import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import { getLicenseeKeysAction } from 'action/LicenseeAction';
import { enableLicenceKeysAction, disableLicenceKeysAction, unlinkLicenceKeysAction } from 'action/LicenceKeyAction';
import LicenceKeyDialog from 'components/Dialog/LicenceKeyDialog';
import { RootState } from 'reducer/configureStore';
import LicenceKeyList from '../../lists/LicenceKeyList';
import ButtonNew from '../../common/Buttons/ButtonNew';
import useObjectModal from '../../Hooks/useObjectModal';

const LicenseeKeysContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [editionState, handleRowSelect, resetModal, openModal] = useObjectModal();
  // Global State
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const licencekeys = useSelector((state: RootState) => state.selectedLicenseeReducer.licenseeKeys);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);

  useEffect(() => {
    dispatch(getLicenseeKeysAction(id));
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, id]);

  // Move to a hook maybe ??
  const enableLicenceKeys = useCallback(
    async (ids: string[]) => {
      await dispatch(enableLicenceKeysAction(ids));
      dispatch(getLicenseeKeysAction(id));
    },
    [dispatch, id]
  );
  const disableLicenceKeys = useCallback(
    async (ids: string[]) => {
      await dispatch(disableLicenceKeysAction(ids));
      dispatch(getLicenseeKeysAction(id));
    },
    [dispatch, id]
  );
  const unlink = useCallback(
    async (ids: string[]) => {
      await dispatch(unlinkLicenceKeysAction(ids));
      dispatch(getLicenseeKeysAction(id));
    },
    [dispatch, id]
  );

  const actions = {
    enableLicenceKeysAction: enableLicenceKeys,
    disableLicenceKeysAction: disableLicenceKeys,
    unlinkAction: unlink,
  };

  if (licensee) {
    return (
      <>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem md={9}>
                <h1>
                  <FormattedMessage defaultMessage="Licence keys" id="licencekey.list.title" />
                </h1>
              </GridItem>
              <GridItem md={2}>{staff && openModal && <ButtonNew onClick={openModal} />}</GridItem>
              <GridItem md={2} />
            </GridContainer>
          </CardHeader>
          <CardBody>
            <LicenceKeyList
              action={actions}
              licencekeys={licencekeys}
              staff={staff}
              handleRowSelect={handleRowSelect}
            />
          </CardBody>
        </Card>
        <LicenceKeyDialog editionState={editionState} resetModal={resetModal} />
      </>
    );
  }

  return null;
};

export default LicenseeKeysContainer;
