import { createSlice } from '@reduxjs/toolkit';
import {
  CustomerType,
  DownloadType,
  GameType,
  Invoice,
  LicenceKeyType,
  LicenseeType,
  ProvisionalInvoiceType,
} from 'types';

export interface SelectedLicenseeReducerDataType {
  // FIXME: Type
  licensee?: LicenseeType;
  licenseeKeys: LicenceKeyType[];
  licenseeInvitations: any;
  licenseeGames: GameType[];
  customer?: CustomerType;
  provisionalInvoice?: ProvisionalInvoiceType;
  invoices: {
    data: Invoice[];
    has_more: boolean;
  };
  downloads: DownloadType[];
  downloadLink: string | undefined;
}

const initialState: SelectedLicenseeReducerDataType = {
  licensee: undefined,
  licenseeKeys: [],
  licenseeInvitations: [],
  licenseeGames: [],
  customer: undefined,
  provisionalInvoice: undefined,
  invoices: {
    data: [],
    has_more: false,
  },
  downloads: [],
  downloadLink: undefined,
};

const selectedLicenseeSlice = createSlice({
  name: 'selectedLicenseeReducer',
  initialState,
  reducers: {
    GET_LICENSEE_RESPONSE(state, action) {
      return {
        ...state,
        licensee: action.payload,
      };
    },
    GET_LICENSEE_KEYS_RESPONSE(state, action) {
      return {
        ...state,
        licenseeKeys: action.payload,
      };
    },
    GET_LICENSEE_CUSTOMER_RESPONSE(state, action) {
      return {
        ...state,
        customer: action.payload,
      };
    },
    GET_LICENSEE_INVITATIONS_RESPONSE(state, action) {
      return {
        ...state,
        licenseeInvitations: action.payload,
      };
    },
    GET_LICENSEE_GAMES_RESPONSE(state, action) {
      return {
        ...state,
        licenseeGames: action.payload,
      };
    },
    GET_LICENSEE_INVOICES_RESPONSE(state, action) {
      return {
        ...state,
        invoices: action.payload.invoices,
        provisionalInvoice: action.payload.provisionalInvoice,
      };
    },
    GET_LICENSEE_BUCKETS_RESPONSE(state, action) {
      return {
        ...state,
        downloads: action.payload.downloads,
      };
    },
    GET_LICENSEE_BUCKET_LINK(state, action) {
      return {
        ...state,
        downloadLink: action.payload.link,
      };
    },
  },
});

export const {
  GET_LICENSEE_RESPONSE,
  GET_LICENSEE_KEYS_RESPONSE,
  GET_LICENSEE_CUSTOMER_RESPONSE,
  GET_LICENSEE_INVITATIONS_RESPONSE,
  GET_LICENSEE_GAMES_RESPONSE,
  GET_LICENSEE_INVOICES_RESPONSE,
  GET_LICENSEE_BUCKETS_RESPONSE,
  GET_LICENSEE_BUCKET_LINK,
} = selectedLicenseeSlice.actions;

export default selectedLicenseeSlice.reducer;
