import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// store
import { RootState } from 'reducer/configureStore';
import * as LicenceKeyAction from 'action/LicenceKeyAction';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
// ## //
import LicenceKeyDialog from 'components/Dialog/LicenceKeyDialog';
import LicenceKeyList from '../../lists/LicenceKeyList';
import useObjectModal from '../../Hooks/useObjectModal';

const LicenceKeyListPage: React.FC = () => {
  const [editionState, handleRowSelect, resetModal] = useObjectModal();
  const licencekeys = useSelector((state: RootState) => state.licencekeysReducer.licencekeys);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LicenceKeyAction.getLicenceKeysAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h1>
            <FormattedMessage defaultMessage="Licence Keys" description="Licence Keys Title" id="licencekeys.title" />
          </h1>
        </CardHeader>
        <CardBody>
          <LicenceKeyList
            staff
            action={LicenceKeyAction}
            licencekeys={licencekeys}
            showLicensee
            handleRowSelect={handleRowSelect}
          />
        </CardBody>
      </Card>
      <LicenceKeyDialog editionState={editionState} resetModal={resetModal} />
    </>
  );
};

export default LicenceKeyListPage;
