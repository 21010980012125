import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
// @mui/material components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from 'theme/CustomButtons/Button';
// @mui/icons-material
import PersonIcon from '@mui/icons-material/Person';
// theme components
import CustomDropdown from 'theme/CustomDropdown/CustomDropdown';
// ## //
import { logoutUserAction } from 'action/AuthAction';

import { RootState } from 'reducer/configureStore';
import useStyles from './styles/headerLinksStyle';
import LocaleButton from './LocaleButton';

const messages = defineMessages({
  profile: {
    defaultMessage: 'Profile',
    id: 'menu.profile',
  },
  changePasswd: {
    defaultMessage: 'Change password',
    id: 'button.changePasswd',
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'menu.logout',
  },
  welcome: {
    defaultMessage: 'Welcome {firstName} {lastName}',
    id: 'menu.welcome',
  },
});

const RightLinks: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.authenticationReducer.user);
  const isAuth = useSelector((state: RootState) => state.authenticationReducer.loggedIn);

  const handleLogout = useCallback(async () => {
    await dispatch(logoutUserAction());
    history.push('/login');
  }, [dispatch, history]);

  if (!user) {
    return null;
  }

  return (
    <List className={classes.list}>
      {isAuth && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={intl.formatMessage(messages.welcome, {
              firstName: user.firstName.toUpperCase(),
              lastName: user.lastName.toUpperCase(),
            })}
            buttonProps={{
              className: classes.navLink,
              customColor: 'transparent',
            }}
            buttonIcon={PersonIcon}
            dropdownList={[
              <Link to="/profile" key="profile" className={classes.dropdownLink}>
                {intl.formatMessage(messages.profile)}
              </Link>,
              <Link to="/change-passwd" key="passwd" className={classes.dropdownLink}>
                {intl.formatMessage(messages.changePasswd)}
              </Link>,
              <Button onClick={handleLogout} key="logout" className={classes.dropdownLink} customColor="danger">
                {intl.formatMessage(messages.logout)}
              </Button>,
            ]}
          />
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        <LocaleButton />
      </ListItem>
    </List>
  );
};

export default RightLinks;
