import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { IdObj, RoleType } from 'types';
// Material Ui
import ButtonGroup from '@mui/material/ButtonGroup';
// Theme
import Button from 'theme/CustomButtons/Button';
// ## //
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
    id: 'user.email',
  },
  firstName: {
    defaultMessage: 'Firstname',
    id: 'user.firstname',
  },
  lastName: {
    defaultMessage: 'Lastname',
    id: 'user.lastname',
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'licenseeusers.list.actions',
  },
  role: {
    defaultMessage: 'Role',
    id: 'user.role',
  },
});

interface ChangeRoleParam extends IdObj {
  role: RoleType;
}

interface Props {
  licenseeUsers: any;
  handleRemoveUser: (idObj: IdObj) => void;
  changeRole: (idObj: ChangeRoleParam) => void;
  allowActions?: boolean;
}

const LicenseeUsers: React.FC<Props> = ({ licenseeUsers, handleRemoveUser, changeRole, allowActions }) => {
  const intl = useIntl();

  const buttonFormatter = (_void: unknown, tableMeta: MUIDataTableMeta): React.ReactNode => {
    const id = tableMeta.rowData[0];
    const role = tableMeta.rowData[4];
    return (
      <ButtonGroup>
        <Button
          round
          customColor="danger"
          size="small"
          id={`delUser_${id}`}
          onClick={() => handleRemoveUser({ _id: id })}
        >
          <FormattedMessage defaultMessage="Remove" id="licenseeusers.list.removebutton" />
        </Button>
        {role === 'staff' ? (
          <Button
            round
            size="small"
            customColor="primary"
            id={`makeOwner_${id}`}
            onClick={() => changeRole({ _id: id, role: 'owner' })}
          >
            <FormattedMessage defaultMessage="make Owner" id="licenseeusers.list.makeOwner" />
          </Button>
        ) : (
          <Button
            round
            customColor="primary"
            size="small"
            id={`makeStaff_${id}`}
            onClick={() => changeRole({ _id: id, role: 'staff' })}
          >
            <FormattedMessage defaultMessage="make Staff" id="licenseeusers.list.makeStaff" />
          </Button>
        )}
      </ButtonGroup>
    );
  };

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: 'user._id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'user.primaryEmail',
      label: intl.formatMessage(messages.email),
    },
    {
      name: 'user.firstName',
      label: intl.formatMessage(messages.firstName),
    },
    {
      name: 'user.lastName',
      label: intl.formatMessage(messages.lastName),
    },
    {
      name: 'role',
      label: intl.formatMessage(messages.role),
    },
  ];
  if (allowActions) {
    columns.push({
      name: 'actions',
      options: {
        empty: true,
        customBodyRender: buttonFormatter,
      },
    });
  }

  const options: MUIDataTableOptions = {
    tableId: 'LicenseeUsersList',
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    enableNestedDataAccess: '.',
  };

  return <MUIDataTable title="" data={licenseeUsers} options={options} columns={columns} />;
};

LicenseeUsers.defaultProps = {
  allowActions: false,
};

export default LicenseeUsers;
