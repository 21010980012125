import { createSlice } from '@reduxjs/toolkit';
import { ProductType } from 'types';

export interface ProductReducerDataType {
  products: ProductType[];
  product: ProductType | undefined;
}

const initialState: ProductReducerDataType = {
  products: [],
  product: undefined,
};

const productSlice = createSlice({
  name: 'productReducer',
  initialState,
  reducers: {
    GET_PRODUCTS_RESPONSE(state, action) {
      return {
        ...state,
        products: action.payload,
      };
    },
    GET_PRODUCT_RESPONSE(state, action) {
      return {
        ...state,
        product: action.payload,
      };
    },
    RESET_PRODUCT_RESPONSE(state) {
      return {
        ...state,
        product: initialState.product,
      };
    },
  },
});

export const { GET_PRODUCTS_RESPONSE, GET_PRODUCT_RESPONSE, RESET_PRODUCT_RESPONSE } = productSlice.actions;

export default productSlice.reducer;
