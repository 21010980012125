import { CustomerType } from 'types';
import axios from './ApiConfig';

class PaymentApi {
  /**
   * Get Stripe Customer (will query our back which will query stripe)
   */

  static getCustomer(LicenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/customer/${LicenseeId}`).then((response) => response.data);
  }

  static saveCustomer(LicenseeId: string, customer: Partial<CustomerType>): Promise<any> {
    return axios.post(`/api/licensee/customer/${LicenseeId}`, { customer }).then((response) => response.data);
  }

  /**
   * Save Stripe Card (will query our back which will query stripe)
   */
  static saveCard(sourceId: string, licenseeId: string): Promise<any> {
    return axios
      .post('/api/billing/card/add', {
        sourceId,
        licenseeId,
      })
      .then((response) => response.data);
  }

  /**
   * Delete Stripe Card (will query our back which will query stripe)
   */
  static deleteSource(customerId: string, sourceId: string): Promise<any> {
    return axios
      .post('/api/billing/card/delete', {
        customerId,
        sourceId,
      })
      .then((response) => response.data);
  }

  /**
   * Make source as default source for customer (will query our back which will query stripe)
   */
  static makeSourceDefault(customerId: string, sourceId: string): Promise<any> {
    return axios
      .post('/api/billing/card/default', {
        customerId,
        sourceId,
      })
      .then((response) => response.data);
  }

  /**
   * Activate or Desactivate subscription for customer
   * Must see how it will be used 'in fine'
   */

  static subscribe(licenseeId: string): Promise<any> {
    return axios
      .post(`/api/licensee/customer/${licenseeId}/subscribe`, {
        licenseeId,
        action: 'subscribe',
      })
      .then((response) => response.data);
  }

  static unsubscribe(licenseeId: string): Promise<any> {
    return axios
      .post(`/api/licensee/customer/${licenseeId}/subscribe`, {
        licenseeId,
        action: 'unsubscribe',
      })
      .then((response) => response.data);
  }

  /**
   * VTA Stuff
   */
  static getVTARates(): Promise<any> {
    return axios.get('/api/billing/vta/rates').then((response) => response.data);
  }
}

export default PaymentApi;
