import { GET_LICENSEE_CUSTOMER_RESPONSE } from 'reducer/selectedLicenseeReducer';
import { GET_VTA_RATES_RESPONSE } from 'reducer/stripeReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import PaymentApi from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerType } from 'types';
import { getLicenseeAction } from './LicenseeAction';
/**
 * Get Licensee Stripe Customer (unique)
 */

export const getLicenseeCustomerAction = createAsyncThunk('payment/getCustomer', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PaymentApi.getCustomer(licenseeId).then((customer) => {
    dispatch(GET_LICENSEE_CUSTOMER_RESPONSE(customer));
    dispatch(API_CALL_END());
  });
});

interface SaveCustomerParam {
  licenseeId: string;
  customer: Partial<CustomerType>;
}

export const saveLicenseeCustomerAction = createAsyncThunk(
  'payment/saveCustomer',
  ({ licenseeId, customer }: SaveCustomerParam, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return PaymentApi.saveCustomer(licenseeId, customer)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getLicenseeCustomerAction(licenseeId));
      });
  }
);

/**
 * Dispatch card token to backend for creating customer
 */
interface SaveCardParam {
  sourceId: string;
  licenseeId: string;
}

export const saveCardAction = createAsyncThunk(
  'payment/saveCard',
  ({ sourceId, licenseeId }: SaveCardParam, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return PaymentApi.saveCard(sourceId, licenseeId)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getLicenseeCustomerAction(licenseeId));
      });
  }
);

/**
 * Delete Card for a customer
 */

interface SourceParam {
  customerId: string;
  sourceId: string;
}

export const deleteSourceAction = createAsyncThunk(
  'payment/deleteSource',
  ({ customerId, sourceId }: SourceParam, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return PaymentApi.deleteSource(customerId, sourceId).then(() => {
      dispatch(API_CALL_END());
    });
  }
);

/**
 * Make card as default for a customer
 */
export const makeSourceDefaultAction = createAsyncThunk(
  'payment/makeSourceDefault',
  ({ customerId, sourceId }: SourceParam, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return PaymentApi.makeSourceDefault(customerId, sourceId).then(() => {
      dispatch(API_CALL_END());
    });
  }
);

/**
 * Activate/Desactivate subscription
 */

export const subscribeAction = createAsyncThunk('payment/subscribe', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PaymentApi.subscribe(licenseeId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getLicenseeAction(licenseeId));
      dispatch(getLicenseeCustomerAction(licenseeId));
    });
});

export const unsubscribeAction = createAsyncThunk('payment/unsubscribe', (licenseeId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PaymentApi.unsubscribe(licenseeId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getLicenseeAction(licenseeId));
      dispatch(getLicenseeCustomerAction(licenseeId));
    });
});

/**
 * VTA Rates
 */

export const getVTARatesAction = createAsyncThunk('payment/getRates', (_useless, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PaymentApi.getVTARates().then((rates) => {
    dispatch(GET_VTA_RATES_RESPONSE(rates));
    dispatch(API_CALL_END());
  });
});
