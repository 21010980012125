import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import * as authAction from 'action/AuthAction';
import RegisterForm from '../forms/RegisterForm';

const RegisterUserPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { email } = useParams<{ email: string }>();

  const handleSubmit = useCallback(
    async (values) => {
      const user = {
        _id: values._id,
        firstName: values.firstName,
        lastName: values.lastName,
        primaryEmail: values.email,
        password: values.password,
        staff: values.staff,
      };

      await dispatch(authAction.registerUserAction(user));
      toastr.success(
        '',
        intl.formatMessage({ defaultMessage: 'Registered Sucessfully', id: 'action.register.message' })
      );
      // FIXME Flow -- Need to confirm Email
      history.push('/login');
    },
    [dispatch, history, intl]
  );

  return <RegisterForm handleSave={handleSubmit} initialValues={{ email }} />;
};

export default RegisterUserPage;
