import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// theme
import Card from 'theme/Card/Card';
import CardHeader from 'theme/Card/CardHeader';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import PasswordRule from '../common/PasswordRule';
import { ButtonClear, ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  email: {
    id: 'register.form.Email',
    description: 'register Email',
    defaultMessage: 'Email',
  },
  firstname: {
    id: 'register.form.firstname',
    description: 'register First Name',
    defaultMessage: 'First Name',
  },
  lastname: {
    id: 'register.form.lastname',
    description: 'register Last Name',
    defaultMessage: 'Last Name',
  },
  password: {
    id: 'register.form.password',
    description: 'register form password',
    defaultMessage: 'Password',
  },
  confirmpassword: {
    id: 'register.form.confirmpassword',
    description: 'register form password',
    defaultMessage: 'Confirm password',
  },
});

const errors = defineMessages({
  passwordrule: {
    defaultMessage:
      'Passwords must be at least 8 characters long with at least 1 number, 1 special and 1 capital letter',
    id: 'form.register.error.passwordrule',
  },
  passwordmatch: {
    defaultMessage: 'both password do not match',
    id: 'form.register.error.passwordmatch',
  },
});

export interface RegisterFormValues {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmpassword: string;
}

export interface RegisterFormProps {
  handleSave: (values: RegisterFormValues) => void;
  initialValues?: Partial<RegisterFormValues>;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ handleSave, initialValues }) => {
  const methods = useForm<RegisterFormValues>({ mode: 'onBlur', defaultValues: initialValues });
  const intl = useIntl();
  const { handleSubmit, formState, reset, watch } = methods;
  const { isDirty, isSubmitting } = formState;
  const password = useRef({});
  password.current = watch('password', '');
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardHeader color="primary">
            <h1>
              <FormattedMessage defaultMessage="Register" id="form.register.title" />
            </h1>
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <TextFieldInput
                label={intl.formatMessage(labels.email)}
                name="email"
                placeholder={intl.formatMessage(labels.email)}
                type="email"
                fullWidth
              />
            </FormGroup>
            <FormGroup row>
              <TextFieldInput
                label={intl.formatMessage(labels.firstname)}
                name="firstName"
                placeholder={intl.formatMessage(labels.firstname)}
                type="text"
                fullWidth
              />
              <TextFieldInput
                label={intl.formatMessage(labels.lastname)}
                name="lastName"
                placeholder={intl.formatMessage(labels.lastname)}
                type="text"
                fullWidth
              />
            </FormGroup>
            <FormGroup row>
              <TextFieldInput
                label={intl.formatMessage(labels.password)}
                name="password"
                type="password"
                fullWidth
                validators={{
                  validate: (value) => PasswordRule(value) || intl.formatMessage(errors.passwordrule),
                }}
              />
              <TextFieldInput
                label={intl.formatMessage(labels.confirmpassword)}
                name="confirmPassword"
                type="password"
                fullWidth
                validators={{
                  validate: (value) => value === password.current || intl.formatMessage(errors.passwordmatch),
                }}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonClear disabled={!isDirty} onClick={reset} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
