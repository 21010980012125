import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
// Material Ui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import * as ProductAction from 'action/ProductAction';
import { RootState } from 'reducer/configureStore';
import ProductsList from '../../lists/ProductList';
import { ButtonNew } from '../../common/Buttons';
import EditPlan from '../../Controllers/AddOrEditProductContainer';

const messages = defineMessages({
  title: {
    defaultMessage: 'Products',
    id: 'Products.list.title',
  },
});

const ProductsListPage: React.FC = () => {
  const [editionState, OpenCloseModal] = useState<{ product: string | undefined; openModal: boolean }>({
    product: undefined,
    openModal: false,
  });
  const products = useSelector((state: RootState) => state.productReducer.products);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(ProductAction.getProductsAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  const handleAddProduct = (): void => {
    OpenCloseModal({ product: undefined, openModal: true });
  };
  const handleRowSelect = (productId: string): void => {
    OpenCloseModal({ product: productId, openModal: true });
  };
  const resetModal = (): void => {
    OpenCloseModal({ product: undefined, openModal: false });
  };

  if (products) {
    return (
      <>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem md={6}>
                <h1>{intl.formatMessage(messages.title)}</h1>
              </GridItem>
              <GridItem md={2}>
                <ButtonNew onClick={handleAddProduct} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <ProductsList handleRowSelect={handleRowSelect} Products={products} />
          </CardBody>
        </Card>
        <Dialog
          maxWidth="lg"
          onClose={() => OpenCloseModal({ product: undefined, openModal: false })}
          open={!!editionState.openModal}
        >
          <DialogTitle>
            {editionState.product ? (
              <FormattedMessage defaultMessage="Edit product" id="edit.product" />
            ) : (
              <FormattedMessage defaultMessage="Create a new product" id="create.product" />
            )}
          </DialogTitle>
          <DialogContent>
            <EditPlan productId={editionState.product} doneAction={resetModal} />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return null;
};

export default ProductsListPage;
