import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { getPlanAction, savePlanAction } from 'action/PlanAction';
import { RootState } from 'reducer/configureStore';
import PlanForm from '../forms/PlanForm';

interface Props {
  planId?: string;
  doneAction: () => void;
}

const AddOrEditPlanContainer: React.FC<Props> = ({ planId, doneAction }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const plan = useSelector((state: RootState) => state.selectedPlanReducer.plan);

  const fetchPlan = useCallback(async () => {
    dispatch(getPlanAction(planId));
    setLoading(false);
  }, [dispatch, planId]);

  useEffect(() => {
    fetchPlan();
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, fetchPlan, planId]);

  const handleSave = useCallback(
    async (values) => {
      const frequency = values.frequency.split(' ');
      const planToSave = {
        _id: planId,
        name: values.name,
        royaltyFee: values.royaltyFee,
        royaltyCurrency: values.royaltyCurrency,
        interval_count: frequency[0],
        interval: frequency[1],
      };

      dispatch(savePlanAction(planToSave));
      toastr.success('', intl.formatMessage({ defaultMessage: 'Plan Saved', id: 'plan.save.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, planId]
  );
  if (loading) {
    return null;
  }
  return <PlanForm handleCancel={doneAction} handleSave={handleSave} initialValues={plan} isNew={!plan} />;
};

AddOrEditPlanContainer.defaultProps = {
  planId: undefined,
};

export default AddOrEditPlanContainer;
