import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from 'assets/jss/material-kit-react';
import makeStyles from '@mui/styles/makeStyles';

const infoStyle = makeStyles({
  infoArea: {
    maxWidth: '360px',
    margin: '0 auto',
    padding: '0px',
  },
  iconWrapper: {
    float: 'left',
    marginTop: '24px',
    marginRight: '10px',
  },
  primary: {
    background: primaryColor,
  },
  warning: {
    background: warningColor,
  },
  danger: {
    background: dangerColor,
  },
  success: {
    background: successColor,
  },
  info: {
    background: infoColor,
  },
  rose: {
    background: roseColor,
  },
  gray: {
    background: grayColor,
  },
  white: {
    background: '#fffff',
  },
  inherit: {
    background: 'inherit',
  },
  icon: {
    width: '36px',
    height: '36px',
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: 'hidden',
  },
  title,
  description: {
    color: primaryColor,
    overflow: 'hidden',
    marginTop: '0px',
    fontSize: '14px',
  },
  iconWrapperVertical: {
    float: 'none',
  },
  iconVertical: {
    width: '61px',
    height: '61px',
  },
});

export default infoStyle;
