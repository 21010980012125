import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// Icon
import VpnKey from '@mui/icons-material/VpnKey';
// ## //
import { getKeysStatsAction } from 'action/StatisticsAction';
import { RootState } from 'reducer/configureStore';
import useStyles from './styles/KeysStyles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Total Active Keys',
    id: 'dashboard.keys.title',
  },
});

interface Props {
  className?: string;
}

const Keys: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const keysStats = useSelector((state: RootState) => state.statisticsReducer.keys);

  useEffect(() => {
    dispatch(getKeysStatsAction());
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch]);

  if (keysStats) {
    const { countActive } = keysStats;
    return (
      <Card className={clsx(classes.root, className)}>
        <CardActionArea component={RouterLink} to="/admin/licencekeys">
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
                  {intl.formatMessage(messages.title)}
                </Typography>
                <Typography variant="h3">{countActive}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <VpnKey className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <CircularProgress />
      </CardContent>
    </Card>
  );
};

Keys.defaultProps = {
  className: 'DasboardLicensees',
};

export default Keys;
