import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// MaterialUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import * as planAction from 'action/PlanAction';
import { RootState } from 'reducer/configureStore';
import PlanList from '../../lists/PlanList';
import EditPlan from '../../Controllers/AddOrEditPlanContainer';
import { ButtonNew } from '../../common/Buttons';

const PlanListPage: React.FC = () => {
  const [editionState, OpenCloseModal] = useState<{
    plan: string | undefined;
    openModal: boolean;
  }>({
    plan: undefined,
    openModal: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(planAction.getPlansAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);
  const plans = useSelector((state: RootState) => state.plansReducer.plans);

  const handleAddPlan = (): void => {
    OpenCloseModal({ plan: undefined, openModal: true });
  };
  const handleRowSelect = (planId: string): void => {
    OpenCloseModal({ plan: planId, openModal: true });
  };
  const resetModal = (): void => {
    OpenCloseModal({ plan: undefined, openModal: false });
  };
  if (!plans) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem md={9}>
              <h1>
                <FormattedMessage defaultMessage="Plans" description="Plans Title" id="plans.title" />
              </h1>
            </GridItem>
            <GridItem md={2}>
              <ButtonNew onClick={handleAddPlan} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <PlanList handleRowSelect={handleRowSelect} plans={plans} />
        </CardBody>
      </Card>
      <Dialog
        maxWidth="lg"
        onClose={() => OpenCloseModal({ plan: undefined, openModal: false })}
        open={!!editionState.openModal}
      >
        <DialogTitle>
          {editionState.plan ? (
            <FormattedMessage defaultMessage="Edit plan" id="edit.plan" />
          ) : (
            <FormattedMessage defaultMessage="Create a new plan" id="create.plan" />
          )}
        </DialogTitle>
        <DialogContent>
          <EditPlan planId={editionState.plan} doneAction={resetModal} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlanListPage;
