import Prismic from 'prismic-javascript';
import { LocaleType } from 'types';

export const apiEndpoint = 'https://portal-incarna.cdn.prismic.io/api/v2';
export const accessToken =
  'MC5YaHhCenhVQUFDUUFLcW0z.77-9UO-_vXDvv73vv73vv73vv71iFO-_vSxw77-977-9Je-_vRFY77-977-9Ue-_ve-_vVIz77-9Fe-_vU_vv71o';

const client = Prismic.client(apiEndpoint, { accessToken });

export const mapLocale = (locale: LocaleType): string => {
  switch (locale) {
    case 'en':
      return 'en-us';
    case 'fr':
      return 'fr-fr';
    default:
      return 'fr-fr';
  }
};

class PrismicApi {
  static getFaq(locale: LocaleType): Promise<any> {
    return client
      .query(Prismic.Predicates.at('document.type', 'faq_entry'), { lang: mapLocale(locale) })
      .then((data) => data.results);
  }

  static getReleaseNotes(): Promise<any> {
    return client
      .query(
        Prismic.Predicates.at('document.type', 'release_note'),
        { orderings: '[my.release_note.date desc]' }
        //      { lang: mapLocale(locale) },
      )
      .then((data) => data.results);
  }
}

export default PrismicApi;
