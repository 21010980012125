import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// ## //
import { getGamesAction } from 'action/LicenceKeyAction';
import { RootState } from 'reducer/configureStore';
import GamesList from '../lists/GamesList';

interface Props {
  licenceKeyId: string;
}

const LicenceKeyGamesContainer: React.FC<Props> = ({ licenceKeyId }) => {
  const games = useSelector((state: RootState) => state.selectedLicenceKeyReducer.games);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesAction(licenceKeyId));
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, licenceKeyId]);

  if (games && games.length) {
    return <GamesList games={games} />;
  }

  return null;
};

export default LicenceKeyGamesContainer;
