import { GET_LICENCE_KEYS_RESPONSE } from 'reducer/licencekeysReducer';
import {
  GET_LICENCE_KEY_RESPONSE,
  GET_LICENCE_KEY_GAMES_RESPONSE,
  RESET_LICENCEKEY_RESPONSE,
} from 'reducer/selectedLicenceKeyReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import LicenceKeyApi, { SavedLicenceKey } from 'api/LicenceKeyApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Get LicenceKeys (list)
 */

export const getLicenceKeysAction = createAsyncThunk('licencekey/getAll', (_param, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenceKeyApi.getLicenceKeys().then((keys) => {
    dispatch(GET_LICENCE_KEYS_RESPONSE(keys));
    dispatch(API_CALL_END());
  });
});

/**
 * Get LicenceKey(unique)
 */

export const getLicenceKeyAction = createAsyncThunk('licencekey/getOne', async (licenceKey: string, { dispatch }) => {
  if (!licenceKey) {
    dispatch(RESET_LICENCEKEY_RESPONSE());
  } else {
    dispatch(API_CALL_BEGIN());

    const key = await LicenceKeyApi.getLicenceKey(licenceKey);
    dispatch(GET_LICENCE_KEY_RESPONSE(key));
    dispatch(API_CALL_END());
  }
});

/**
 * Get Games for a licence Key (unique)
 */

export const getGamesAction = createAsyncThunk('licencekey/getGames', (licenceKeyId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenceKeyApi.getGames(licenceKeyId).then((games) => {
    dispatch(GET_LICENCE_KEY_GAMES_RESPONSE(games));
    dispatch(API_CALL_END());
  });
});

/*
 * Edit Licence Key
 */

interface SaveLicenceKeyActionParams {
  licenceKey: SavedLicenceKey;
  amount?: number;
}

export const saveLicenceKeyAction = createAsyncThunk(
  'licencekey/save',
  ({ licenceKey, amount }: SaveLicenceKeyActionParams, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return LicenceKeyApi.saveLicenceKey(licenceKey, amount).then(() => {
      dispatch(API_CALL_END());
    });
  }
);

/**
 * Enable License Keys
 */

export const enableLicenceKeysAction = createAsyncThunk('licencekey/enable', (ids: string[], { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenceKeyApi.enableLicenceKeys(ids).then(() => {
    dispatch(API_CALL_END());
  });
});

/**
 * Disable License Keys
 */

export const disableLicenceKeysAction = createAsyncThunk('licencekey/disable', (ids: string[], { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenceKeyApi.disableLicenceKeys(ids).then(() => {
    dispatch(API_CALL_END());
  });
});

/**
 * Unlink License Keys
 */

export const unlinkLicenceKeysAction = createAsyncThunk('licencekey/unlink', (ids: string[], { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return LicenceKeyApi.unlinkLicenceKeys(ids).then(() => {
    dispatch(API_CALL_END());
  });
});
