import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
// Theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import Select from 'theme/CustomInput/CustomSelect';
// ## //
import { PlanType } from 'types';
import { ButtonCancel, ButtonSubmit, ButtonClear } from '../common/Buttons';
import { frequencysForPlansDropdown } from '../../selectors/selectors';

const labels = defineMessages({
  name: {
    id: 'plan.form.name',
    description: 'plan name',
    defaultMessage: 'Name',
  },
  royaltyfee: {
    id: 'plan.form.royaltyfee',
    description: 'plan royalty fee',
    defaultMessage: 'Fee per player',
  },
  royaltyCurrency: {
    id: 'plan.form.royaltyCurrency',
    description: 'plan royalty currency',
    defaultMessage: 'Currency of the fee',
  },
  frequency: {
    id: 'plan.form.frequency',
    description: 'plan frequency',
    defaultMessage: 'Invoice frequency',
  },
  warning: {
    defaultMessage: 'The following fields are not editable as stripe disallow changes once created',
    id: 'plan.form.warningStripe',
  },
});

interface Props {
  handleSave: (plan: PlanType) => void;
  handleCancel: () => void;
  isNew: boolean;
  initialValues?: Partial<PlanType>;
}

const PlanForm: React.FC<Props> = ({ isNew, handleSave, handleCancel, initialValues = {} }) => {
  const frequencies = frequencysForPlansDropdown();
  const methods = useForm({
    defaultValues: {
      name: initialValues.name,
      royaltyFee: initialValues.royaltyFee,
      royaltyCurrency: initialValues.royaltyCurrency,
      frequency: !isNew ? `${initialValues.interval_count} ${initialValues.interval}` : '',
    },
    mode: 'onBlur',
  });
  const intl = useIntl();
  const { handleSubmit, formState, reset } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // FIXME: ADD CURRENCY VALIDATION + NUMBER VALIDATION
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup>
              <TextFieldInput
                label={intl.formatMessage(labels.name)}
                name="name"
                placeholder={intl.formatMessage(labels.name)}
                type="text"
              />
            </FormGroup>
            <FormGroup>
              {!isNew && <Chip variant="outlined" color="secondary" label={intl.formatMessage(labels.warning)} />}
              <TextFieldInput
                label={intl.formatMessage(labels.royaltyfee)}
                name="royaltyFee"
                placeholder="5"
                readOnly={!isNew}
                type="text"
              />
              <TextFieldInput
                label={intl.formatMessage(labels.royaltyCurrency)}
                name="royaltyCurrency"
                placeholder="EUR"
                readOnly={!isNew}
                type="text"
              />
              <Select
                label={intl.formatMessage(labels.frequency)}
                name="frequency"
                readOnly={!isNew}
                options={frequencies}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonClear disabled={!isDirty} onClick={() => reset(initialValues)} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

// TODO: Add async validator for uniq fields (like name)

PlanForm.defaultProps = {
  initialValues: {},
};

export default PlanForm;
