import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import {
  LOGIN_USER_RESPONSE,
  LOGOUT_USER_RESPONSE,
  API_CALL_SESSION_START,
  API_CALL_SESSION_END,
  CHECK_SESSION_RESPONSE,
  SET_REDIRECT_URL_RESPONSE,
} from 'reducer/authenticationReducer';
import UserApi from 'api/UserApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginParam } from 'types';
import updateLocale from './updateLocale';

export const loginUserAction = createAsyncThunk('auth/login', (values: LoginParam, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.loginUser(values)
    .then((data) => {
      if (data.user && data.user.locale) {
        dispatch(updateLocale(data.user.locale));
      }
      dispatch(LOGIN_USER_RESPONSE(data.user));
      dispatch(API_CALL_END());
      return data.user;
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        return Promise.reject(err.response.data.error);
      }
      return Promise.reject({ code: 'GENERIC_ERR', message: 'Generic Error' });
    });
});

export const registerUserAction = createAsyncThunk('auth/register', (user: any, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.registerUser(user).then(() => {
    dispatch(API_CALL_END());
  });
});

export const checkSessionAction = createAsyncThunk('auth/checkSession', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());
  dispatch(API_CALL_SESSION_START());

  return UserApi.checkSession()
    .then((data) => {
      if (data.user && data.user.locale) {
        dispatch(updateLocale(data.user.locale));
      }
      dispatch(CHECK_SESSION_RESPONSE({ user: data.user, loggedIn: data.loggedIn }));
      dispatch(API_CALL_SESSION_END());
      dispatch(API_CALL_END());
    })
    .catch((error) => {
      dispatch(API_CALL_SESSION_END());
      throw error;
    });
});

export const logoutUserAction = createAsyncThunk('auth/logout', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return UserApi.logoutUser().then(() => {
    dispatch(LOGOUT_USER_RESPONSE());
    dispatch(API_CALL_END());
  });
});

export const setRedirectUrl = createAsyncThunk('auth/setRedirectUrl', (url: string, { dispatch }) => {
  dispatch(SET_REDIRECT_URL_RESPONSE(url));
  // dispatch(API_CALL_END());
});
