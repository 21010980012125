import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import App from './App';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import * as serviceWorker from './serviceWorker';

// import {render} from 'react-dom';
import store from './reducer/configureStore';
import App from './App';

// i18n
// import { addLocaleData } from 'react-intl';
// import en from 'react-intl/locale-data/en';
// import fr from 'react-intl/locale-data/fr';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import 'react-credit-cards/es/styles-compiled.css';

// addLocaleData([...en, ...fr]);

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="fr">
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
