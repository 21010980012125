import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
// ## //
import * as licenseeAction from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';
import LicenseeInvoices from '../../lists/InvoicesList';

const messages = defineMessages({
  title: {
    defaultMessage: 'Licensee Invoices',
    id: 'licenseeinvoices.list.title',
  },
});

const InvoicesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const intl = useIntl();
  // Global states
  const invoices = useSelector((state: RootState) => state.selectedLicenseeReducer.invoices);
  const provisionalInvoice = useSelector((state: RootState) => state.selectedLicenseeReducer.provisionalInvoice);

  useEffect(() => {
    dispatch(licenseeAction.getLicenseeInvoicesAction(id));
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, id]);

  // FIXME: Handle 'show more' of invoices stripe component
  if (invoices || provisionalInvoice) {
    return (
      <Card>
        <CardHeader color="primary">
          <h1>{intl.formatMessage(messages.title)}</h1>
        </CardHeader>
        <CardBody>
          <LicenseeInvoices invoices={invoices.data} provisionalInvoice={provisionalInvoice} />
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default InvoicesPage;
