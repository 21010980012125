import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
import Select from 'theme/CustomInput/CustomSelect';
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import { ButtonCancel, ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  language: {
    id: 'licensee.form.language',
    defaultMessage: 'Language',
  },
  tax_exempt: {
    id: 'licensee.form.tax_exempt',
    defaultMessage: 'Tax Exemption status',
  },
  name: {
    id: 'licensee.form.name',
    defaultMessage: 'Name (from licensee)',
  },
  description: {
    id: 'licensee.form.description',
    defaultMessage: 'Description (from licensee)',
  },
  email: {
    id: 'licensee.form.email',
    defaultMessage: 'Email',
  },
  line1: {
    id: 'licensee.form.line1',
    defaultMessage: 'Adresse',
  },
  postal_code: {
    id: 'licensee.form.postal_code',
    defaultMessage: 'Code Postal',
  },
  city: {
    id: 'licensee.form.city',
    defaultMessage: 'Ville',
  },
  footer: {
    id: 'licensee.form.footer',
    defaultMessage: 'Invoice Footer',
  },
});

const languages = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' },
  { value: 'ar', label: 'Arabic' },
  { value: 'da', label: 'Danish' },
  { value: 'es', label: 'Spanish' },
  { value: 'fi', label: 'Finnish' },
  { value: 'he', label: 'Hebrew' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'nl', label: 'Dutch' },
  { value: 'nb', label: 'Norwegian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'sv', label: 'Swedish' },
  { value: 'fr', label: 'Francais' },
];

const taxExemptions = [
  { value: 'none', label: 'none' },
  { value: 'exempt', label: 'exempt' },
  { value: 'reverse', label: 'reverse' },
];

export const footers = [
  {
    value:
      'Autoliquidation (Exonération de TVA, article 283 du CGI) Incarna S.A.S. ' +
      'au capital de 13.600 euros; 15 Rue des Halles, 75001 Paris; RCS : PARIS 818 642 902; TVA : FR74 818642902',
    label: 'UE Client',
  },
  {
    value:
      'TVA non applicable - art. 259-1 du CGI Incarna S.A.S. au capital de 13.600' +
      ' euros; 15 Rue des Halles, 75001 Paris; RCS : PARIS 818 642 902; TVA : FR74 818642902',
    label: 'Hors UE Client',
  },
  {
    value:
      'Incarna S.A.S. au capital de 13.600 euros; 15 Rue des Halles, 75001 Paris;' +
      ' RCS : PARIS 818 642 902; TVA : FR74 818642902',
    label: 'Client Français',
  },
];

export interface CustomerFormValues {
  name: string;
  language: string;
  description: string;
  tax_exempt: string;
  email: string;
  line1: string;
  city: string;
  postal_code: string;
  footer: string;
}
interface Props {
  handleSave: (values: CustomerFormValues) => void;
  handleCancel: () => void;
  initialValues?: Partial<CustomerFormValues>;
}

const CustomerForm: React.FC<Props> = ({ handleSave, handleCancel, initialValues }) => {
  const methods = useForm<CustomerFormValues>({
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const intl = useIntl();
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup>
              <Select label={intl.formatMessage(labels.language)} name="language" options={languages} />
              <Select label={intl.formatMessage(labels.footer)} name="footer" options={footers} />
              <Select label={intl.formatMessage(labels.tax_exempt)} name="tax_exempt" options={taxExemptions} />
              <TextFieldInput label={intl.formatMessage(labels.name)} name="name" type="text" />
              <TextFieldInput label={intl.formatMessage(labels.description)} name="description" type="text" optional />
              <TextFieldInput label={intl.formatMessage(labels.email)} name="email" type="text" optional />
              <TextFieldInput label={intl.formatMessage(labels.line1)} name="line1" type="text" optional />
              <TextFieldInput label={intl.formatMessage(labels.postal_code)} name="postal_code" type="text" optional />
              <TextFieldInput label={intl.formatMessage(labels.city)} name="city" type="text" optional />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

CustomerForm.defaultProps = {
  initialValues: {
    footer: footers[2].value,
    language: 'fr',
    tax_exempt: 'none',
  },
};

export default CustomerForm;
