import React from 'react';
import { FormattedMessage } from 'react-intl';
// Theme
import Button from 'theme/CustomButtons/Button';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
  onClick: (event?: any) => void;
}

const ButtonCancel: React.FC<Props> = ({ onClick }) => (
  <Button customColor="white" round onClick={onClick}>
    <CancelIcon />
    <FormattedMessage defaultMessage="Cancel" description="Button Cancel" id="button.cancel" />
  </Button>
);

export default ButtonCancel;
