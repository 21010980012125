import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// Theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import { ButtonSubmit } from '../common/Buttons';

const labels = defineMessages({
  reason: {
    id: 'plan.form.reason',
    defaultMessage: 'Please provide reason',
  },
});

interface Props {
  handleSave: (values: any) => void;
  initialValues?: any;
}

const GameContestForm: React.FC<Props> = ({ handleSave, initialValues }) => {
  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const intl = useIntl();
  const { handleSubmit, formState } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <FormGroup>
          <TextFieldInput label={intl.formatMessage(labels.reason)} name="reason" placeholder="5" type="text" />
        </FormGroup>
        <ButtonSubmit disabled={isSubmitting || !isDirty} />
      </form>
    </FormProvider>
  );
};

GameContestForm.defaultProps = {
  initialValues: {},
};

export default GameContestForm;
