import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

// ## //
import useStyles from './styles/GridItemStyles';

type Props = GridProps & {
  children?: React.ReactNode;
  className?: string;
};

const GridItem: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid item {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
};

GridItem.defaultProps = {
  className: '',
  children: null,
};

export default GridItem;
