import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// MaterialUi
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// ## //
import { getLicenseeAction } from 'action/LicenseeAction';
import { getLicenseeCustomerAction } from 'action/PaymentAction';

import { RootState } from 'reducer/configureStore';
import LicenseeInfo from '../../Cards/LicenseeInfo';
import StripeCustomerInfo from '../../Cards/StripeCustomerInfo';
import DialogTitle from '../../common/DialogTitle';
import EditLicensee from '../../Controllers/AddOrEditLicenseeContainer';
import EditCustomer from '../../Controllers/EditCustomerContainer';

const ViewLicenseePage: React.FC = () => {
  const dispatch = useDispatch();
  // Global States
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const role = useSelector((state: RootState) => state.authenticationReducer.licenseePermission);
  const customer = useSelector((state: RootState) => state.selectedLicenseeReducer.customer);
  const rates = useSelector((state: RootState) => state.stripeReducer.rates);
  // Local states
  const [showEditModal, OpenCloseModal] = useState(false);
  const [showCustomerModal, OpenCloseCustomerModal] = useState(false);

  const fetchCustomer = useCallback(() => {
    if (licensee && (staff || role === 'owner')) {
      dispatch(getLicenseeCustomerAction(licensee._id));
    }
  }, [role, staff, licensee, dispatch]);

  useEffect(() => {
    fetchCustomer();
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, fetchCustomer, licensee, role, staff]);

  if (licensee) {
    return (
      <Container>
        <LicenseeInfo
          editAction={() => OpenCloseModal(true)}
          licensee={licensee}
          customerTax={customer?.tax_info ? customer.tax_info.tax_id : ''}
          staff={staff}
          role={role}
          rates={rates || []}
        />
        <br />
        {customer && staff && (
          <StripeCustomerInfo customer={customer} editAction={() => OpenCloseCustomerModal(true)} />
        )}
        <Dialog fullWidth maxWidth="xl" onClose={() => OpenCloseModal(false)} open={!!showEditModal}>
          <DialogTitle>
            <FormattedMessage defaultMessage="Edit Licensee" id="edit.licensee" />
          </DialogTitle>
          <DialogContent>
            <EditLicensee
              licensee={licensee}
              doneAction={() => {
                dispatch(getLicenseeAction(licensee._id));
                fetchCustomer();
                OpenCloseModal(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog maxWidth="lg" onClose={() => OpenCloseCustomerModal(false)} open={!!showCustomerModal}>
          <DialogTitle>
            <FormattedMessage defaultMessage="Edit Licensee" id="edit.licensee" />
          </DialogTitle>
          <DialogContent>
            {customer && staff && (
              <EditCustomer
                licenseeId={licensee._id}
                customer={customer}
                doneAction={() => {
                  fetchCustomer();
                  OpenCloseCustomerModal(false);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
  return null;
};

export default ViewLicenseePage;
