import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateIntl } from 'react-intl-redux';
// Our translation files
import localeFr from 'i18n/fr.json';
import localeEn from 'i18n/en.json';
import { LocaleType } from 'types';
import { setLocaleAction } from './UserAction';

const updateLocale = createAsyncThunk('locale/update', async (locale: LocaleType, { dispatch }) => {
  switch (locale) {
    case 'fr':
      dispatch(
        updateIntl({
          locale: 'fr',
          messages: localeFr,
        })
      );
      await dispatch(setLocaleAction('fr'));
      break;
    case 'en':
    default:
      dispatch(
        updateIntl({
          locale: 'en',
          messages: localeEn,
        })
      );
      await dispatch(setLocaleAction('en'));
  }
});

export default updateLocale;
