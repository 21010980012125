import React from 'react';
// Material Ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backdrop: {
      zIndex: theme.zIndex?.drawer + 1 || 1201,
      color: '#fff',
    },
  });
});

const CustomBackdrop: React.FC = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="secondary" size={80} />
    </Backdrop>
  );
};

export default CustomBackdrop;
