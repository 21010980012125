import { MUIDataTableTextLabels } from 'mui-datatables';
import { defineMessages, IntlShape } from 'react-intl';

const bodyMessages = defineMessages({
  // BODY
  noMatch: {
    id: 'datatables.body.nomatch',
    defaultMessage: 'Sorry, no matching records found',
  },
  toolTip: {
    id: 'datatables.body.sort',
    defaultMessage: 'Sort',
  },
  columnHeaderTooltip: {
    id: 'datatables.body.columnHeaderTooltip',
    defaultMessage: 'Sort for {label}',
  },
});
// PAGINATION
const paginationMessages = defineMessages({
  next: {
    id: 'datatables.pagination.next',
    defaultMessage: 'Next Page',
  },
  previous: {
    id: 'datatables.pagination.previous',
    defaultMessage: 'Previous Page',
  },
  rowsPerPage: {
    id: 'datatables.pagination.rowsPerPage',
    defaultMessage: 'Rows per page:',
  },
  displayRows: {
    id: 'datatables.pagination.displayRows',
    defaultMessage: 'of',
  },
});
// TOOLBAR
const toolbarMessages = defineMessages({
  search: {
    id: 'datatables.toolbar.search',
    defaultMessage: 'Search',
  },
  downloadCsv: {
    id: 'datatables.toolbar.downloadCsv',
    defaultMessage: 'Download CSV',
  },
  print: {
    id: 'datatables.toolbar.print',
    defaultMessage: 'Print',
  },
  viewColumns: {
    id: 'datatables.toolbar.viewColumns',
    defaultMessage: 'View Columns',
  },
  filterTable: {
    id: 'datatables.toolbar.filterTable',
    defaultMessage: 'Filter Table',
  },
});
// FILTER
const filterMessages = defineMessages({
  all: {
    id: 'datatables.filter.all',
    defaultMessage: 'All',
  },
  title: {
    id: 'datatables.filter.title',
    defaultMessage: 'FILTERS',
  },
  reset: {
    id: 'datatables.filter.reset',
    defaultMessage: 'RESET',
  },
});
// VIEW COLUMNS
const viewColumnsMessages = defineMessages({
  title: {
    id: 'datatables.viewColumns.title',
    defaultMessage: 'Show Columns',
  },
  titleAria: {
    id: 'datatables.viewColumns.titleAria',
    defaultMessage: 'Show/Hide Table Columns',
  },
});
// SELECTED ROWS
const selectedRowsMessages = defineMessages({
  text: {
    id: 'datatables.selectedRows.text',
    defaultMessage: 'row(s) selected',
  },
  delete: {
    id: 'datatables.selectedRows.delete',
    defaultMessage: 'Delete',
  },
  deleteAria: {
    id: 'datatables.selectedRows.deleteAria',
    defaultMessage: 'Delete Selected Rows',
  },
});

const textLabels = (intl: IntlShape): MUIDataTableTextLabels => ({
  body: {
    noMatch: intl.formatMessage(bodyMessages.noMatch),
    toolTip: intl.formatMessage(bodyMessages.toolTip),
    columnHeaderTooltip: (column) => intl.formatMessage(bodyMessages.columnHeaderTooltip, { label: column.label }),
  },
  pagination: {
    next: intl.formatMessage(paginationMessages.next),
    previous: intl.formatMessage(paginationMessages.previous),
    rowsPerPage: intl.formatMessage(paginationMessages.rowsPerPage),
    displayRows: intl.formatMessage(paginationMessages.displayRows),
  },
  toolbar: {
    search: intl.formatMessage(toolbarMessages.search),
    downloadCsv: intl.formatMessage(toolbarMessages.downloadCsv),
    print: intl.formatMessage(toolbarMessages.print),
    viewColumns: intl.formatMessage(toolbarMessages.viewColumns),
    filterTable: intl.formatMessage(toolbarMessages.filterTable),
  },
  filter: {
    all: intl.formatMessage(filterMessages.all),
    title: intl.formatMessage(filterMessages.title),
    reset: intl.formatMessage(filterMessages.reset),
  },
  viewColumns: {
    title: intl.formatMessage(viewColumnsMessages.title),
    titleAria: intl.formatMessage(viewColumnsMessages.titleAria),
  },
  selectedRows: {
    text: intl.formatMessage(selectedRowsMessages.text),
    delete: intl.formatMessage(selectedRowsMessages.delete),
    deleteAria: intl.formatMessage(selectedRowsMessages.deleteAria),
  },
});

export default textLabels;
