import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      fontWeight: 700,
    },
    avatar: {
      backgroundColor: theme.palette.error.main,
      height: 56,
      width: 56,
    },
    icon: {
      height: 32,
      width: 32,
    },
    difference: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    koIcon: {
      color: theme.palette.error.dark,
    },
    okIcon: {
      color: theme.palette.success.dark,
    },
    koValue: {
      color: theme.palette.error.dark,
      marginRight: theme.spacing(1),
    },
    okValue: {
      color: theme.palette.success.dark,
      marginRight: theme.spacing(1),
    },
  })
);
