import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI || 'http://localhost:8000';

axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': window.location.origin,
};
axios.defaults.withCredentials = true;

export default axios;
