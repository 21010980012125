import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material Ui
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import * as licenseeAction from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';
import LicenseeList from '../lists/LicenseeList';
import { ButtonNew } from '../common/Buttons';
import DialogTitle from '../common/DialogTitle';
import AddLicensee from '../Controllers/AddOrEditLicenseeContainer';

const LicenseeListPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Global States
  const licensees = useSelector((state: RootState) => state.licenseesReducer.licensees);
  const currentUser = useSelector((state: RootState) => state.authenticationReducer.user);
  // Local States
  const [showEditModal, OpenCloseModal] = useState(false);

  useEffect(() => {
    dispatch(licenseeAction.getLicenseesAction());
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch]);

  // Callbacks
  const handleAddLicensee = (): void => OpenCloseModal(true);
  const handleEdit = useCallback(
    (licenseeId) => {
      history.push(`/licensee/${licenseeId}`);
    },
    [history]
  );

  return (
    <div className="container">
      <Card>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={4} md={9}>
              <h1>
                <FormattedMessage defaultMessage="Licensees" description="Licensees" id="licensees.title" />
              </h1>
            </GridItem>
            <GridItem md={2}>
              {currentUser && currentUser.staff && (
                <ButtonGroup data-testid="LicenseeListstaffActions" className="pull-right">
                  <ButtonNew onClick={handleAddLicensee} />
                </ButtonGroup>
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <LicenseeList staff={currentUser?.staff} handleEdit={handleEdit} licensees={licensees} />
        </CardBody>
      </Card>
      <Dialog maxWidth="lg" onClose={() => OpenCloseModal(false)} open={showEditModal}>
        <DialogTitle>
          <FormattedMessage defaultMessage="Add Licensee" id="add.licensee" />
        </DialogTitle>
        <DialogContent>
          <AddLicensee doneAction={() => OpenCloseModal(false)} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LicenseeListPage;
