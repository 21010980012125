import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
// ## //
import { RateType } from 'types';
import { rateFormatter } from '../datatables/Formatters';
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  display_name: {
    defaultMessage: 'Name',
    id: 'rates.display_name',
  },
  description: {
    defaultMessage: 'Description',
    id: 'rates.description',
  },
  jurisdiction: {
    defaultMessage: 'Jurisdiction',
    id: 'rates.jurisdiction',
  },
  percentage: {
    defaultMessage: 'rate',
    id: 'rates.percentage',
  },
});

interface Props {
  Rates: RateType[];
}

const RatesList: React.FC<Props> = ({ Rates }) => {
  const intl = useIntl();

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'display_name',
      label: intl.formatMessage(messages.display_name),
    },
    {
      name: 'description',
      label: intl.formatMessage(messages.description),
    },
    {
      name: 'jurisdiction',
      label: intl.formatMessage(messages.jurisdiction),
    },
    {
      name: 'percentage',
      label: intl.formatMessage(messages.percentage),
      options: {
        customBodyRender: rateFormatter,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    tableId: 'RatesList',
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    viewColumns: false,
    sortFilterList: false,
    search: false,
    print: false,
    pagination: false,
    filter: false,
    download: false,
  };

  return <MUIDataTable title="" data={Rates} options={options} columns={columns} />;
};

export default RatesList;
