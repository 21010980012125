import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cards from 'react-credit-cards';
// Material Ui
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
// ## //
import { SourceType } from 'types';
import { ButtonDelete } from '../common/Buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      margin: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
  })
);

interface Props {
  source: SourceType;
  deleteCard: (source: SourceType) => void;
  makeDefault: (source: SourceType) => void;
}

const CardInfo: React.FC<Props> = ({ source, makeDefault, deleteCard }) => {
  const { card } = source;
  const { owner } = source;
  const classes = useStyles();
  // TODO: Manage expired Cards

  return (
    <Paper className={classes.paper}>
      {source.default && (
        <Chip
          color="primary"
          icon={<DoneIcon />}
          variant="outlined"
          label={
            <FormattedMessage
              defaultMessage="Default Card"
              description="Default card label"
              id="cardInfo.defaultCard"
            />
          }
        />
      )}
      <Cards
        cvc="****"
        expiry={`${`0${card.exp_month}`.slice(-2)}/${card.exp_year}`}
        name={owner.name}
        number={`************${card.last4}`}
        preview
        issuer={card.brand}
      />
      <div>
        <ButtonDelete onClick={() => deleteCard(source)} />
        {!source.default && (
          <Button
            className={classes.action}
            data-testid="makeDefaultButton"
            variant="contained"
            color="secondary"
            onClick={() => makeDefault(source)}
          >
            <FormattedMessage defaultMessage="Make Default" description="make default button" id="button.makeDefault" />
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default CardInfo;
