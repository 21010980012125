import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// ## //
import { getLicenseeCustomerAction } from 'action/PaymentAction';
import LicenseeBilling from 'components/billing/LicenseeBilling';
import SubscriptionList from 'components/Cards/SubscriptionList';
import apiKey from 'api/stripeConfig';
import { RootState } from 'reducer/configureStore';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(apiKey);

const BillingPage: React.FC = () => {
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const customer = useSelector((state: RootState) => state.selectedLicenseeReducer.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (licensee) {
      dispatch(getLicenseeCustomerAction(licensee._id));
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, licensee]);

  if (licensee && customer) {
    return (
      <>
        <SubscriptionList />
        <Elements stripe={stripePromise}>
          <LicenseeBilling />
        </Elements>
      </>
    );
  }

  return null;
};

export default BillingPage;
