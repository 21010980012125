import React, { useEffect, useState } from 'react';
// Commons
import Backdrop from './Backdrop';

const Spinner: React.FC = () => {
  const [loading, setLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Show Spinner only if loading Time is above 60ms
      setLoader(true);
    }, 600);
    return () => clearTimeout(timer);
  });

  if (loading) {
    return <Backdrop />;
  }
  return null;
};

export default Spinner;
