import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FormattedMessage, useIntl } from 'react-intl';
// Material Ui
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// ## //
import { loginUserAction } from 'action/AuthAction';
import { LoginParam } from 'types';
import LoginForm from '../forms/LoginForm';
import ForgotPasswordContainer from '../Controllers/ForgotPasswordContainer';
import DialogTitle from '../common/DialogTitle';
import { useAppDispatch } from 'reducer/configureStore';

const LoginPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showPasswordModal, openPasswordModal] = useState(false);

  const handleLogin = async (values: LoginParam): Promise<void> => {
    const user = {
      username: values.username,
      password: values.password,
    };
    const res = await dispatch(loginUserAction(user));
    if (res.meta.requestStatus !== 'rejected') {
      toastr.success('', intl.formatMessage({ defaultMessage: 'Login Sucessfull', id: 'action.login.message' }));
      history.push('/');
    }
  };

  return (
    <Container>
      <LoginForm handleResetPassword={() => openPasswordModal(true)} handleSave={handleLogin} />
      <Dialog onClose={() => openPasswordModal(false)} open={showPasswordModal}>
        <DialogTitle onClose={() => openPasswordModal(false)}>
          <FormattedMessage
            defaultMessage="Please provide us with your email so we can look you up"
            id="modal.resetpassword.title"
          />
        </DialogTitle>
        <DialogContent>
          <ForgotPasswordContainer closeModal={() => openPasswordModal(false)} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default LoginPage;
