import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
// Material
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// Icons
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
// ## //
import { getLicenseesStatsAction } from 'action/StatisticsAction';
import { RootState } from 'reducer/configureStore';
import useStyles from './styles/LicenseesStyles';

const messages = defineMessages({
  title: {
    defaultMessage: 'Active Subscriptions',
    id: 'dashboard.Licensees.title',
  },
  total: {
    defaultMessage: 'Total Licensees',
    id: 'dashboard.Licensees.total',
  },
});

interface Props {
  className?: string;
}

const Licensees: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const LicenseeStats = useSelector((state: RootState) => state.statisticsReducer.licensees);

  useEffect(() => {
    dispatch(getLicenseesStatsAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  if (LicenseeStats) {
    const { countLicensees, countActive } = LicenseeStats;
    return (
      <Card className={clsx(classes.root, className)}>
        <CardActionArea component={RouterLink} to="/licensees">
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
                  {intl.formatMessage(messages.title)}
                </Typography>
                <Typography variant="h3">{countActive}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <PeopleIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <div className={classes.difference}>
              <Typography className={classes.Value} variant="body2">
                {countLicensees}
              </Typography>
              <Typography variant="caption">{intl.formatMessage(messages.total)}</Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <CircularProgress />
      </CardContent>
    </Card>
  );
};

Licensees.defaultProps = {
  className: 'DasboardLicensees',
};

export default Licensees;
