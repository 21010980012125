import React from 'react';
import { FormattedMessage } from 'react-intl';
// Theme
import Button from 'theme/CustomButtons/Button';
// Icons
import SendIcon from '@mui/icons-material/Send';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
}

const ButtonSubmit: React.FC<Props> = ({ onClick, disabled }) => (
  <Button customColor="success" round disabled={disabled} onClick={onClick} type="submit">
    <SendIcon />
    <FormattedMessage defaultMessage="Submit" description="Button Submit" id="button.submit" />
  </Button>
);

ButtonSubmit.defaultProps = {
  disabled: false,
  onClick: undefined,
};

export default ButtonSubmit;
