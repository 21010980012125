import { RESET_PLAN_RESPONSE, GET_PLAN_RESPONSE } from 'reducer/selectedPlanReducer';
import { GET_PLANS_RESPONSE } from 'reducer/plansReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import PlanApi from 'api/PlanApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlanType } from 'types';

/**
 *  Retrieve Plans list
 */

export const getPlansAction = createAsyncThunk('plan/getPlans', (_useless, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PlanApi.getAllPlans().then((plans) => {
    dispatch(GET_PLANS_RESPONSE(plans));
    dispatch(API_CALL_END());
  });
});

/**
 *  Save plan
 */

export const savePlanAction = createAsyncThunk(
  'plan/save',
  (planBeingAddedOrEdited: Partial<PlanType>, { dispatch }) => {
    dispatch(API_CALL_BEGIN());

    return PlanApi.savePlan(planBeingAddedOrEdited)
      .then(() => {
        dispatch(API_CALL_END());
      })
      .then(() => {
        dispatch(getPlansAction());
      });
  }
);

/**
 *  Get plan
 */

export const getPlanAction = createAsyncThunk('plan/getPlan', (planId: string | undefined, { dispatch }) => {
  if (!planId) {
    dispatch(RESET_PLAN_RESPONSE());
    return Promise.resolve();
  }
  dispatch(API_CALL_BEGIN());

  return PlanApi.getPlan(planId).then((plan) => {
    dispatch(GET_PLAN_RESPONSE(plan));
    dispatch(API_CALL_END());
  });
});

/**
 * Delete Plan
 */

export const deletePlanAction = createAsyncThunk('plan/delete', (planId: string, { dispatch }) => {
  dispatch(API_CALL_BEGIN());

  return PlanApi.deletePlan(planId)
    .then(() => {
      dispatch(API_CALL_END());
    })
    .then(() => {
      dispatch(getPlansAction());
    });
});
