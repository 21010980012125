import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
// Material Ui
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import { getUsersAction, enableUsers, disableUsers } from 'action/UserAction';
import { RootState } from 'reducer/configureStore';
import UserList from '../../lists/UserList';
import EditUser from '../../Controllers/AddOrEditUserContainer';
import { ButtonNew } from '../../common/Buttons';

const UserListPage: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  // Global states
  const users = useSelector((state: RootState) => state.usersReducer.users);
  // Local states
  const [editionState, OpenCloseModal] = useState({
    user: undefined,
    openModal: false,
  });

  useEffect(() => {
    dispatch(getUsersAction());
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch]);

  // Callbacks
  const handleAddUser = useCallback(() => {
    OpenCloseModal({ user: undefined, openModal: true });
  }, []);
  const handleRowSelect = useCallback((userId) => {
    OpenCloseModal({ user: userId, openModal: true });
  }, []);
  const resetModal = useCallback(() => {
    OpenCloseModal({ user: undefined, openModal: false });
  }, []);
  const enableUsersCallback = useCallback(
    async (ids) => {
      await dispatch(enableUsers(ids));
      await dispatch(getUsersAction());
      toastr.success('', intl.formatMessage({ defaultMessage: 'Users enabled', id: 'action.enableUser.sucess' }));
    },
    [dispatch, intl]
  );
  const disableUsersCallback = useCallback(
    async (ids) => {
      await dispatch(disableUsers(ids));
      await dispatch(getUsersAction());
      toastr.success('', intl.formatMessage({ defaultMessage: 'Users disabled', id: 'action.disableUser.sucess' }));
    },
    [dispatch, intl]
  );

  if (!users) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem md={9}>
              <h1>
                <FormattedMessage defaultMessage="Users" description="Users Title" id="users.title" />
              </h1>
            </GridItem>
            <GridItem md={2}>
              <ButtonNew onClick={handleAddUser} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Alert severity="info">
            <FormattedMessage
              defaultMessage={
                'you can massively enable or disable user by selecting them ' +
                'and using the right button in the toolbar'
              }
              id="users.tooltip"
            />
          </Alert>
          <UserList
            handleRowSelect={handleRowSelect}
            users={users}
            disableUsers={disableUsersCallback}
            enableUsers={enableUsersCallback}
          />
        </CardBody>
      </Card>
      <Dialog
        maxWidth="lg"
        onClose={() => OpenCloseModal({ user: undefined, openModal: false })}
        open={!!editionState.openModal}
      >
        <DialogTitle>
          {editionState.user ? (
            <FormattedMessage defaultMessage="Edit user" id="edit.user" />
          ) : (
            <FormattedMessage defaultMessage="Create a new user" id="create.user" />
          )}
        </DialogTitle>
        <DialogContent>
          <EditUser userId={editionState.user} doneAction={resetModal} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserListPage;
