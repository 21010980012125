import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Buffer } from 'buffer';
// Theme
import Button from 'theme/CustomButtons/Button';
// ## //
import { getBucketLink } from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';

interface Props {
  fileName: string;
}

const DownloadDialog: React.FC<Props> = ({ fileName }) => {
  const [loading, setLoading] = useState(true);
  const download = useSelector((state: RootState) => state.selectedLicenseeReducer.downloadLink);
  const buff = Buffer.from(fileName);
  const base64data = buff.toString('base64');

  const dispatch = useDispatch();

  const fetchLink = useCallback(async () => {
    setLoading(true);
    await dispatch(getBucketLink(base64data));
    setLoading(false);
  }, [base64data, dispatch]);

  useEffect(() => {
    if (base64data && fileName) {
      fetchLink();
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, base64data, fileName, fetchLink]);

  if (!loading) {
    if (download) {
      return (
        <Button href={download.toString()} round customColor="success">
          <FormattedMessage defaultMessage="Click here to download" id="download.action.download" />
        </Button>
      );
    }
    return (
      <Button disabled round customColor="warning">
        <FormattedMessage
          defaultMessage="There was an issue creating the download link"
          id="download.action.downloadError"
        />
      </Button>
    );
  }
  return (
    <Button disabled round customColor="warning">
      <FormattedMessage defaultMessage="Loading. Please wait ..." id="download.action.loading" />
    </Button>
  );
};

export default DownloadDialog;
