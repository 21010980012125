import React from 'react';
// Material Ui
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
// Icons
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const useStyles = makeStyles({
  mainBox: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  itemBox: {
    paddingTop: '0.2em',
  },
});

interface TimelineProps {
  children: React.ReactNode;
}

export const Timeline: React.FC<TimelineProps> = ({ children }) => (
  <div>
    <ul>{children}</ul>
  </div>
);

interface EventProps {
  interval: string;
  children: React.ReactNode;
}

export const Event: React.FC<EventProps> = ({ interval, children }) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.mainBox}>
      <Chip label={interval} color="primary" />
      <Box display="flex" className={classes.itemBox}>
        <DoubleArrowIcon />
        <Typography>{children}</Typography>
      </Box>
    </Box>
  );
};
