import { GET_PRISMIC_FAQ, GET_PRISMIC_RELEASES } from 'reducer/prismicReducer';
import { API_CALL_BEGIN, API_CALL_END } from 'reducer/apiReducer';
import PrismicApi from 'api/PrismicApi';
import { LocaleType } from 'types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getFaqAction = createAsyncThunk('prismic/faq', (locale: LocaleType, { dispatch }) => {
  dispatch(API_CALL_BEGIN());
  return PrismicApi.getFaq(locale)
    .then((data) => {
      dispatch(GET_PRISMIC_FAQ(data));
      dispatch(API_CALL_END());
    })
    .catch(() => {
      // Fail Graciously
      dispatch(API_CALL_END());
    });
});

export const getReleaseNotesAction = createAsyncThunk('prismic/releaseNote', (_void, { dispatch }) => {
  dispatch(API_CALL_BEGIN());
  return PrismicApi.getReleaseNotes()
    .then((data) => {
      dispatch(GET_PRISMIC_RELEASES(data));
      dispatch(API_CALL_END());
    })
    .catch(() => {
      // Fail Graciously
      dispatch(API_CALL_END());
    });
});
