import { createSlice } from '@reduxjs/toolkit';
import { RateType } from 'types';

export interface StripeReducerDataType {
  rates: RateType[] | undefined;
}

const initialState: StripeReducerDataType = {
  rates: undefined,
};

const stripeSlice = createSlice({
  name: 'stripeReducer',
  initialState,
  reducers: {
    GET_VTA_RATES_RESPONSE(state, action) {
      return {
        ...state,
        rates: action.payload,
      };
    },
  },
});

export const { GET_VTA_RATES_RESPONSE } = stripeSlice.actions;

export default stripeSlice.reducer;
