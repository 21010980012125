import React from 'react';
import MUIDataTable, { DisplayData, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { defineMessages, useIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
// Material Ui
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// Icons
import BlockIcon from '@mui/icons-material/Block';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LinkOffIcon from '@mui/icons-material/LinkOff';
// Theme
import Button from 'theme/CustomButtons/Button';
// Other
import { LicenceKeyType } from 'types';
import { findSelectedIds } from '../datatables/actionHelpers';
import CustomToolbarSelect from '../datatables/CustomToolbarSelect';
import TextLabels from '../datatables/datatables-messages';
// ## //
import { boolFormatter } from '../datatables/Formatters';
import { CustomMUIDataTableColumn, SelectedRows } from '../datatables/ListTypes';

const messages = defineMessages({
  disable: {
    defaultMessage: 'Disable',
    id: 'licencekey.disable',
  },
  enable: {
    defaultMessage: 'Enable',
    id: 'licencekey.enable',
  },
  enabled: {
    defaultMessage: 'Keys Enabled',
    id: 'action.enablelicencekey.message',
  },
  disabled: {
    defaultMessage: 'Keys Disabled',
    id: 'action.disablelicencekey.message',
  },
  key: {
    defaultMessage: 'Key',
    id: 'licencekey.list.key',
  },
  active: {
    defaultMessage: 'Active',
    id: 'licencekey.list.active',
  },
  licensee: {
    defaultMessage: 'Licensee',
    id: 'licencekey.list.licenseename',
  },
  status: {
    defaultMessage: 'Actions',
    id: 'licencekey.list.actions',
  },
  linkedUuid: {
    defaultMessage: 'Linked to machine ?',
    id: 'licencekey.list.linkedUuid',
  },
  product: {
    defaultMessage: 'Product',
    id: 'licencekey.list.product',
  },
  comment: {
    defaultMessage: 'Comment',
    id: 'licencekey.list.comment',
  },
  unlink: {
    defaultMessage: 'Unlink',
    id: 'licencekey.action.unlink',
  },
  unlinked: {
    defaultMessage: 'Keys unlinked',
    id: 'action.unlink.message',
  },
  info1: {
    defaultMessage:
      'To enable, disable, unlink, select the keys and use the action menu. To comment, click on the key first',
    id: 'licencekey.action.info1',
  },
  actions: {
    defaultMessage: 'Action',
    id: 'licensee.games.actions',
  },
  viewEvents: {
    defaultMessage: 'View',
    id: 'licensee.game.view',
  },
});

export const licenseeFormatter = (licenseeName: string, licenseeId: string): React.ReactNode => (
  <Link to={`/licensee/${licenseeId}`}>{licenseeName}</Link>
);

export interface LicenceKeysListProps {
  handleRowSelect: (row: string) => void;
  licencekeys: LicenceKeyType[];
  showLicensee?: boolean;
  action?: any; // FIXME: this is absurd
  staff?: boolean;
}

const LicenceKeysList: React.FC<LicenceKeysListProps> = ({
  action,
  showLicensee,
  staff,
  licencekeys,
  handleRowSelect,
}) => {
  const intl = useIntl();

  // TODO: Convert to Massive EN/DIS
  const handleEnableKey = (selectedRows: SelectedRows, displayData: DisplayData): void => {
    const ids = findSelectedIds(selectedRows, displayData);
    action.enableLicenceKeysAction(ids).then(() => {
      toastr.success('', intl.formatMessage(messages.enabled));
    });
  };

  const handleDisableKey = (selectedRows: SelectedRows, displayData: DisplayData): void => {
    const ids = findSelectedIds(selectedRows, displayData);
    action.disableLicenceKeysAction(ids).then(() => {
      toastr.success('', intl.formatMessage(messages.disabled));
    });
  };

  const handleUnlink = (selectedRows: SelectedRows, displayData: DisplayData): void => {
    const ids = findSelectedIds(selectedRows, displayData);
    action.unlinkAction(ids).then(() => {
      toastr.success('', intl.formatMessage(messages.unlinked));
    });
  };

  const selectKeyFormatter = (_void: unknown, tableMeta: MUIDataTableMeta): React.ReactNode => {
    const keyId = tableMeta.rowData[0];
    return (
      <Button round customColor="primary" onClick={() => handleRowSelect(keyId)}>
        {intl.formatMessage(messages.viewEvents)}
      </Button>
    );
  };

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'actions',
      label: intl.formatMessage(messages.actions),
      options: {
        empty: true,
        filter: false,
        customBodyRender: selectKeyFormatter,
      },
    },
    {
      name: showLicensee ? 'licensee._id' : 'licensee',
      options: { display: 'excluded' },
    },
    {
      name: 'key',
      label: intl.formatMessage(messages.key),
    },
    {
      name: 'active',
      label: intl.formatMessage(messages.active),
      options: {
        customBodyRender: boolFormatter,
      },
    },
    {
      name: 'product.name',
      label: intl.formatMessage(messages.product),
    },
    {
      name: 'comment',
      label: intl.formatMessage(messages.comment),
    },
    {
      name: 'linkedUuid',
      label: intl.formatMessage(messages.linkedUuid),
      options: {
        customBodyRender: boolFormatter,
      },
    },
  ];

  if (showLicensee) {
    columns.push({
      name: 'licensee.name',
      label: intl.formatMessage(messages.licensee),
      options: {
        customBodyRender: (cell, tableMeta) =>
          licenseeFormatter(
            cell,
            tableMeta.rowData[1] // 1 is licensee ID
          ),
      },
    });
  }

  const options: MUIDataTableOptions = {
    tableId: 'LicenceKeyList',
    textLabels: TextLabels(intl),
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    enableNestedDataAccess: '.',
    //  onRowClick: (rowData) => {
    //    handleRowSelect(rowData[0]); // 0 is ID
    //  },
  };
  if (action) {
    // eslint-disable-next-line react/display-name
    options.customToolbarSelect = (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}>
        {staff ? (
          <>
            <Tooltip title={intl.formatMessage(messages.unlink)}>
              <IconButton onClick={() => handleUnlink(selectedRows, displayData)}>
                <LinkOffIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage(messages.disable)}>
              <IconButton onClick={() => handleDisableKey(selectedRows, displayData)}>
                <BlockIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage(messages.enable)}>
              <IconButton onClick={() => handleEnableKey(selectedRows, displayData)}>
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title={intl.formatMessage(messages.unlink)}>
            <IconButton onClick={() => handleUnlink(selectedRows, displayData)}>
              <LinkOffIcon />
            </IconButton>
          </Tooltip>
        )}
      </CustomToolbarSelect>
    );
  } else {
    options.selectableRows = 'none';
  }

  return (
    <>
      <Alert severity="info">{intl.formatMessage(messages.info1)}</Alert>
      <MUIDataTable title="" data={licencekeys} options={options} columns={columns} />
    </>
  );
};

LicenceKeysList.defaultProps = {
  staff: false,
  showLicensee: false,
  action: undefined,
};

export default LicenceKeysList;
