import React from 'react';
import { FormattedMessage } from 'react-intl';
// Material Ui
import ButtonGroup from '@mui/material/ButtonGroup';
// Icons
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// Theme
import Button from 'theme/CustomButtons/Button';
import InfoArea from 'theme/InfoArea/InfoArea';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import CardFooter from 'theme/Card/CardFooter';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';

// ## //
import { LicenseeType, PlanType, RateType } from 'types';
import { ButtonDelete } from '../common/Buttons';

const renderPlan = (plan: PlanType | undefined): React.ReactNode => {
  if (plan) {
    return (
      // FIXME: Frequency itself is not translated ...
      <FormattedMessage
        defaultMessage="{royaltyFee} {royaltyCurrency} per player, billable every {interval_count} {interval}"
        description="plan summay"
        id="licensee.renderplan.summary"
        values={{
          royaltyFee: plan.royaltyFee,
          royaltyCurrency: plan.royaltyCurrency,
          interval: plan.interval,
          interval_count: plan.interval_count,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="No plan selected"
      description="No plan selelected"
      id="licensee.renderplan.noplan"
    />
  );
};

interface Props {
  licensee: LicenseeType;
  staff?: boolean;
  role?: string;
  editAction: () => void;
  rates: RateType[];
  customerTax?: string;
}

const LicenseeInfo: React.FC<Props> = ({ licensee, staff, role, editAction, rates, customerTax }) => {
  const rate = rates && rates.find((x) => x.id === licensee.stripe.rateId);
  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h1>
            <FormattedMessage
              defaultMessage="Licensee Info"
              description="Licensee Info title"
              id="licensee.info.title"
            />
          </h1>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={6}>
              <InfoArea title="Name" description={licensee.name} icon={EmojiPeopleIcon} iconColor="white" />
            </GridItem>
            <GridItem md={6}>
              <InfoArea
                title="Address"
                description={licensee.exploitationAddress || licensee.address.line1 || '-'}
                icon={ApartmentIcon}
                iconColor="white"
              />
            </GridItem>
            {(role === 'owner' || staff) && (
              <>
                <GridItem md={6}>
                  <InfoArea
                    title="TVA/VAT"
                    description={
                      <>
                        registration: {licensee.vat || customerTax || '-'} <br /> Rate:{' '}
                        {(rate && `${rate.description} ${rate.percentage}%`) || '-'}
                      </>
                    }
                    icon={MonetizationOnIcon}
                    iconColor="white"
                  />
                </GridItem>
                <GridItem md={6}>
                  <InfoArea
                    title="Plan"
                    description={renderPlan(licensee.plan)}
                    icon={BusinessCenterIcon}
                    iconColor="white"
                  />
                </GridItem>
                <GridItem md={6}>
                  <InfoArea title="Status" description={licensee.status} icon={BusinessCenterIcon} iconColor="white" />
                </GridItem>
              </>
            )}
          </GridContainer>
        </CardBody>
        <CardFooter>
          <ButtonGroup>
            {(role === 'owner' || staff) && (
              <Button customColor="primary" onClick={editAction}>
                <FormattedMessage defaultMessage="Edit" description="Edit button" id="button.edit" />
              </Button>
            )}
            {/* FIXME DELETE BUTTON */}
            {staff && <ButtonDelete onClick={() => {}} />}
          </ButtonGroup>
        </CardFooter>
      </Card>
    </>
  );
};

LicenseeInfo.defaultProps = {
  staff: false,
  role: 'staff',
  customerTax: '-',
};

export default LicenseeInfo;
