import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { getUserAction, saveUserAction } from 'action/UserAction';
import { RootState } from 'reducer/configureStore';
import { UserType } from 'types';
import UserForm from '../forms/UserForm';

interface Props {
  userId?: string;
  doneAction: () => void;
}

const AddOrEditUserContainer: React.FC<Props> = ({ userId, doneAction }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchUser = useCallback(async () => {
    await dispatch(getUserAction(userId));
    setLoading(false);
  }, [dispatch, userId]);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, fetchUser, userId]);

  const user = useSelector((state: RootState) => state.selectedUserReducer.user);

  const handleSave = useCallback(
    async (values: UserType) => {
      const userToSave = {
        _id: userId,
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        primaryEmail: values.primaryEmail,
        staff: values.staff,
        active: values.active,
        emailConfirmed: values.emailConfirmed,
      };

      await dispatch(saveUserAction(userToSave));
      toastr.success('', intl.formatMessage({ defaultMessage: 'User Saved', id: 'user.save.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, userId]
  );

  if (loading) {
    return null;
  }
  return <UserForm handleCancel={doneAction} handleSave={handleSave} initialValues={user} staff />;
};

AddOrEditUserContainer.defaultProps = {
  userId: undefined,
};

export default AddOrEditUserContainer;
