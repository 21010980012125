import { createSlice } from '@reduxjs/toolkit';
import { UserType } from 'types';

export interface UsersReducerDataType {
  users: UserType[];
}

const initialState: UsersReducerDataType = {
  users: [],
};

const usersSlice = createSlice({
  name: 'usersReducer',
  initialState,
  reducers: {
    GET_USERS_RESPONSE(state, action) {
      return {
        ...state,
        users: action.payload,
      };
    },
  },
});

export const { GET_USERS_RESPONSE } = usersSlice.actions;

export default usersSlice.reducer;
