import React, { useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// ## //
import { setRedirectUrl } from 'action/AuthAction';
import { RootState } from 'reducer/configureStore';

interface Props extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: React.FC<RouteComponentProps<{}>> | React.ComponentClass<unknown> | React.FC<unknown>;
  childProps?: Record<string, unknown>;
}

const LoggedInRoute: React.FC<Props> = ({ component, childProps, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const Component = component;

  useEffect(() => {
    if (!isLoggedIn) {
      // set the current url/path for future redirection (we use a Redux action)
      // then redirect (we use a React Router method)
      const currentURL = location.pathname;
      if (currentURL !== '/login') {
        dispatch(setRedirectUrl(currentURL));
        history.push('/login');
        //   action.setRedirectUrl(currentURL)
        //     .then(() => history.push('/login'));
      }
    }
  }, [isLoggedIn, history, dispatch, location.pathname]);

  if (!isLoggedIn) {
    return null;
  }
  return <Route {...rest} render={(routeProps) => <Component {...routeProps} {...childProps} />} />;
};

LoggedInRoute.defaultProps = {
  childProps: {},
};

export default LoggedInRoute;
