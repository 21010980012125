import { GameEventType, GameType } from 'types';
import axios from './ApiConfig';

class GameApi {
  static getGames(filter?: string): Promise<GameType[]> {
    if (filter === 'contest') {
      return axios.get('/api/games/contest').then((response) => response.data);
    }
    // Return all games
    return axios.get('/api/games').then((response) => response.data);
  }

  static getLicenseeGames(LicenseeId: string): Promise<GameType[]> {
    return axios.get(`/api/licensee/games/${LicenseeId}`).then((response) => response.data);
  }

  static syncGames(licenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/games/sync/${licenseeId}`).then((response) => response.data);
  }

  static syncAllGames(): Promise<any> {
    return axios.post('/api/games/syncall').then((response) => response.data);
  }

  static getGameEvents(gameId: string): Promise<GameEventType[]> {
    return axios.get(`/api/licensee/gameEvents/${gameId}`).then((response) => response.data);
  }

  static contestGame(game: string, reason = 'cancel', cancellation = false): Promise<any> {
    return axios
      .post('/api/games/contest', {
        game,
        reason,
        cancellation,
      })
      .then((response) => response.data);
  }

  static validateContestGame(game: string, doValidate?: boolean): Promise<any> {
    return axios
      .post('/api/games/validatecontest', {
        game,
        valid: doValidate,
      })
      .then((response) => response.data);
  }
}

export default GameApi;
