import { createSlice } from '@reduxjs/toolkit';
import { GameType } from 'types';

export interface GamesReducerDataType {
  games: GameType[];
}

const initialState: GamesReducerDataType = {
  games: [],
};

const gamesSlice = createSlice({
  name: 'gamesReducer',
  initialState,
  reducers: {
    GET_GAMES_RESPONSE(state, action) {
      return {
        ...state,
        games: action.payload,
      };
    },
  },
});

export const { GET_GAMES_RESPONSE } = gamesSlice.actions;

export default gamesSlice.reducer;
