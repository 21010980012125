import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
// Material Ui
import ButtonGroup from '@mui/material/ButtonGroup';
// Theme
import Button from 'theme/CustomButtons/Button';
// ## //
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
    id: 'invitation.list.email',
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'invitation.list.actions',
  },
  unInviteButton: {
    defaultMessage: 'remove',
    id: 'invitation.list.removebutton',
  },
  title: {
    defaultMessage: 'Invitations',
    id: 'invitation.list.title',
  },
  role: {
    defaultMessage: 'Role',
    id: 'user.role',
  },
});

interface Props {
  invitations: any;
  handleUnInvite: any;
  changeRole: any;
}

const InvitationList: React.FC<Props> = ({ invitations, handleUnInvite, changeRole }) => {
  const intl = useIntl();
  const buttonFormatter = (_void: unknown, tableMeta: MUIDataTableMeta): React.ReactNode => {
    const invite = {
      _id: tableMeta.rowData[0],
      email: tableMeta.rowData[1],
      role: tableMeta.rowData[2],
    };
    return (
      <ButtonGroup>
        <Button round customColor="danger" size="small" onClick={() => handleUnInvite(invite)}>
          {intl.formatMessage(messages.unInviteButton)}
        </Button>
        {invite.role === 'staff' ? (
          <Button
            round
            customColor="primary"
            size="small"
            id={`makeOwner_${invite._id}`}
            onClick={() => changeRole({ _id: invite._id, role: 'owner' })}
          >
            <FormattedMessage defaultMessage="make Owner" id="invitationList.list.makeOwner" />
          </Button>
        ) : (
          <Button
            round
            customColor="primary"
            size="small"
            id={`makeStaff_${invite._id}`}
            onClick={() => changeRole({ _id: invite._id, role: 'staff' })}
          >
            <FormattedMessage defaultMessage="make Staff" id="invitationList.list.makeStaff" />
          </Button>
        )}
      </ButtonGroup>
    );
  };

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'email',
      label: intl.formatMessage(messages.email),
    },
    {
      name: 'role',
      label: intl.formatMessage(messages.role),
    },
    {
      name: 'actions',
      label: intl.formatMessage(messages.actions),
      options: {
        customBodyRender: buttonFormatter,
      },
    },
  ];
  const options: MUIDataTableOptions = {
    tableId: 'InvitationsList',
    textLabels: TextLabels(intl),
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    selectableRows: 'none',
  };

  return <MUIDataTable title="" data={invitations} columns={columns} options={options} />;
};

export default InvitationList;
