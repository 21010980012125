import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import withStyles from '@mui/styles/withStyles';
import { DisplayData } from 'mui-datatables';
import { SelectedRows } from './ListTypes';
// Stylres
import useStyles from './styles/customToolbarSelectStyles';

const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

interface Props {
  selectedRows: SelectedRows;
  displayData: DisplayData;
  setSelectedRows: (rows: number[]) => void;
  children?: React.ReactNode;
}

const CustomToolbarSelect: React.FC<Props> = ({ children, setSelectedRows, displayData, selectedRows }) => {
  const classes = useStyles();
  const handleClickInverseSelection = () => {
    const result = displayData.reduce<number[]>((nextSelectedRows, _, index) => {
      if (!selectedRows.data.find((selectedRow) => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    setSelectedRows(result);
  };

  const handleClickDeselectAll = (): void => {
    setSelectedRows([]);
  };

  return (
    <div className={classes.iconContainer}>
      <Tooltip title="Deselect ALL">
        <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Inverse selection">
        <IconButton className={classes.iconButton} onClick={handleClickInverseSelection}>
          <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(' ')} />
        </IconButton>
      </Tooltip>
      {children}
    </div>
  );
};

CustomToolbarSelect.defaultProps = {
  children: [],
};

export default withStyles(defaultToolbarSelectStyles, { name: 'CustomToolbarSelect' })(CustomToolbarSelect);
