import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      padding: theme.spacing(2),
      width: '100%',
    },
  })
);
