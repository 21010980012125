import { LicenseeType } from 'types';
import axios from './ApiConfig';

class LicenseeApi {
  static getAllLicensees(): Promise<LicenseeType[]> {
    return axios.get('/api/licensee').then((response) => response.data);
  }

  static getLicensee(LicenseeId: string): Promise<LicenseeType> {
    return axios.get(`/api/licensee/${LicenseeId}`).then((response) => response.data);
  }

  static saveLicensee(param: any): Promise<any> {
    const licensee = { ...param }; // to avoid manipulating object passed in.
    return axios.post('/api/licensee', { licensee }).then((response) => response.data);
  }

  static assignUsers(users: any, licenseeId: string): Promise<any> {
    return axios
      .post('/api/licensee/users', {
        licenseeId,
        users,
      })
      .then((response) => response.data);
  }

  static deleteLicensee(licenseeId: string): Promise<any> {
    return axios
      .delete('/api/licensee', {
        data: {
          licenseeId,
        },
      })
      .then((response) => response.status === 200);
  }

  /**
   * License Keys
   */

  static getLicenseeKeys(LicenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/key/${LicenseeId}`).then((response) => response.data);
  }

  // FIXME OBSOLETE
  static createLicenceKey(LicenseeId: string): Promise<any> {
    return axios.post('/api/licence-key', { licenseeId: LicenseeId }).then((response) => response.data);
  }

  /**
   * Invitations
   */

  static getInvitations(LicenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/invitations/${LicenseeId}`).then((response) => response.data);
  }

  static addInvitations(LicenseeId: string, emails: any): Promise<any> {
    return axios.post(`/api/licensee/invitations/${LicenseeId}`, { emails }).then((response) => response.data);
  }

  static editInvitation(invitation: any): Promise<any> {
    return axios.post(`/api/licensee/invitation/${invitation._id}`, { invitation }).then((response) => response.data);
  }

  static deleteInvitations(LicenseeId: string, invitation: any): Promise<any> {
    return axios
      .delete(`/api/licensee/invitations/${LicenseeId}`, {
        data: {
          invitation,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Invoices
   */

  static getInvoices(LicenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/invoices/${LicenseeId}`).then((response) => response.data);
  }

  /**
   * Dowloads
   */
  static getBuckets(LicenseeId: string): Promise<any> {
    return axios.get(`/api/licensee/buckets/${LicenseeId}`).then((response) => response.data);
  }

  static getBucketLink(base64Name: string): Promise<any> {
    return axios.get(`/api/getobj/${base64Name}`).then((response) => response.data);
  }
}

export default LicenseeApi;
