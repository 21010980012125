import React, { useEffect } from 'react';
import { Route, useHistory, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'reducer/configureStore';

interface Props extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: React.FC<RouteComponentProps<{}>> | React.ComponentClass<unknown> | React.FC<unknown>;
  childProps?: unknown;
}

const LoggedOutRoute: React.FC<Props> = ({ component, childProps, ...rest }: Props) => {
  const history = useHistory();
  const isLoggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const Component = component;

  useEffect(() => {
    if (isLoggedIn) {
      // set the current url/path for future redirection (we use a Redux action)
      // then redirect (we use a React Router method)
      const currentURL = history.location.pathname;
      if (currentURL !== '/') {
        history.push('/');
      }
    }
  }, [isLoggedIn, history]);

  if (isLoggedIn) {
    return null;
  }
  return <Route {...rest} render={(routeProps) => <Component {...routeProps} {...childProps} />} />;
};

LoggedOutRoute.defaultProps = {
  childProps: {},
};

export default LoggedOutRoute;
