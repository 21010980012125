import { createSlice } from '@reduxjs/toolkit';
import { LicenceKeyType } from 'types';

export interface SelectedLicenceKeyReducerDataType {
  licenceKey?: LicenceKeyType;
  games: any; // FIXME: type
}

const initialState: SelectedLicenceKeyReducerDataType = {
  licenceKey: undefined,
  games: [],
};

const selectedLicenceKeySlice = createSlice({
  name: 'selectedLicenceKeyReducer',
  initialState,
  reducers: {
    GET_LICENCE_KEY_RESPONSE(state, action) {
      return {
        ...state,
        licenceKey: action.payload,
      };
    },
    GET_LICENCE_KEY_GAMES_RESPONSE(state, action) {
      return {
        ...state,
        games: action.payload,
      };
    },
    RESET_LICENCEKEY_RESPONSE() {
      return initialState;
    },
  },
});

export const { GET_LICENCE_KEY_RESPONSE, GET_LICENCE_KEY_GAMES_RESPONSE, RESET_LICENCEKEY_RESPONSE } =
  selectedLicenceKeySlice.actions;

export default selectedLicenceKeySlice.reducer;
