import { LicenceKeyType } from 'types';
import axios from './ApiConfig';

export interface SavedLicenceKey {
  _id?: string;
  product: string;
  active: boolean;
  comment: string;
  licensee: string;
}

class LicenceKeyApi {
  static getLicenceKeys(): Promise<LicenceKeyType[]> {
    return axios.get('/api/licence-key').then((response) => response.data);
  }

  static getLicenceKey(keyId: string): Promise<LicenceKeyType> {
    return axios.get(`/api/licence-key/${keyId}`).then((response) => response.data);
  }

  static getGames(keyId: string): Promise<any> {
    return axios.get(`/api/licence-key/${keyId}/games`).then((response) => response.data);
  }

  static saveLicenceKey(licenceKey: SavedLicenceKey, amount?: number): Promise<any> {
    return axios.post('/api/licence-key', { licenceKey, amount }).then((response) => response.data);
  }

  static enableLicenceKeys(ids: string[]): Promise<any> {
    return axios
      .post('/api/licence-key/status', {
        licenceKeys: ids,
        active: true,
      })
      .then((response) => response.data);
  }

  static disableLicenceKeys(ids: string[]): Promise<any> {
    return axios
      .post('/api/licence-key/status', {
        licenceKeys: ids,
        active: false,
      })
      .then((response) => response.data);
  }

  static unlinkLicenceKeys(ids: string[]): Promise<any> {
    return axios
      .post('/api/licence-key/status', {
        licenceKeys: ids,
        unlink: true,
      })
      .then((response) => response.data);
  }
}

export default LicenceKeyApi;
