import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
// ## //
import { ProductType } from 'types';
import TextLabels from '../datatables/datatables-messages';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  name: {
    defaultMessage: 'Name',
    id: 'rates.name',
  },
  key: {
    defaultMessage: 'Key',
    id: 'rates.key',
  },
});

interface Props {
  Products: ProductType[];
  handleRowSelect: (row: string) => void;
}

const ProductsList: React.FC<Props> = ({ Products, handleRowSelect }) => {
  const intl = useIntl();

  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: intl.formatMessage(messages.name),
    },
    {
      name: 'key',
      label: intl.formatMessage(messages.key),
    },
  ];

  const options: MUIDataTableOptions = {
    tableId: 'ProductsList',
    textLabels: TextLabels(intl),
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    selectableRows: 'none',
    viewColumns: false,
    sortFilterList: false,
    search: false,
    print: false,
    pagination: false,
    filter: false,
    download: false,
    onRowClick: (rowData) => {
      handleRowSelect(rowData[0]); // 0 is ID
    },
  };

  return <MUIDataTable title="" data={Products} options={options} columns={columns} />;
};

export default ProductsList;
