import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormattedMessage, useIntl } from 'react-intl';
// Material Ui
/*
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
*/
// Theme
import Button from 'theme/CustomButtons/Button';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import { subscribeAction, unsubscribeAction } from 'action/PaymentAction';
import { RootState } from 'reducer/configureStore';
import { SubscriptionContainerType } from 'types';
import SubscriptionInfo from './SubscriptionInfo';

const renderSub = (subscriptions: SubscriptionContainerType | undefined): React.ReactNode => {
  if (subscriptions && subscriptions.total_count > 0) {
    // FIXME: Manage has_more (should'nt happen)
    return (
      <>
        {subscriptions.data.map((subscription) => (
          <SubscriptionInfo key={subscription.id} subscription={subscription} />
        ))}
      </>
    );
  }

  return <></>;
};

// TODO: Convert intl styles to defineMessage & hooks for consistency
const SubscriptionList: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const customer = useSelector((state: RootState) => state.selectedLicenseeReducer.customer);

  const handleSubscribe = useCallback(async () => {
    if (licensee) {
      setSubmitting(true);
      await dispatch(subscribeAction(licensee._id));
      setSubmitting(false);
      toastr.success(
        '',
        intl.formatMessage({ defaultMessage: 'Subscription activated', id: 'subscription.activation.message' })
      );
    }
  }, [dispatch, intl, licensee]);

  const handleUnsubscribe = useCallback(async () => {
    if (licensee) {
      setSubmitting(true);
      await dispatch(unsubscribeAction(licensee._id));
      setSubmitting(false);
      toastr.success(
        '',
        intl.formatMessage({
          defaultMessage: 'Subscription cancelled. Invoices still have to be settled',
          id: 'subscription.desactivation.message',
        })
      );
    }
  }, [dispatch, intl, licensee]);

  if (customer) {
    const { subscriptions } = customer;

    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem md={6}>
              <h1>
                <FormattedMessage defaultMessage="Subscription Info" id="subscription.title" />
              </h1>
            </GridItem>
            <GridItem md={2}>
              {(subscriptions && subscriptions.data && subscriptions.data.length > 0 && (
                <Button customColor="danger" disabled={submitting} variant="contained" onClick={handleUnsubscribe}>
                  <FormattedMessage defaultMessage="Stop Subscription" id="subscription.stop" />
                </Button>
              )) || (
                <Button customColor="primary" disabled={submitting} variant="contained" onClick={handleSubscribe}>
                  <FormattedMessage defaultMessage="Activate Subscription" id="subscription.start" />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>{renderSub(subscriptions)}</CardBody>
      </Card>
    );
  }
  return null;
};

export default SubscriptionList;
