import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Moment from 'react-moment';
// Icons
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ExposureIcon from '@mui/icons-material/Exposure';
// Theme
import InfoArea from 'theme/InfoArea/InfoArea';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
import { RateType, SubscriptionType } from 'types';

const messages = defineMessages({
  billing: {
    defaultMessage: 'Billing',
    id: 'billing.billing',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'billing.quantity',
  },
  status: {
    defaultMessage: 'Status',
    id: 'billing.status',
  },
  nextInvoice: {
    defaultMessage: 'Next invoice',
    id: 'billing.nextInvoice',
  },
  tax: {
    defaultMessage: 'Applicable Taxes',
    id: 'billing.taxes',
  },
  noTax: {
    defaultMessage: 'No Taxes defined',
    id: 'billing.NoTaxes',
  },
});

interface Props {
  subscription: SubscriptionType;
}

const SubscriptionInfo: React.FC<Props> = ({ subscription }) => {
  const intl = useIntl();
  const formatTax = (taxes: RateType[]): React.ReactNode => {
    if (!taxes || taxes.length === 0) {
      return intl.formatMessage(messages.noTax);
    }
    return taxes.map((tax) => (
      <React.Fragment key={tax.display_name}>
        {tax.jurisdiction} - {tax.display_name} - {tax.percentage}
        %
        <br />
      </React.Fragment>
    ));
  };
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={6}>
            <InfoArea title="stripeId" description={subscription.id || '-'} icon={FingerprintIcon} iconColor="white" />
          </GridItem>
          <GridItem md={6}>
            <InfoArea
              title={intl.formatMessage(messages.billing)}
              description={subscription.billing || '-'}
              icon={AutorenewIcon}
              iconColor="white"
            />
          </GridItem>
          <GridItem md={6}>
            <InfoArea
              title={intl.formatMessage(messages.tax)}
              description={formatTax(subscription.default_tax_rates)}
              icon={ExposureIcon}
              iconColor="white"
            />
          </GridItem>
          <GridItem md={6}>
            <InfoArea
              title="Quantity"
              description={subscription.quantity || '-'}
              icon={UnfoldMoreIcon}
              iconColor="white"
            />
          </GridItem>
          <GridItem md={6}>
            <InfoArea
              title="Status"
              description={
                subscription.cancel_at_period_end ? (
                  <>
                    Cancelled, will end on
                    <Moment format=" YYYY/MM/DD" unix>
                      {subscription.current_period_end}
                    </Moment>
                  </>
                ) : (
                  subscription.status
                )
              }
              icon={
                subscription.status === 'active' && !subscription.cancel_at_period_end
                  ? PowerOutlinedIcon
                  : PowerOffOutlinedIcon
              }
              iconColor="white"
            />
          </GridItem>
          <GridItem md={6}>
            <InfoArea
              title="Next Invoice"
              description={
                <Moment format="YYYY/MM/DD" unix>
                  {subscription.current_period_end}
                </Moment>
              }
              icon={RedoOutlinedIcon}
              iconColor="white"
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default SubscriptionInfo;
