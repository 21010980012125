import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// Material Ui
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  name: string;
  label: string;
  readOnly?: boolean;
}

const SwitchInput: React.FC<Props> = ({ name, label, readOnly }) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          disabled={readOnly}
          render={(props) => (
            <Switch
              // eslint-disable-next-line react/prop-types
              onChange={(e) => props.onChange(e.target.checked)}
              // eslint-disable-next-line react/prop-types
              checked={props.value}
            />
          )}
        />
      }
      label={label}
    />
  );
};

SwitchInput.defaultProps = {
  readOnly: false,
};

export default SwitchInput;
