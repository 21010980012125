import React from 'react';
import { FormattedMessage } from 'react-intl';
// MaterialUi
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// ## //
import EditLicenceKey from '../Controllers/AddOrEditLicenceKeyContainer';
import LicenceKeyGamesContainer from '../Controllers/LicenceKeyGamesContainer';

interface Props {
  editionState: any;
  resetModal: () => void;
}

const LicenceKeyDialog: React.FC<Props> = ({ editionState, resetModal }) => (
  <Dialog maxWidth="lg" onClose={resetModal} open={!!editionState.openModal}>
    <DialogTitle>
      {editionState.data ? (
        <FormattedMessage defaultMessage="Edit Licence Key" id="edit.licenceKey" />
      ) : (
        <FormattedMessage defaultMessage="Create a Licence Key" id="create.licenceKey" />
      )}
    </DialogTitle>
    <DialogContent>
      <EditLicenceKey licenceKeyId={editionState.data} doneAction={resetModal} />
      {editionState.data && <LicenceKeyGamesContainer licenceKeyId={editionState.data} />}
    </DialogContent>
  </Dialog>
);

export default LicenceKeyDialog;
