import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { defineMessages, useIntl } from 'react-intl';
// Material UI
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// ## //
import { getGamesGraphAction } from 'action/StatisticsAction';
import { RootState } from 'reducer/configureStore';
import options from './GamesGraphOptions';

const messages = defineMessages({
  title: {
    defaultMessage: 'Latest Games',
    id: 'dashboard.gamesGraph.title',
  },
});

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

interface Props {
  className?: string;
}

const GamesGraph: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const gamesGraph = useSelector((state: RootState) => state.statisticsReducer.gamesGraph);

  useEffect(() => {
    dispatch(getGamesGraphAction());
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch]);

  if (gamesGraph) {
    const { graphData } = gamesGraph;
    const data = {
      labels: graphData.map((x: any) => x.date),
      datasets: [
        {
          label: 'Games',
          backgroundColor: '#0aa6d1',
          barThickness: 12,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          maxBarThickness: 10,
          data: graphData.map((x: any) => x.games),
        },
        {
          label: 'Players',
          backgroundColor: '#aa80b7',
          barThickness: 12,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          maxBarThickness: 10,
          data: graphData.map((x: any) => x.players),
        },
      ],
    };

    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={intl.formatMessage(messages.title)} />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={data} options={options} />
          </div>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title={intl.formatMessage(messages.title)} />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <CircularProgress />
        </div>
      </CardContent>
    </Card>
  );
};

GamesGraph.defaultProps = {
  className: 'DasboardGamesGraph',
};

export default GamesGraph;
