import { createSlice } from '@reduxjs/toolkit';

export interface StatisticsReducerDataType {
  // FIXME: Type
  games: any;
  licensees: any;
  keys: any;
  cash: any;
  licenseesGraph: any;
  gamesGraph: any;
}

const initialState: StatisticsReducerDataType = {
  games: [],
  licensees: [],
  keys: [],
  cash: undefined,
  licenseesGraph: undefined,
  gamesGraph: undefined,
};

const StatisticsSlice = createSlice({
  name: 'statisticsReducer',
  initialState,
  reducers: {
    GET_GAMES_STATS(state, action) {
      return {
        ...state,
        games: action.payload,
      };
    },
    GET_LICENSEES_STATS(state, action) {
      return {
        ...state,
        licensees: action.payload,
      };
    },
    GET_KEYS_STATS(state, action) {
      return {
        ...state,
        keys: action.payload,
      };
    },
    GET_CASH_STATS(state, action) {
      return {
        ...state,
        cash: action.payload,
      };
    },
    GET_LICENSEES_GRAPHBYPLAN(state, action) {
      return {
        ...state,
        licenseesGraph: action.payload,
      };
    },
    GET_GAMES_GRAPH(state, action) {
      return {
        ...state,
        gamesGraph: action.payload,
      };
    },
  },
});

export const {
  GET_GAMES_STATS,
  GET_LICENSEES_STATS,
  GET_KEYS_STATS,
  GET_CASH_STATS,
  GET_LICENSEES_GRAPHBYPLAN,
  GET_GAMES_GRAPH,
} = StatisticsSlice.actions;

export default StatisticsSlice.reducer;
