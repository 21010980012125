import { createSlice } from '@reduxjs/toolkit';

export interface LicenceKeysReducerDataType {
  licencekeys: any; // FIXME: LICENCE KEYS DATA TYPE
}

const initialState: LicenceKeysReducerDataType = {
  licencekeys: [],
};

const licenceKeysSlice = createSlice({
  name: 'licenceKeysReducer',
  initialState,
  reducers: {
    GET_LICENCE_KEYS_RESPONSE(state, action) {
      return {
        ...state,
        licencekeys: action.payload,
      };
    },
  },
});

export const { GET_LICENCE_KEYS_RESPONSE } = licenceKeysSlice.actions;

export default licenceKeysSlice.reducer;
