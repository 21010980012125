import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// Material Ui
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
// ## //
import { getFaqAction } from 'action/PrismicAction';
import { RootState } from 'reducer/configureStore';
import Article from '../prismic/FaqArticle';

const Faq: React.FC = () => {
  const dispatch = useDispatch();
  const faq = useSelector((state: RootState) => state.prismicReducer.faq);
  const locale = useSelector((state: RootState) => state.intl.locale);

  useEffect(() => {
    dispatch(getFaqAction(locale));
  }, [dispatch, locale]);

  if (faq) {
    return (
      <Container>
        {faq.map((page) => (
          <Article prismicDoc={page} key={page.id} />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Alert severity="warning">
        <FormattedMessage defaultMessage="FAQ is not available at the moment" id="header.error.nonews" />
      </Alert>
    </Container>
  );
};

export default Faq;
