import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
// Material Ui
import FormGroup from '@mui/material/FormGroup';
// Theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
import SwitchInput from 'theme/CustomInput/CustomSwitch';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardFooter from 'theme/Card/CardFooter';
// ## //
import { UserType } from 'types';
import { ButtonCancel, ButtonSubmit, ButtonClear } from '../common/Buttons';

const labels = defineMessages({
  email: {
    id: 'user.form.Email',
    defaultMessage: 'Email',
  },
  firstname: {
    id: 'user.form.firstname',
    defaultMessage: 'First Name',
  },
  lastname: {
    id: 'user.form.lastname',
    defaultMessage: 'Last Name',
  },
  isadmin: {
    id: 'user.form.isadmin',
    defaultMessage: 'Is Admin ??',
  },
  emailConfirmed: {
    id: 'user.form.emailConfirmed',
    defaultMessage: 'Email Confirmed',
  },
  active: {
    id: 'user.form.active',
    defaultMessage: 'Is Active',
  },
});

export interface UserFormProps {
  handleSave: (value: UserType) => void;
  staff: boolean;
  initialValues?: Partial<UserType> | undefined;
  handleCancel: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ staff, handleSave, initialValues, handleCancel }) => {
  const methods = useForm<UserType>({ defaultValues: initialValues, mode: 'onBlur' });
  const intl = useIntl();
  const { handleSubmit, formState, reset } = methods;
  const { isDirty, isSubmitting } = formState;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <FormGroup row>
              <TextFieldInput
                type="email"
                name="primaryEmail"
                readOnly={!staff}
                placeholder="email@xxxx.xxx"
                label={intl.formatMessage(labels.email)}
                fullWidth
              />
            </FormGroup>
            <FormGroup row>
              <TextFieldInput
                type="text"
                name="firstName"
                placeholder={intl.formatMessage(labels.firstname)}
                label={intl.formatMessage(labels.firstname)}
              />
              <TextFieldInput
                type="text"
                name="lastName"
                placeholder={intl.formatMessage(labels.lastname)}
                label={intl.formatMessage(labels.lastname)}
              />
            </FormGroup>
            {staff && (
              <FormGroup row>
                <SwitchInput label={intl.formatMessage(labels.isadmin)} name="staff" />
                <SwitchInput label={intl.formatMessage(labels.emailConfirmed)} name="emailConfirmed" />
                <SwitchInput label={intl.formatMessage(labels.active)} name="active" />
              </FormGroup>
            )}
          </CardBody>
          <CardFooter>
            <ButtonSubmit disabled={isSubmitting || !isDirty} />
            <ButtonClear disabled={!isDirty} onClick={() => reset(initialValues)} />
            <ButtonCancel onClick={handleCancel} />
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};

UserForm.defaultProps = {
  initialValues: {
    active: true,
    emailConfirmed: true,
    staff: false,
  },
};

export default UserForm;
