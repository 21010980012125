import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  modal: {
    borderRadius: '6px',
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalCloseButton: {
    color: '#999999',
    marginTop: '-12px',
    WebkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    fontSize: 'inherit',
    opacity: '.9',
    textShadow: 'none',
    fontWeight: 700,
    lineHeight: '1',
    float: 'right',
  },
  modalClose: {
    width: '16px',
    height: '16px',
  },
  modalBody: {
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    position: 'relative',
  },
});
