import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// Material Ui
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
// ## //
import { getReleaseNotesAction } from 'action/PrismicAction';
import { RootState } from 'reducer/configureStore';
import ReleaseNote from './ReleaseNote';

const ReleaseNotes: React.FC = () => {
  const dispatch = useDispatch();
  const releaseNotes = useSelector((state: RootState) => state.prismicReducer.releaseNotes);
  const locale = useSelector((state: RootState) => state.intl.locale);

  useEffect(() => {
    dispatch(getReleaseNotesAction());
  }, [dispatch, locale]);

  if (releaseNotes) {
    return (
      <Container>
        {releaseNotes.map((page: any) => (
          <ReleaseNote prismicDoc={page} key={page.id} />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Alert severity="warning">
        <FormattedMessage defaultMessage="News are not available at the moment" id="header.error.nonews" />
      </Alert>
    </Container>
  );
};

export default ReleaseNotes;
