import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Theme
import CustomTabs from 'theme/CustomTabs/CustomTabs';
// Icon
import BugReportIcon from '@mui/icons-material/BugReport';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
// ## //
import { getGamesAction } from 'action/GameAction';
import { RootState } from 'reducer/configureStore';
import { defineMessages, useIntl } from 'react-intl';
import GamesList from '../../lists/GamesList';

const messages = defineMessages({
  title: {
    defaultMessage: 'Games',
    id: 'games.list.title',
  },
});

const GameListPage: React.FC = () => {
  const intl = useIntl();
  const [tab, setTab] = useState('contest');
  const games = useSelector((state: RootState) => state.gamesReducer.games);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tab === 'contest') {
      dispatch(getGamesAction('contest'));
    } else {
      dispatch(getGamesAction());
    }
    // eslint-disable-next-line no-console
    return () => /* TODO: use AbortController */ console.log('cleanup');
  }, [dispatch, tab]);

  const handleChange = (_event: unknown, newValue: string): void => {
    setTab(newValue);
  };

  if (games) {
    return (
      <CustomTabs
        title={intl.formatMessage(messages.title)}
        handleChange={handleChange}
        value={tab}
        headerColor="primary"
        globalContent={<GamesList games={games} showLicensee />}
        tabs={[
          { tabIcon: BugReportIcon, tabName: 'Contested games', tabValue: 'contest' },
          { tabIcon: AllInclusiveIcon, tabName: 'All games', tabValue: 'all' },
        ]}
      />
    );
  }
  return null;
};

export default GameListPage;
