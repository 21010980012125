import { configureStore, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { Dispatch, combineReducers, AnyAction } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { intlReducer } from 'react-intl-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import fetchError from '../action/ErrorAction';
// Default Locale
import localeFr from '../i18n/fr.json';

// Reducers
import selectedUserReducer from './selectedUserReducer';
import usersReducer from './usersReducer';
import selectedLicenseeReducer from './selectedLicenseeReducer';
import licenseesReducer from './licenseesReducer';
import apiReducer from './apiReducer';
import selectedLicenceKeyReducer from './selectedLicenceKeyReducer';
import authenticationReducer from './authenticationReducer';
import plansReducer from './plansReducer';
import gamesReducer from './gamesReducer';
import selectedPlanReducer from './selectedPlanReducer';
import licencekeysReducer from './licencekeysReducer';
import selectedGameReducer from './selectedGameReducer';
import prismicReducer from './prismicReducer';
import statisticsReducer from './statisticsReducer';
import stripeReducer from './stripeReducer';
import productReducer from './productReducer';
import { useDispatch } from 'react-redux';

export interface MiddlewareAPI {
  dispatch: Dispatch;
  getState: () => RootState;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asyncCatch =
  (handler: any) =>
  (store: MiddlewareAPI) =>
  (next: Dispatch) =>
  async (
    action: PayloadAction<
      unknown,
      string,
      {
        arg: void;
        requestId: string;
        rejectedWithValue: boolean;
        requestStatus: 'rejected';
        aborted: boolean;
        condition: boolean;
      },
      SerializedError
    >
  ) => {
    if (action?.type.match('rejected$')) {
      console.log('ASYNC CATCHHHH', action);
      store.dispatch(handler(action.error, action));

      return Promise.resolve(action.error);
    }
    return next(action);

    /*
  try {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await next(action);
  } catch (e) {
    console.log('ASYNC CATCHHHH', e);
    store.dispatch(handler(e, action));

    return Promise.resolve(e);
    // return Promise.reject(e);
  }
  */
  };

// This will hydrate the store or return default state when loading the app
export const preloadedState = {
  intl: {
    defaultLocale: 'fr',
    locale: 'fr',
    messages: localeFr,
  },
  // ...other initialState
};

/* Might be uselfull later if we store stuff in local browser
// This will allow to store global context in localStorage at login
const APP_STATE = 'SL_SESSION';

type PreloadedStateType = typeof preloadedState;

const reHydrateStore = (): RootState | PreloadedStateType => {
  const data = localStorage.getItem(APP_STATE);
  if (data) {
    const parsedData = <RootState>JSON.parse(data);
    return {
      authenticationReducer: parsedData.authenticationReducer,
      intl: parsedData.intl,
    };
  }
  return preloadedState;
};
*/

// Main Store Config
export const rootReducer = combineReducers({
  prismicReducer,
  usersReducer,
  selectedUserReducer,
  licenseesReducer,
  selectedLicenseeReducer,
  apiReducer,
  licencekeysReducer,
  gamesReducer,
  selectedGameReducer,
  selectedLicenceKeyReducer,
  authenticationReducer,
  plansReducer,
  selectedPlanReducer,
  statisticsReducer,
  productReducer,
  stripeReducer,
  intl: intlReducer,
  toastr: toastrReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
// eslint-disable-next-line max-len
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

// Main Store Config
const Configurator = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: [thunk, asyncCatch(fetchError)],
});

export default Configurator;
