import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

// Actions
import { getVTARatesAction } from 'action/PaymentAction';

import { RootState } from 'reducer/configureStore';
import { checkSessionAction } from 'action/AuthAction';
import LayoutHandler from './components/layout/LayoutHandler';

// Commons
import Backdrop from './components/common/Backdrop';
import Spinner from './components/common/Spinner';

// Route helpers
import LoggedInRoute from './components/routes/LoggedInRoute';
import LoggedOutRoute from './components/routes/LoggedOutRoute';
import AdminRoute from './components/routes/AdminRoute';

// Pages for router
import Home from './components/pages/Home';
import PageNotFound from './components/pages/PageNotFound';
import LoginPage from './components/pages/LoginPage';
import LicenseeListPage from './components/pages/LicenseeListPage';
import RegisterUserPage from './components/pages/RegisterUserPage';
import ProfilePage from './components/pages/ProfilePage';
import ChangePasswdPage from './components/pages/ChangePasswdPage';

// Admin Pages
import UserListPage from './components/pages/admin/Users';
import PlanListPage from './components/pages/admin/Plans';
import LicenceKeyListPage from './components/pages/admin/LicenceKey';
import GameListPage from './components/pages/admin/Games';
import RatesListPage from './components/pages/admin/Rates';
import ProductListPage from './components/pages/admin/Products';

// Licensee Pages
import LicenseeUsersPage from './components/pages/licensee/UserAndInvitations';
import LicenseeGamesPage from './components/pages/licensee/Games';
import LicenceKeysPage from './components/pages/licensee/LicenceKeys';
import BillingPage from './components/pages/licensee/Billing';
import LicenseeInfoPage from './components/pages/licensee/LicenseeInfo';
import LicenseeDownloadPage from './components/pages/licensee/LicenseeDownloadPage';
import InvoicesPage from './components/pages/licensee/Invoices';

// Other
import FaqPage from './components/pages/Faq';

// CSS
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'assets/css/incarna.css';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const AppRouter: React.FC = () => (
  <Switch>
    <Route component={Home} exact path="/" />
    <LoggedOutRoute component={LoginPage} path="/login" />
    <LoggedOutRoute component={RegisterUserPage} exact path="/register" />
    <LoggedOutRoute component={RegisterUserPage} exact path="/register/:email" />
    <LoggedOutRoute
      component={ChangePasswdPage}
      childProps={{ needsToken: true }}
      exact
      path="/reset-password/:token"
    />
    {/* AUTH USER ONLY ROUTES */}
    <LoggedInRoute component={ProfilePage} path="/profile" />
    <LoggedInRoute component={ChangePasswdPage} path="/change-passwd/:id" />
    <LoggedInRoute component={ChangePasswdPage} path="/change-passwd" />

    {/* Licensee Routes */}
    <LoggedInRoute component={LicenseeListPage} path="/licensees" />
    <LoggedInRoute component={LicenseeUsersPage} exact path="/licensee/:id/users" />
    <LoggedInRoute component={LicenseeGamesPage} exact path="/licensee/:id/games" />
    <LoggedInRoute component={LicenceKeysPage} exact path="/licensee/:id/keys" />
    <LoggedInRoute component={BillingPage} exact path="/licensee/:id/billing" />
    <LoggedInRoute component={InvoicesPage} exact path="/licensee/:id/invoices" />
    <LoggedInRoute component={LicenseeInfoPage} exact path="/licensee/:id" />
    <LoggedInRoute component={LicenseeDownloadPage} exact path="/licensee/:id/download" />
    {/* Other routes */}
    <LoggedInRoute component={FaqPage} exact path="/faq" />
    {/* ADMIN ONLY ROUTES */}
    <AdminRoute component={UserListPage} path="/admin/users" />
    <AdminRoute component={PlanListPage} path="/admin/plans" />
    <AdminRoute component={GameListPage} exact path="/admin/games" />
    <AdminRoute component={LicenceKeyListPage} exact path="/admin/licencekeys" />
    <AdminRoute component={RatesListPage} exact path="/admin/rates" />
    <AdminRoute component={ProductListPage} exact path="/admin/products" />
    {/* DEFAULT PAGE */}
    <Route component={PageNotFound} />
  </Switch>
);

const App: React.FC = () => {
  const checking = useSelector((state: RootState) => state.authenticationReducer.checking);
  const apiCallsInProgress = useSelector((state: RootState) => state.apiReducer.apiCallsInProgress);
  const rates = useSelector((state: RootState) => state.stripeReducer.rates);
  const loggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const checkSession = useCallback(async () => {
    setIsLoading(true);
    await dispatch(checkSessionAction());
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    if (!rates && loggedIn) {
      dispatch(getVTARatesAction());
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, loggedIn, rates]);

  // TODO: Look how tu plug Crisp with TS properperly
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.$crisp = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CRISP_WEBSITE_ID = 'f39ed2d9-8657-49d8-8098-6a98a5ae3a0b';
    (function loadCrisp() {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  if (checking || isLoading) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Backdrop />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
  return (
    <Router>
      {apiCallsInProgress ? <Spinner /> : ''}
      <LayoutHandler appRouter={<AppRouter />} />
    </Router>
  );
};

export default App;
