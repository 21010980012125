import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
// ## //
import { PlanType } from 'types';
import TextLabels from '../datatables/datatables-messages';
import { amountFormatter } from '../datatables/Formatters';
import { CustomMUIDataTableColumn } from '../datatables/ListTypes';

const messages = defineMessages({
  name: {
    defaultMessage: 'Name',
    id: 'plan.name',
  },
  frequency: {
    defaultMessage: 'Frequency',
    id: 'plan.frequency',
  },
  royaltyFee: {
    defaultMessage: 'Fee per player',
    id: 'plan.feeperplayer',
  },
  royaltyCurrency: {
    defaultMessage: 'Currency',
    id: 'plan.feecurrency',
  },
});

export interface PlanListProps {
  plans: PlanType[];
  handleRowSelect: (row: string) => void;
}

const PlansList: React.FC<PlanListProps> = ({ plans, handleRowSelect }) => {
  const intl = useIntl();
  const columns: CustomMUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: intl.formatMessage(messages.name),
    },
    {
      name: 'interval',
      label: intl.formatMessage(messages.frequency),
      options: {
        customBodyRender: (cell, tableMeta) => ` ${tableMeta.rowData[3]} ${cell}`,
      },
    },
    {
      name: 'interval_count',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'royaltyFee',
      label: intl.formatMessage(messages.royaltyFee),
      options: {
        customBodyRender: (cell, tableMeta) => amountFormatter(cell, tableMeta.rowData[5]),
      },
    },
    {
      name: 'royaltyCurrency',
      label: intl.formatMessage(messages.royaltyCurrency),
    },
    {
      name: 'stripeId',
      label: 'stripeId',
    },
  ];

  const options: MUIDataTableOptions = {
    tableId: 'PlansList',
    textLabels: TextLabels(intl),
    selectableRows: 'none',
    rowsPerPageOptions: [100, 150, 200],
    rowsPerPage: 100,
    onRowClick: (rowData) => {
      handleRowSelect(rowData[0]); // 0 is ID
    },
  };

  return <MUIDataTable title="" data={plans} options={options} columns={columns} />;
};

export default PlansList;
