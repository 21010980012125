import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

import useStyles from 'theme/InfoArea/styles/infoStyle';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

interface Props {
  description: string | React.ReactNode;
  title: string;
  iconColor?: 'primary' | 'warning' | 'danger' | 'success' | 'info' | 'rose' | 'gray' | 'white';
  vertical?: boolean;
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
}

const InfoArea: React.FC<Props> = ({ title, description, iconColor = 'gray', vertical, icon }) => {
  const Icon = icon;
  const classes = useStyles();
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical,
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  return (
    <div className={classes.infoArea}>
      <div className={iconWrapper}>
        <Icon className={iconClasses} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

InfoArea.defaultProps = {
  vertical: false,
  iconColor: 'gray',
};

export default InfoArea;
