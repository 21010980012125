import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl, defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';
// Material Ui
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
// ## //
import { contestGameAction, getGameEventsAction, validateContestGameAction } from 'action/GameAction';
import { GameType } from 'types';
import { RootState } from 'reducer/configureStore';
import { Timeline, Event } from '../common/timeline';
import DialogTitle from '../common/DialogTitle';
import GameContestForm from '../forms/GameContestForm';
import { RESET_GAME_EVENTS } from '../../reducer/selectedGameReducer';

const messages = defineMessages({
  title: {
    defaultMessage: 'Game Details',
    id: 'game.detail.title',
  },
  timeline: {
    defaultMessage: 'Timeline',
    id: 'game.detail.timeline',
  },
  formTitle: {
    defaultMessage: 'Contest Form',
    id: 'game.detail.formTitle',
  },
  // Actions
  cancel: {
    defaultMessage: 'Cancel',
    id: 'game.detail.action.cancel',
  },
  contest: {
    defaultMessage: 'Contest',
    id: 'game.detail.action.contest',
  },
  validateContest: {
    defaultMessage: 'Accept Contest & Cancel Game',
    id: 'game.detail.action.validatecontest',
  },
  inValidateContest: {
    defaultMessage: 'Reject Contest',
    id: 'game.detail.action.invalidatecontest',
  },
  cancelContest: {
    defaultMessage: 'Cancel Contest',
    id: 'game.detail.action.CancelContext',
  },
  // Status
  contested: {
    defaultMessage: 'Game is currently Contested for the following reason',
    id: 'game.detail.status.contested',
  },
  accepted: {
    defaultMessage: 'The game will not be invoiced',
    id: 'game.detail.status.accepted',
  },
});

interface Props {
  openedGame: GameType;
  onClose: () => void;
  licenseeId?: string;
}

const GameEventsDialog: React.FC<Props> = ({ openedGame, onClose, licenseeId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const events = useSelector((state: RootState) => state.selectedGameReducer.events);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    if (openedGame && openedGame._id) {
      dispatch(getGameEventsAction(openedGame._id));
    }
    return () => {
      setOpenForm(false);
      dispatch(RESET_GAME_EVENTS());
    };
  }, [dispatch, openedGame]);

  const saveReason = useCallback(
    async (data) => {
      await dispatch(contestGameAction({ gameId: openedGame._id, reason: data.reason, licenseeId }));
      toastr.success(
        '',
        intl.formatMessage({
          defaultMessage: 'Contestation saved. Incarna has been notified',
          id: 'contest.save.message',
        })
      );
      onClose();
    },
    [dispatch, intl, licenseeId, onClose, openedGame._id]
  );

  const validateContest = useCallback(
    async (doValidate) => {
      await dispatch(validateContestGameAction({ gameId: openedGame._id, doValidate, licenseeId }));
      toastr.success(
        '',
        intl.formatMessage({
          defaultMessage: 'Validation result saved',
          id: 'validatecontest.save.message',
        })
      );
      onClose();
    },
    [dispatch, intl, licenseeId, onClose, openedGame._id]
  );

  const cancelContest = useCallback(async () => {
    await dispatch(contestGameAction({ gameId: openedGame._id, reason: 'cancel', licenseeId, cancellation: true }));
    toastr.success(
      '',
      intl.formatMessage({
        defaultMessage: 'Validation result saved',
        id: 'validatecontest.save.message',
      })
    );
    onClose();
  }, [dispatch, intl, licenseeId, onClose, openedGame._id]);

  const RenderContest: React.FC = () => {
    switch (openedGame.contestStatus) {
      case 'accepted':
        return (
          <div>
            <Typography variant="h5">{intl.formatMessage(messages.accepted)}</Typography>
            <Typography variant="body1">{openedGame.contestReason}</Typography>
          </div>
        );
      case 'contested':
        return (
          <>
            <Typography variant="h5">{intl.formatMessage(messages.contested)}</Typography>
            <Typography variant="body1">{openedGame.contestReason}</Typography>
          </>
        );
      default:
        // not contested
        // Not contested Game -> Display form
        return (
          <>
            <Collapse in={openForm}>
              <Typography variant="h5">{intl.formatMessage(messages.formTitle)}</Typography>
              <GameContestForm handleSave={saveReason} />
            </Collapse>
          </>
        );
    }
  };

  const RenderActions: React.FC = () => {
    switch (openedGame.contestStatus) {
      case 'accepted':
        return (
          <Button autoFocus onClick={onClose} color="secondary">
            {intl.formatMessage(messages.cancel)}
          </Button>
        );
      case 'contested':
        return (
          <>
            <Button autoFocus onClick={onClose} color="secondary">
              {intl.formatMessage(messages.cancel)}
            </Button>
            {staff ? (
              <>
                <Button onClick={() => validateContest(true)} color="primary" variant="outlined">
                  {intl.formatMessage(messages.validateContest)}
                </Button>
                <Button onClick={() => validateContest(false)} color="primary" variant="outlined">
                  {intl.formatMessage(messages.inValidateContest)}
                </Button>
              </>
            ) : (
              <Button onClick={() => cancelContest()} color="primary" variant="outlined">
                {intl.formatMessage(messages.cancelContest)}
              </Button>
            )}
          </>
        );
      default:
        // Not contested
        return (
          <>
            <Button autoFocus onClick={onClose} color="secondary">
              {intl.formatMessage(messages.cancel)}
            </Button>
            {!openForm && (
              <Button onClick={() => setOpenForm(true)} color="primary" variant="outlined">
                {intl.formatMessage(messages.contest)}
              </Button>
            )}
          </>
        );
    }
  };

  return (
    <Dialog onClose={onClose} open={!!openedGame} fullWidth>
      <DialogTitle onClose={onClose}>{intl.formatMessage(messages.title)}</DialogTitle>
      <DialogContent>
        <Typography variant="h5">{intl.formatMessage(messages.timeline)}</Typography>
        <Timeline>
          {events.map((event) => (
            <Event
              key={event._id}
              // This is to convert seconds to hh:mm:ss
              interval={moment.utc(1000 * event.time).format('H:mm:ss')}
            >
              {event.event}
            </Event>
          ))}
        </Timeline>
        <RenderContest />
      </DialogContent>
      <DialogActions>
        <RenderActions />
      </DialogActions>
    </Dialog>
  );
};

GameEventsDialog.defaultProps = {
  licenseeId: undefined,
};

export default GameEventsDialog;
