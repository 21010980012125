import { PlanType } from 'types';
import axios from './ApiConfig';

class PlanApi {
  static getAllPlans(): Promise<PlanType[]> {
    return axios.get('/api/plan').then((response) => response.data);
  }

  static savePlan(param: Partial<PlanType>): Promise<any> {
    const plan = { ...param }; // to avoid manipulating object passed in.
    return axios.post('/api/plan', { plan }).then((response) => response.data);
  }

  static getPlan(planId: string): Promise<PlanType> {
    return axios.get(`/api/plan/${planId}`).then((response) => response.data);
  }

  static deletePlan(planId: string): Promise<any> {
    return axios
      .delete('/api/plan', {
        data: {
          planId,
        },
      })
      .then((response) => response.data);
  }
}

export default PlanApi;
