import React from 'react';
import { FormattedMessage } from 'react-intl';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Theme
import Button from 'theme/CustomButtons/Button';

interface Props {
  onClick: () => void;
}

const ButtonNew: React.FC<Props> = ({ onClick }) => (
  <Button customColor="rose" round onClick={onClick}>
    <AddCircleIcon />
    <FormattedMessage defaultMessage="New" description="Button New" id="button.new" />
  </Button>
);

export default ButtonNew;
