import React from 'react';
import { FormattedMessage } from 'react-intl';
// Theme
import Button from 'theme/CustomButtons/Button';
// Icons
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const ButtonClear: React.FC<Props> = ({ onClick, disabled }) => (
  <Button round disabled={disabled} onClick={onClick} customColor="danger">
    <SettingsBackupRestoreIcon />
    <FormattedMessage defaultMessage="Clear Values" description="Button Clear" id="button.clear" />
  </Button>
);

ButtonClear.defaultProps = {
  disabled: false,
};

export default ButtonClear;
