import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';
// Theme
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
import Button from 'theme/CustomButtons/Button';
// Icons
import SyncIcon from '@mui/icons-material/Sync';
// ## //
import { syncGamesAction, getLicenseeGamesAction } from 'action/GameAction';
import { RootState } from 'reducer/configureStore';
import GamesList from '../../lists/GamesList';

const messages = defineMessages({
  syncDone: {
    defaultMessage: 'Games synchronized',
    id: 'action.syncgames.message',
  },
  title: {
    defaultMessage: 'Licensee Games',
    id: 'licensee.games.title',
  },
  syncButton: {
    defaultMessage: 'Sync Games',
    id: 'licensee.games.syncbutton',
  },
});

const LicenseeGamesContainer: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // Global States
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const licenseeGames = useSelector((state: RootState) => state.selectedLicenseeReducer.licenseeGames);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);

  useEffect(() => {
    if (licensee) {
      dispatch(getLicenseeGamesAction(licensee._id));
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, licensee]);

  const handleSync = useCallback(async () => {
    if (licensee) {
      await dispatch(syncGamesAction(licensee._id));
      toastr.success('', intl.formatMessage(messages.syncDone));
    } // TODO: Maybe toast an error in else Case
  }, [dispatch, intl, licensee]);

  if (licensee) {
    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem md={5}>
              <h1>{intl.formatMessage(messages.title)}</h1>
            </GridItem>
            <GridItem md={4}>
              {staff && handleSync && (
                <Button customColor="rose" round onClick={handleSync}>
                  <SyncIcon />
                  {intl.formatMessage(messages.syncButton)}
                </Button>
              )}
            </GridItem>
            <GridItem md={3} />
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GamesList games={licenseeGames} licenseeId={licensee._id} />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export default LicenseeGamesContainer;
