import React from 'react';
import { FormattedMessage } from 'react-intl';
// Theme
import Button from 'theme/CustomButtons/Button';
// Icon
import DeleteIcon from '@mui/icons-material/Delete';

export interface ButtonDeleteProps {
  onClick: () => void;
}

const ButtonDelete: React.FC<ButtonDeleteProps> = ({ onClick }) => (
  <Button onClick={onClick} customColor="danger">
    <DeleteIcon />
    <FormattedMessage defaultMessage="Delete" description="Button Delete" id="button.delete" />
  </Button>
);

export default ButtonDelete;
