import { createSlice } from '@reduxjs/toolkit';
import { PlanType } from 'types';

export interface PlansReducerDataType {
  plans: PlanType[]; // FIXME: type
}

const initialState: PlansReducerDataType = {
  plans: [],
};

const plansSlice = createSlice({
  name: 'plansReducer',
  initialState,
  reducers: {
    GET_PLANS_RESPONSE(state, action) {
      return {
        ...state,
        plans: action.payload,
      };
    },
  },
});

export const { GET_PLANS_RESPONSE } = plansSlice.actions;

export default plansSlice.reducer;
