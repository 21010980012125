import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
// Material Ui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// theme
import TextFieldInput from 'theme/CustomInput/CustomTextField';
// ## //
import { ButtonCancel, ButtonClear, ButtonSubmit } from '../common/Buttons';
import PasswordRule from '../common/PasswordRule';

const labels = defineMessages({
  oldpassword: {
    id: 'chgpasswd.form.oldpassword',
    description: 'Old password',
    defaultMessage: 'Old password',
  },
  password: {
    id: 'chgpasswd.form.password',
    description: 'New password',
    defaultMessage: 'New password',
  },
  confirmpassword: {
    id: 'chgpasswd.form.confirmpassword',
    description: 'new password confirmation',
    defaultMessage: 'Confirm new password',
  },
});

const errors = defineMessages({
  passwordrule: {
    defaultMessag:
      'Passwords must be at least 8 characters long with at least 1 number, 1 special and 1 capital letter',
    id: 'form.register.error.passwordrule',
  },
  passwordMatch: {
    defaultMessage: 'both password do not match',
    id: 'form.register.error.passwordmatch',
  },
});

interface Props {
  handleSave: (values: any) => void;
  handleCancel: (event: any) => void;
  mustProvideOldPassword?: boolean;
  initialValues?: any;
}

const ChangePasswdForm: React.FC<Props> = ({ mustProvideOldPassword, handleSave, handleCancel }) => {
  const methods = useForm({ mode: 'onBlur' });
  const intl = useIntl();
  const { handleSubmit, formState, reset, watch } = methods;
  const { isDirty, isSubmitting } = formState;
  const password = useRef({});
  password.current = watch('password', '');
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardHeader
            title={<FormattedMessage defaultMessage="Please input your new password" id="chgpasswd.form.title" />}
          />
          <CardContent>
            {mustProvideOldPassword && (
              <TextFieldInput
                label={intl.formatMessage(labels.oldpassword)}
                name="oldpassword"
                placeholder={intl.formatMessage(labels.oldpassword)}
                type="password"
                fullWidth
              />
            )}
            <TextFieldInput
              label={intl.formatMessage(labels.password)}
              name="password"
              type="password"
              placeholder={intl.formatMessage(labels.password)}
              fullWidth
              validators={{
                validate: (value) => PasswordRule(value) || intl.formatMessage(errors.passwordrule),
              }}
            />
            <TextFieldInput
              label={intl.formatMessage(labels.confirmpassword)}
              name="confirmPassword"
              placeholder={intl.formatMessage(labels.confirmpassword)}
              type="password"
              fullWidth
              validators={{
                validate: (value) => value === password.current || intl.formatMessage(errors.passwordMatch),
              }}
            />
            <CardActions>
              <ButtonSubmit disabled={isSubmitting || !isDirty} />
              <ButtonClear disabled={!isDirty} onClick={() => reset()} />
              <ButtonCancel onClick={handleCancel} />
            </CardActions>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};

ChangePasswdForm.defaultProps = {
  mustProvideOldPassword: false,
  initialValues: {},
};

export default ChangePasswdForm;
