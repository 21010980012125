import React from 'react';
// Material Ui
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
// ## //
import { CustomerType, SourceType } from 'types';
import CardInfo from './CardInfo';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

interface Props {
  customer: CustomerType;
  deleteCard: (source: SourceType) => void;
  makeDefault: (source: SourceType) => void;
}

const SourcesList: React.FC<Props> = ({ customer, deleteCard, makeDefault }) => {
  const { sources } = customer;

  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {sources.map((source) => (
            <CardInfo deleteCard={deleteCard} key={source.id} makeDefault={makeDefault} source={source} />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default SourcesList;
