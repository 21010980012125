import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { saveLicenseeAction, getLicenseeAction } from 'action/LicenseeAction';
import { getPlansAction } from 'action/PlanAction';
import { LicenseeType } from 'types';
import { RootState } from 'reducer/configureStore';
import LicenseeForm, { LicenseeFormValues } from '../forms/LicenseeForm';
import { FormatObjectListForDropdown } from '../../selectors/selectors';

interface Props {
  licensee?: LicenseeType;
  doneAction: () => void;
}

const AddOrEditLicenseeContainer: React.FC<Props> = ({ licensee, doneAction }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // Global State
  const plans = useSelector((state: RootState) => FormatObjectListForDropdown(state.plansReducer.plans));
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const rates = useSelector((state: RootState) => state.stripeReducer.rates);

  useEffect(() => {
    dispatch(getPlansAction());
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch]);

  const handleSave = useCallback(
    async (values: LicenseeFormValues) => {
      const licenseeToSave = {
        _id: licensee && licensee._id,
        name: values.name,
        exploitationAddress: values.exploitationAddress,
        address: {
          ...values.address,
          country: (values.country && values.country.code) || undefined,
        },
        status: values.status,
        plan: values.plan,
        vat: values.vat,
        stripe: { rateId: values.stripe.rateId },
      };
      await dispatch(saveLicenseeAction(licenseeToSave));
      if (licensee?._id) {
        // Refresh licensee if existing
        dispatch(getLicenseeAction(licensee._id));
      }
      toastr.success('', intl.formatMessage({ defaultMessage: 'Licensee saved', id: 'action.savelicensee.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, licensee]
  );

  return (
    <div className="container">
      <LicenseeForm
        handleCancel={doneAction}
        handleSave={handleSave}
        initialValues={licensee}
        plans={plans || []}
        staff={staff || false}
        rates={rates || []}
      />
    </div>
  );
};

AddOrEditLicenseeContainer.defaultProps = {
  licensee: undefined,
};

export default AddOrEditLicenseeContainer;
