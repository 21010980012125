import { defineMessages } from 'react-intl';

const messages = defineMessages({
  LICENSEE_OR_SOURCE_NOT_FOUND: {
    defaultMessage: 'Licensee or Source not found',
    id: 'ERROR.LICENSEE_OR_SOURCE_NOT_FOUND',
  },
  UNABLE_TO_GET_LICENSEE: {
    defaultMessage: 'Unable to get licensee',
    id: 'ERROR.UNABLE_TO_GET_LICENSEE',
  },
  UNABLE_TO_UPDATE_LICENSEE: {
    defaultMessage: 'Unable to update licensee',
    id: 'ERROR.UNABLE_TO_UPDATE_LICENSEE',
  },
  UNABLE_TO_CREATE_LICENSEE: {
    defaultMessage: 'Unable to create licensee',
    id: 'ERROR.UNABLE_TO_CREATE_LICENSEE',
  },
  INVALID_CONTEST: {
    defaultMessage: 'Invalid Contest',
    id: 'ERROR.INVALID_CONTEST',
  },
  GAME_CONTEST_FAILED_UKNOWN_GAME: {
    defaultMessage: 'Game Contest Failed: Uknown Game',
    id: 'ERROR.GAME_CONTEST_FAILED_UKNOWN_GAME',
  },
  INVALID_CONTEST_VALIDATION: {
    defaultMessage: 'Invalid contest validation',
    id: 'ERROR.INVALID_CONTEST_VALIDATION',
  },
  NO_SUCH_LICENCE_KEY: {
    defaultMessage: 'No such Licence Key',
    id: 'ERROR.NO_SUCH_LICENCE_KEY',
  },
  INVALID_LICENCE_KEY_POST: {
    defaultMessage: 'Invalid licence key create/edit request',
    id: 'ERROR.INVALID_LICENCE_KEY_POST',
  },
  UNABLE_TO_DELETE_KEY: {
    defaultMessage: 'Unable to delete licence key',
    id: 'ERROR.UNABLE_TO_DELETE_KEY',
  },
  UNABLE_TO_GET_KEYS: {
    defaultMessage: 'Unable to retrieve Keys',
    id: 'ERROR.UNABLE_TO_GET_KEYS',
  },
  UNABLE_TO_GET_KEY: {
    defaultMessage: 'Unable to retrieve Key',
    id: 'ERROR.UNABLE_TO_GET_KEY',
  },
  UNABLE_TO_RETRIEVE_GAMES_FOR_KEY: {
    defaultMessage: 'Unable to retrieve Games for this key',
    id: 'ERROR.UNABLE_TO_RETRIEVE_GAMES_FOR_KEY',
  },
  INVALID_LICENCE_KEY_STATUS: {
    defaultMessage: 'Invalid licenceKey Status request',
    id: 'ERROR.INVALID_LICENCE_KEY_STATUS',
  },
  INVALID_LOCALE: {
    defaultMessage: 'Invalid Locale',
    id: 'ERROR.INVALID_LOCALE',
  },
  UNABLE_TO_GET_PLANS: {
    defaultMessage: 'Unable to retrieve Plans',
    id: 'ERROR.UNABLE_TO_GET_PLANS',
  },
  UNABLE_TO_GET_PLAN: {
    defaultMessage: 'Unable to find requested Plan',
    id: 'ERROR.UNABLE_TO_GET_PLAN',
  },
  UNABLE_TO_SYNC_PLANS: {
    defaultMessage: 'Unable to sync Plans',
    id: 'ERROR.UNABLE_TO_SYNC_PLANS',
  },
  INVALID_PLAN_ID: {
    defaultMessage: 'Invalid Plan Id',
    id: 'ERROR.INVALID_PLAN_ID',
  },
  UNABLE_TO_UPDATE_PLAN: {
    defaultMessage: 'Unable to update the requested Plan',
    id: 'ERROR.UNABLE_TO_UPDATE_PLAN',
  },
  UNABLE_TO_CREATE_PLAN: {
    defaultMessage: 'Unable to create the requested Plan',
    id: 'ERROR.UNABLE_TO_CREATE_PLAN',
  },
  UNABLE_TO_DELETE_PLAN: {
    defaultMessage: 'Unable to delete requested Plan',
    id: 'ERROR.UNABLE_TO_DELETE_PLAN',
  },
  UNABLE_TO_GET_USER: {
    defaultMessage: 'Unable to find requested user',
    id: 'ERROR.UNABLE_TO_GET_USER',
  },
  UNABLE_TO_SEARCH_USER: {
    defaultMessage: 'Unable to search requested user',
    id: 'ERROR.UNABLE_TO_SEARCH_USER',
  },
  UNABLE_TO_UPDATE_USER: {
    defaultMessage: 'Unable to update requested user',
    id: 'ERROR.UNABLE_TO_UPDATE_USER',
  },
  UNABLE_TO_CREATE_USER: {
    defaultMessage: 'Unable to create requested users',
    id: 'ERROR.UNABLE_TO_CREATE_USER',
  },
  UNABLE_TO_DELETE_USER: {
    defaultMessage: 'Unable to delete requested user',
    id: 'ERROR.UNABLE_TO_DELETE_USER',
  },
  NO_SUCH_USER: {
    defaultMessage: 'No such User',
    id: 'ERROR.NO_SUCH_USER',
  },
  INVALID_USER: {
    defaultMessage: 'Invalid User Id',
    id: 'ERROR.INVALID_USER',
  },
  UNABLE_TO_GET_CUSTOMER: {
    defaultMessage: 'Unable to fetch customer',
    id: 'ERROR.UNABLE_TO_GET_CUSTOMER',
  },
  UNABLE_TO_SUBSCRIBE: {
    defaultMessage: 'Unable to subscribe',
    id: 'ERROR.UNABLE_TO_SUBSCRIBE',
  },
  UNABLE_TO_UNSUBSCRIBE: {
    defaultMessage: 'Unable to unsubscribe',
    id: 'ERROR.UNABLE_TO_UNSUBSCRIBE',
  },
  UKNOWN_ACTION: {
    defaultMessage: 'Unknown action',
    id: 'ERROR.UKNOWN_ACTION',
  },
  LICENSEE_HAS_NO_PLAN: {
    defaultMessage: "Licensee doesn't have a plan",
    id: 'ERROR.LICENSEE_HAS_NO_PLAN',
  },
  LICENSEE_HAS_NO_SUB: {
    defaultMessage: 'Licensee must have a subscription',
    id: 'ERROR.LICENSEE_HAS_NO_SUB',
  },
  AWS_ERROR: {
    defaultMessage: 'AWS ERROR',
    id: 'ERROR.AWS_ERROR',
  },
  UNABLE_TO_GET_GAME: {
    defaultMessage: 'Unable to find game',
    id: 'ERROR.UNABLE_TO_GET_GAME',
  },
  UNABLE_TO_GET_GAMES: {
    defaultMessage: 'Unable to fetch games',
    id: 'ERROR.UNABLE_TO_GET_GAMES',
  },
  UNABLE_TO_SYNC_GAMES: {
    defaultMessage: 'Unable to sync games',
    id: 'ERROR.UNABLE_TO_SYNC_GAMES',
  },
  UNABLE_TO_GET_INVITATIONS: {
    defaultMessage: 'Unable to fetch invitations',
    id: 'ERROR.UNABLE_TO_GET_INVITATIONS',
  },
  UNABLE_TO_CREATE_INVITATIONS: {
    defaultMessage: 'Unable to create invitations',
    id: 'ERROR.UNABLE_TO_CREATE_INVITATIONS',
  },
  UNABLE_TO_DELETE_INVITATION: {
    defaultMessage: 'Unable to delete invitation',
    id: 'ERROR.UNABLE_TO_DELETE_INVITATION',
  },
  UNABLE_TO_UPDATE_LICENSEE_USERS: {
    defaultMessage: 'Unable to update licensee users',
    id: 'ERROR.UNABLE_TO_UPDATE_LICENSEE_USERS',
  },
  ADRESS_ALREADY_IN_USE: {
    defaultMessage: 'Cette adresse email est déjà utilisée',
    id: 'ERROR.ADRESS_ALREADY_IN_USE',
  },
  NO_HASH_GENERATED: {
    defaultMessage: 'No hash was generated',
    id: 'ERROR.NO_HASH_GENERATED',
  },
  INVALID_CREDENTIALS: {
    defaultMessage: 'invalid credentials',
    id: 'ERROR.INVALID_CREDENTIALS',
  },
  INACTIVE_USER: {
    defaultMessage: 'Account is disabled',
    id: 'ERROR.INACTIVE_USER',
  },
  INVALID_EMAIL: {
    defaultMessage: 'Invalid email',
    id: 'ERROR.INVALID_EMAIL',
  },
  UNABLE_TO_SAVE_CUSTOMER: {
    defaultMessage: 'Unable to save customer',
    id: 'ERROR.UNABLE_TO_SAVE_CUSTOMER',
  },
  // Std Errors
  ForbiddenError: {
    defaultMessage: 'Forbidden action',
    id: 'ERROR.ForbiddenError',
  },
  UnauthorizedError: {
    defaultMessage: 'Unauthorized action',
    id: 'ERROR.UnauthorizedError',
  },
  NotFoundError: {
    defaultMessage: 'Not Found',
    id: 'ERROR.NotFoundError',
  },
  // Frontend Only
  NoBackendError: {
    defaultMessage: 'Server is unavailable, please try again later',
    id: 'ERROR.NoBackendError',
  },
  // GENERIC
  GENERIC_ERR: {
    defaultMessage: 'Unknown error has happenned. Try refreshing the page.',
    id: 'ERROR.GENERIC_ERR',
  },
});

export default messages;
