import { LocaleType, UserType } from 'types';
import axios from './ApiConfig';

interface LoginParam {
  username: string;
  password: string;
}

class UserApi {
  static getAllUsers(): Promise<UserType[]> {
    return axios.get('/api/user').then((response) => response.data);
  }

  static getUser(UserId: string): Promise<UserType> {
    return axios.get(`/api/user/${UserId}`).then((response) => response.data);
  }

  static searchUser(searchItem: string): Promise<UserType> {
    return axios.get(`/api/user/search/${searchItem}`).then((response) => response.data);
  }

  static deleteUser(userId: string): Promise<boolean> {
    return axios
      .delete('/api/user', {
        data: {
          userId,
        },
      })
      .then((response) => response.status === 200);
  }

  static saveUser(param: Partial<UserType>): Promise<void> {
    const user = { ...param }; // to avoid manipulating object passed in.
    return axios.post('/api/user', { user }).then((response) => response.data);
  }

  static registerUser(param: UserType): Promise<void> {
    const user = { ...param }; // to avoid manipulating object passed in.
    return axios.post('/auth/register', { user }).then((response) => response.data);
  }

  static loginUser(user: LoginParam): Promise<{ user: UserType }> {
    return axios
      .post('/auth/login', { email: user.username, password: user.password })
      .then((response) => response.data);
  }

  static logoutUser(): Promise<void> {
    return axios.post('/auth/logout', {}).then((response) => response.data);
  }

  static checkSession(): Promise<{ user: UserType | undefined; loggedIn: boolean }> {
    return axios.post('/auth/session', {}).then((response) => response.data);
  }

  static sendResetLink(email: string): Promise<void> {
    return axios.post('/auth/reset', { email }).then((response) => response.data);
  }

  static changepasswd(values: {
    confirmPassword: string;
    oldpassword: string;
    password: string;
  }): Promise<{ changed: boolean; error?: string }> {
    return axios.post('/auth/changepasswd', values).then((response) => response.data);
  }

  static resetpasswd(values: any): Promise<{ changed: boolean; error?: string }> {
    return axios.post('/auth/resetpassword', values).then((response) => response.data);
  }

  static disableUsers(ids: string[]): Promise<any> {
    return axios.post('/api/user/status', { ids, active: false }).then((response) => response.data);
  }

  static enableUsers(ids: string[]): Promise<any> {
    return axios.post('/api/user/status', { ids, active: true }).then((response) => response.data);
  }

  static setLocale(locale: LocaleType): Promise<any> {
    return axios.post('/api/locale', { locale }).then((response) => response.data);
  }
}

export default UserApi;
