import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { saveLicenseeCustomerAction } from 'action/PaymentAction';
import { CustomerType } from 'types';
import CustomerForm from '../forms/CustomerForm';

interface Props {
  licenseeId: string;
  customer: CustomerType;
  doneAction: () => void;
}

const EditCustomerContainer: React.FC<Props> = ({ licenseeId, customer, doneAction }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    // Disable ESLINT here for camelcase as fields are the "Stripe" ones (3 times)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    preferred_locales,
    name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tax_exempt,
    description,
    email,
    address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_settings,
  } = customer;

  const handleSave = useCallback(
    async (values) => {
      const customerToSave: Partial<CustomerType> = {
        tax_exempt: values.tax_exempt,
        preferred_locales: [values.language],
        name: values.name,
        description: values.description,
        email: values.email,
        invoice_settings: {
          footer: values.footer,
        },
        address: {
          city: values.city,
          postal_code: values.postal_code,
          line1: values.line1,
        },
      };
      await dispatch(saveLicenseeCustomerAction({ licenseeId, customer: customerToSave }));
      toastr.success('', intl.formatMessage({ defaultMessage: 'Licensee saved', id: 'action.savelicensee.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, licenseeId]
  );

  return (
    <div className="container">
      <CustomerForm
        handleCancel={doneAction}
        handleSave={handleSave}
        initialValues={{
          name,
          language: preferred_locales[0],
          description,
          tax_exempt,
          email,
          line1: address?.line1,
          city: address?.city,
          postal_code: address?.postal_code,
          footer: invoice_settings.footer,
        }}
      />
    </div>
  );
};

export default EditCustomerContainer;
