import React from 'react';
import _ from 'lodash';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import useStyles from './styles/CustomTextFieldStyles';

const labels = defineMessages({
  required: {
    id: 'form.error.required',
    defaultMessage: 'Required',
  },
  email: {
    id: 'form.error.email',
    defaultMessage: 'Invalid Email Adress',
  },
});

const emailValidators = (intl: IntlShape, optional: boolean): RegisterOptions => {
  if (optional) {
    return {};
  }
  return {
    required: intl.formatMessage(labels.required),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: intl.formatMessage(labels.email),
    },
  };
};
const textValidator = (intl: IntlShape, optional: boolean): RegisterOptions => {
  if (optional) {
    return {};
  }
  return {
    required: intl.formatMessage(labels.required),
  };
};

interface Props {
  optional?: boolean;
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  validators?: RegisterOptions;
  rows?: number;
}

const TextFieldInput: React.FC<Props> = ({
  type,
  name,
  label,
  placeholder,
  readOnly,
  fullWidth,
  validators,
  optional = false,
  rows = 1,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { register, errors, formState } = useFormContext();
  const {
    touched,
    /* isDirty, isSubmitting, , submitCount, dirtyFields, */
  } = formState;
  const error = _.get(errors, name);
  const isTouched = _.get(touched, name);
  return (
    <TextField
      inputRef={register(
        type === 'email' ? emailValidators(intl, optional) : validators || textValidator(intl, optional)
      )}
      className={classes.margin}
      id={name}
      name={name}
      rows={rows}
      fullWidth={fullWidth}
      multiline={rows > 1}
      type={type}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      disabled={readOnly}
      size="small"
      error={isTouched && !!error}
      helperText={error ? error.message : ''}
    />
  );
};

TextFieldInput.defaultProps = {
  placeholder: undefined,
  readOnly: false,
  fullWidth: false,
  validators: undefined,
  optional: false,
  rows: 1,
};

export default TextFieldInput;
