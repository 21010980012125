import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
// MaterialUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
// Icons
import Info from '@mui/icons-material/Info';
import Group from '@mui/icons-material/Group';
import Payment from '@mui/icons-material/Payment';
import VpnKey from '@mui/icons-material/VpnKey';
import SportsEsports from '@mui/icons-material/SportsEsports';
import CloudDownload from '@mui/icons-material/CloudDownload';
import ReceiptIcon from '@mui/icons-material/Receipt';
// ## //
import { getLicenseeAction } from 'action/LicenseeAction';
import { RoleType } from 'types';
import { RootState } from 'reducer/configureStore';

const messages = defineMessages({
  info: {
    defaultMessage: 'Info',
    id: 'licensee.menu.info',
  },
  users: {
    id: 'licensee.menu.users',
    defaultMessage: 'Users',
  },
  billing: {
    id: 'licensee.menu.billing',
    defaultMessage: 'Billing',
  },
  invoices: {
    id: 'licensee.menu.invoices',
    defaultMessage: 'Invoices',
  },
  serverkeys: {
    id: 'licensee.menu.serverkeys',
    defaultMessage: 'Server Keys',
  },
  games: {
    id: 'licensee.menu.games',
    defaultMessage: 'Games',
  },
  download: {
    id: 'licensee.menu.download',
    defaultMessage: 'Download',
  },
});

interface LicenseeRoute {
  path: string;
  title: keyof typeof messages;
  icon: JSX.Element;
  access: RoleType;
}

const routes: LicenseeRoute[] = [
  {
    path: '/licensee/:id',
    title: 'info',
    icon: <Info fontSize="small" />,
    access: 'staff',
  },
  {
    path: '/licensee/:id/billing',
    title: 'billing',
    icon: <Payment fontSize="small" />,
    access: 'owner',
  },
  {
    path: '/licensee/:id/invoices',
    title: 'invoices',
    icon: <ReceiptIcon fontSize="small" />,
    access: 'owner',
  },
  {
    path: '/licensee/:id/keys',
    title: 'serverkeys',
    icon: <VpnKey fontSize="small" />,
    access: 'staff',
  },
  {
    path: '/licensee/:id/games',
    title: 'games',
    icon: <SportsEsports fontSize="small" />,
    access: 'staff',
  },
  {
    path: '/licensee/:id/users',
    title: 'users',
    icon: <Group fontSize="small" />,
    access: 'owner',
  },
  {
    path: '/licensee/:id/download',
    title: 'download',
    icon: <CloudDownload fontSize="small" />,
    access: 'staff',
  },
];

interface Props {
  id: string;
}

const LicenseeMenu: React.FC<Props> = ({ id }) => {
  const { pathname } = useLocation();
  const intl = useIntl();

  // Get Data
  // const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLicenseeAction(id));
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, id]);

  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const role = useSelector((state: RootState) => state.authenticationReducer.licenseePermission);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff || false);

  const match = (route: string) => route === pathname;

  if (licensee) {
    return (
      <List>
        {routes.map((route) => {
          const path = route.path.replace(':id', licensee._id);
          if (staff || role === route.access || role === 'owner') {
            return (
              <ListItem key={route.title} selected={match(path)} component={Link} button to={path}>
                <ListItemIcon>
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage(messages[route.title])} />
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );
  }
  return null;
};

export default LicenseeMenu;
