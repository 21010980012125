import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl, defineMessages } from 'react-intl';
// Material Ui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Theme
import Button from 'theme/CustomButtons/Button';
import Card from 'theme/Card/Card';
import CardBody from 'theme/Card/CardBody';
import CardHeader from 'theme/Card/CardHeader';
import GridContainer from 'theme/Grid/GridContainer';
import GridItem from 'theme/Grid/GridItem';
// ## //
import {
  getInvitationsAction,
  addInvitationsAction,
  deleteInvitationsAction,
  unAssignUsersAction,
  saveLicenseeAction,
  editInvitationsAction,
  getLicenseeAction,
} from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';
import InvitationForm from '../../forms/InvitationForm';
import LicenseeUsers from '../../lists/LicenseeUsersList';
import InvitationList from '../../lists/InvitationList';

// Intl Messages
const messages = defineMessages({
  assigned: {
    defaultMessage: 'User Assigned',
    id: 'assignuser.message',
  },
  unAssigned: {
    defaultMessage: 'User Unassigned',
    id: 'unassignuser.message',
  },
  invited: {
    defaultMessage: 'User invited',
    id: 'inviteuser.message',
  },
  edited: {
    defaultMessage: 'Role changed',
    id: 'editinvite.message',
  },
  unInvited: {
    defaultMessage: 'User Uninvited',
    id: 'uninviteuser.message',
  },
  changedRole: {
    defaultMessage: 'Role sucessfully changed',
    id: 'rolechange.message',
  },
  invitationTitle: {
    defaultMessage: 'Invitations',
    id: 'invitation.list.title',
  },
  usersTitle: {
    defaultMessage: 'Licensee users',
    id: 'licenseeusers.list.title',
  },
  inviteButton: {
    defaultMessage: 'Invite User',
    id: 'invitation.list.invitebutton',
  },
});

// Render function
const LicenseeUsersContainer: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // Local State
  const [showInviteModal, setShowInviteModal] = useState(false);
  // Global States
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const role = useSelector((state: RootState) => state.authenticationReducer.licenseePermission);
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const licenseeInvitations = useSelector((state: RootState) => state.selectedLicenseeReducer.licenseeInvitations);

  useEffect(() => {
    if (licensee) {
      dispatch(getInvitationsAction(licensee._id));
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, licensee]);

  // User Invite & Remove Handlers
  const handleInvite = useCallback(
    async (emails) => {
      if (licensee) {
        await dispatch(addInvitationsAction({ licenseeId: licensee._id, emails }));
        toastr.success('', intl.formatMessage(messages.invited));
      }
    },
    [dispatch, intl, licensee]
  );
  const handleUnInvite = useCallback(
    async (value) => {
      if (licensee) {
        await dispatch(deleteInvitationsAction({ licenseeId: licensee._id, invitation: value }));
        toastr.success('', intl.formatMessage(messages.unInvited));
      }
    },
    [dispatch, intl, licensee]
  );

  const actionInvite = useCallback(
    async (data) => {
      const emails = data.emails.split('\n');
      await handleInvite(emails);
      setShowInviteModal(false);
    },
    [handleInvite]
  );
  const changeInviteRole = useCallback(
    async (invitation) => {
      if (licensee) {
        await dispatch(editInvitationsAction({ licenseeId: licensee._id, invitation }));
        toastr.success('', intl.formatMessage(messages.edited));
      }
    },
    [dispatch, intl, licensee]
  );

  // User Add & Remove Handlers
  const handleRemoveUser = useCallback(
    async (user) => {
      if (licensee) {
        await dispatch(unAssignUsersAction({ users: [{ ...user }], licensee }));
        toastr.success('', intl.formatMessage(messages.unAssigned));
      }
    },
    [dispatch, intl, licensee]
  );
  const changeUserRole = useCallback(
    async (user) => {
      if (licensee) {
        const newLicensee = { ...licensee }; // Just need the ID
        newLicensee.users = licensee.users.map((u) => {
          if (u.user._id === user._id) {
            const newUser = { ...u }; // Immutable User ... TODO: Implement backend overwrite logic
            newUser.role = user.role;
            return newUser;
          }
          return u;
        });
        await dispatch(saveLicenseeAction(newLicensee));
        await dispatch(getLicenseeAction(licensee._id)); // Refresh Licensee
        toastr.success('', intl.formatMessage(messages.changedRole));
      }
    },
    [dispatch, intl, licensee]
  );

  const allowActions = role === 'owner' || staff;
  if (licensee) {
    return (
      <>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem md={6}>
                <h1>{intl.formatMessage(messages.usersTitle)}</h1>
              </GridItem>
              {allowActions && (
                <GridItem md={2}>
                  <Button round customColor="rose" onClick={() => setShowInviteModal(true)}>
                    <AddCircleIcon />
                    {intl.formatMessage(messages.inviteButton)}
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </CardHeader>
          <CardBody>
            <LicenseeUsers
              handleRemoveUser={handleRemoveUser}
              changeRole={changeUserRole}
              licenseeUsers={licensee.users}
              allowActions={allowActions}
            />
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader color="primary">
            <h1>{intl.formatMessage(messages.invitationTitle)}</h1>
          </CardHeader>
          <CardBody>
            <InvitationList
              handleUnInvite={handleUnInvite}
              changeRole={changeInviteRole}
              invitations={licenseeInvitations}
            />
          </CardBody>
        </Card>
        <Dialog onClose={() => setShowInviteModal(false)} open={!!showInviteModal}>
          <DialogContent>
            <InvitationForm handleCancel={() => setShowInviteModal(false)} handleSave={actionInvite} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return null;
};

export default LicenseeUsersContainer;
