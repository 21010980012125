import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import useStyles from './styles/cardStyle';

type Props = {
  className?: string;
  plain?: boolean;
  carousel?: boolean;
  children: React.ReactNode;
};

const Card: React.FC<Props> = ({ className, children, plain, carousel, ...rest }: Props) => {
  const classes = useStyles();
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
    [className || '']: className !== undefined,
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  plain: false,
  className: undefined,
  carousel: false,
};

export default Card;
