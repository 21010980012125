import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useAppDispatch } from 'reducer/configureStore';
import { defineMessages, useIntl } from 'react-intl';
// Material Ui
import Container from '@mui/material/Container';
// ## //
import * as userAction from 'action/UserAction';
import ChangePasswdForm from '../forms/ChangePasswdForm';

const messages = defineMessages({
  nomatch: {
    defaultMessage: 'Old password is invalid',
    id: 'action.changepasswd.invalid_old_password',
  },
  samepassword: {
    defaultMessage: 'Old password is identical to new password',
    id: 'action.changepasswd.same_password',
  },
  success: {
    defaultMessage: 'Password changed sucessfully Sucessfully',
    id: 'action.changepasswd.message',
  },
  invalidToken: {
    defaultMessage: 'Invalid token',
    id: 'action.changepasswd.invalid_token_error',
  },
  unknown: {
    defaultMessage: 'Unkown error',
    id: 'action.changepasswd.uknown_error',
  },
});

interface Props {
  needsToken?: boolean;
}

const ChangePasswdPage: React.FC<Props> = ({ needsToken }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { token } = useParams<{ token: string }>();

  const handleSave = useCallback(
    async (values) => {
      let data: any;
      if (needsToken) {
        data = await dispatch(userAction.changeUserPasswordWithToken({ token, password: values.password })).unwrap();
      } else {
        data = await dispatch(userAction.changeUserPassword(values)).unwrap();
      }

      console.log('GOT DATA:::', data);
      if (!data.changed) {
        // TODO: Must handle errors differently
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toastr.error('', intl.formatMessage(messages[data.error || 'unknown']));
      } else {
        toastr.success('', intl.formatMessage(messages.success));
        history.push('/');
      }
    },
    [dispatch, history, intl, needsToken, token]
  );

  const handleCancel = (event: any): void => {
    event.preventDefault();
    // FIXME: Is this really what we want to do ? this will potentially redirect to external pages
    history.goBack();
  };

  if (needsToken && !token) {
    history.push('/login');
  }
  return (
    <Container>
      <ChangePasswdForm handleCancel={handleCancel} handleSave={handleSave} mustProvideOldPassword={!needsToken} />
    </Container>
  );
};

ChangePasswdPage.defaultProps = {
  needsToken: false,
};

export default ChangePasswdPage;
