import React from 'react';
// @mui/material components
import Grid, { GridProps } from '@mui/material/Grid';
import useStyles from './styles/GridContainerStyles';

type Props = GridProps & {
  children: React.ReactNode;
  className?: string;
};

const GridContainer: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid container {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
};

GridContainer.defaultProps = {
  className: '',
};

export default GridContainer;
