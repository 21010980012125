import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// Material Ui
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// ## //
import { SET_REDIRECT_URL_RESPONSE } from 'reducer/authenticationReducer';
import { RootState, useAppDispatch } from 'reducer/configureStore';
import ReleaseNotes from '../prismic/ReleaseNotes';
import StaffDashboard from '../dashboard/elements/StaffDashboard';
import useStyles from './styles/HomeStyles';
import { useHistory } from 'react-router-dom';

const Home: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const staff = useSelector((state: RootState) => state.authenticationReducer.user?.staff);
  const loggedIn = useSelector((state: RootState) => state.authenticationReducer.loggedIn);
  const redirectUrl = useSelector((state: RootState) => state.authenticationReducer.redirectUrl);

  useEffect(() => {
    if (loggedIn && redirectUrl) {
      history.push(redirectUrl);
      dispatch(SET_REDIRECT_URL_RESPONSE(undefined));
    }
  }, [dispatch, history, loggedIn, redirectUrl]);

  if (loggedIn && staff) {
    return <StaffDashboard />;
  }
  return (
    <Grid container direction="row" justifyContent="center" alignContent="center">
      <Grid item xs={9}>
        <Paper className={classes.titleContainer}>
          <Typography variant="h1">INCARNA PORTAL</Typography>
          <Typography>
            <FormattedMessage
              defaultMessage="Incarna administration & licensing platform"
              description="Welcome title"
              id="header.title"
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <ReleaseNotes />
      </Grid>
    </Grid>
  );
};

export default Home;
