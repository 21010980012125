import { createTheme } from '@mui/material/styles';

const themeIncarna = {
  palette: {
    primary: {
      dark: '#002249',
      main: '#333',
      light: '#5b5b5b',
      contrastText: '#fafafa',
    },
  },
};

const theme = createTheme({
  ...themeIncarna,
  components: {
    // Background
    /*
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundImage: "url('/img/fond-decors-enchanteurs.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          height: '100%',
        },
      },
    },
    */
    // Cards
    MuiCard: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
      },
    },
    // Paper
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,0.87)',
        },
      },
    },
    // Table Cells
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '3px 6px',
        },
        body: {
          color: themeIncarna.palette.primary.dark,
        },
      },
    },
    /*
    MUIDataTableHeadCell: {
      fixedHeaderCommon: {
        backgroundColor: 'rgba(240,240,240,0.50)',
      },
    },
    */
    // Header
    MuiAppBar: {
      styleOverrides: {
        root: {
          // Logo
          '& .header-logo-wrapper': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .header-logo': {
            width: '100px',
            marginRight: '5px',
            alignSelf: 'center',
          },
          '& .header-user-profile .MuiTypography-root': {
            fontSize: '14px',
            cursor: 'pointer',
          },
        },
      },
    },
  },
});

export default theme;
