import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// MaterialUi
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
// Icons
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import DescriptionIcon from '@mui/icons-material/Description';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Close from '@mui/icons-material/Close';
// Theme
import CustomTabs from 'theme/CustomTabs/CustomTabs';
// ## //
import * as action from 'action/LicenseeAction';
import { RootState } from 'reducer/configureStore';
import useStyles from './styles/LicenseeDownloadStyles';
import DownloadList from '../../lists/DownloadList';
import DownloadDialog from '../../Dialog/DownloadDialog';

const LicenseeDownloadPage: React.FC = () => {
  const classes = useStyles();
  // Local States
  const [showEditModal, OpenCloseModal] = useState('');
  const [tab, setTab] = useState('game');
  // Global States
  const licensee = useSelector((state: RootState) => state.selectedLicenseeReducer.licensee);
  const downloads = useSelector((state: RootState) => state.selectedLicenseeReducer.downloads);

  const dispatch = useDispatch();

  useEffect(() => {
    if (licensee) {
      dispatch(action.getBucketsAction(licensee._id));
    }
    /* TODO: use AbortController */
    // eslint-disable-next-line no-console
    return () => console.log('cleanup');
  }, [dispatch, licensee]);

  if (licensee && downloads) {
    const docs = downloads.filter((x) => x.Key.match(/^doc/));
    const builds = downloads.filter((x) => x.Key.match(/^builds/));
    const communication = downloads.filter((x) => x.Key.match(/^communication kit/));
    return (
      <>
        <CustomTabs
          handleChange={(event, newValue) => setTab(newValue)}
          value={tab}
          headerColor="primary"
          tabs={[
            {
              tabIcon: SportsEsportsIcon,
              tabName: <FormattedMessage defaultMessage="Game" id="download.title.game" />,
              tabValue: 'game',
              tabContent: <DownloadList documents={builds} downloadAction={OpenCloseModal} />,
            },
            {
              tabIcon: DescriptionIcon,
              tabName: <FormattedMessage defaultMessage="Doc" id="download.title.doc" />,
              tabValue: 'doc',
              tabContent: <DownloadList documents={docs} downloadAction={OpenCloseModal} />,
            },
            {
              tabIcon: PermMediaIcon,
              tabName: <FormattedMessage defaultMessage="Communication Kit" id="download.title.communication" />,
              tabValue: 'media',
              tabContent: <DownloadList documents={communication} downloadAction={OpenCloseModal} />,
            },
          ]}
        />
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          maxWidth="lg"
          onClose={() => OpenCloseModal('')}
          open={!!showEditModal}
        >
          <DialogTitle className={classes.modalHeader}>
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => OpenCloseModal('')}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <FormattedMessage defaultMessage="Download" id="dialog.download" />
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            <DownloadDialog fileName={showEditModal} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return null;
};

export default LicenseeDownloadPage;
