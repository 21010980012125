import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
// ## //
import { getProductAction, saveProductAction } from 'action/ProductAction';
import { RootState } from 'reducer/configureStore';
import { ProductType } from 'types';
import ProductForm from '../forms/ProductForm';

interface Props {
  productId?: string;
  doneAction: () => void;
}

const AddOrEditPlanContainer: React.FC<Props> = ({ productId, doneAction }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const product = useSelector((state: RootState) => state.productReducer.product);

  const fetchProduct = useCallback(async () => {
    await dispatch(getProductAction(productId));
    setLoading(false);
  }, [dispatch, productId]);

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line no-console
    return () => /* use AbortController */ console.log('cleanup');
  }, [dispatch, fetchProduct, productId]);

  const handleSave = useCallback(
    async (values: ProductType) => {
      const productToSave = {
        _id: productId,
        name: values.name,
        key: values.key,
      };

      await dispatch(saveProductAction(productToSave));
      toastr.success('', intl.formatMessage({ defaultMessage: 'Product Saved', id: 'product.save.message' }));
      doneAction();
    },
    [dispatch, doneAction, intl, productId]
  );
  if (loading) {
    return <div> loading </div>;
  }
  return <ProductForm handleCancel={doneAction} handleSave={handleSave} initialValues={product} />;
};

AddOrEditPlanContainer.defaultProps = {
  productId: undefined,
};

export default AddOrEditPlanContainer;
