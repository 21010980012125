import React from 'react';
import { FormattedMessage } from 'react-intl';
// Material Ui
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// ## //
import useStyles from './styles/PageNotFound';

const PageNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Paper className={classes.padding}>
        <Typography variant="h2" className="margin">
          <FormattedMessage defaultMessage="Page Not Found" description="Page Not Found" id="error.404.notFound" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="404 Error" description="404 Error" id="error.404.title" />
        </Typography>
        <Typography>
          <FormattedMessage
            defaultMessage="Sorry, this page does not exist"
            description="404 Error"
            id="error.404.message"
          />
        </Typography>
      </Paper>
    </Container>
  );
};

export default PageNotFound;
